import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Switch } from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import configureStore, { history } from './redux/store';
import AppWrapper from './@jumbo/components/AppWrapper';
import AppContextProvider from './@jumbo/components/contextProvider/AppContextProvider';
import { StylesProvider } from '@material-ui/core/styles';
import Routes from './routes';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
//import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';
import { PersistGate } from 'redux-persist/integration/react';

export const { store, persistor } = configureStore();

const App = () => (
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <StylesProvider>
      <AppContextProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ConnectedRouter history={history}>
              <AppWrapper>
                <Switch>
                  <Routes />
                </Switch>
              </AppWrapper>
            </ConnectedRouter>
          </PersistGate>
        </Provider>
      </AppContextProvider>
    </StylesProvider>
  </MuiPickersUtilsProvider>
);

export default App;
