import {
  Tooltip,
  IconButton,
  Checkbox,
  makeStyles,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tabs,
  Typography,
  Box,
  Grid,
} from '@material-ui/core';
import EnhancedTableHead from 'custom_components/EnhancedTableHead';
import EnhancedTableToolbar from 'custom_components/EnhancedTableToolbar';
import TabPanel from 'custom_components/TabPanel';
import PrintIcon from '@material-ui/icons/Print';
import Button from '@material-ui/core/Button';
import React, { useEffect, useState } from 'react';
import ExportIcon from 'custom_components/ExportIcon';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import GridContainer from '@jumbo/components/GridContainer';
import CustomTableHeader from 'custom_components/Pages/BulkUpload/CustomTableHeader';
import DownloadIcon from 'custom_components/DownloadIcon';
import BulkUploadTableToolbar from 'custom_components/Pages/BulkUpload/BulkUploadTableToolbar';
import BulkUploadForm from 'custom_components/Pages/BulkUpload/BulkUploadForm';
import { Block } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import BulkUploads from 'services/bulkUploads';
import BulkUploadDataTable from 'custom_components/Pages/BulkUpload/BulkUploadDataTable';
import BulkUploadDialog from 'custom_components/BulkUploadDialog';
import SnackBar from 'custom_components/SnackBar';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: 'green',
  },

  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

  paginationContainer: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
  },
  tableCellPadding: {
    paddingBottom: 0,
    paddingTop: 0,
  },

  exportIcon: {
    marginRight: 20,
  },

  tabPanel: {
    '& > div': {
      paddingLeft: 0,
      paddingRight: 0,
      //backgroundColor:'blue'
    },

    bulkForm: {
      display: 'flex',
      display: 'none',
    },

    mainForm: {
      display: 'none',
    },
  },
}));

const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'Bulk Upload', isActive: true },
];

function BulkUpload() {
  let isVisible = false;
  const classes = useStyles();
  const [isBullkFormOpened, setIsBullkFormOpened] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log('Calling UseEffect');

    dispatch(BulkUploads.getBulkUploads(1, true));

    return () => {
      isVisible = false;
    };
  }, []);

  const addNewEventHandler = () => {
    setIsBullkFormOpened(!isBullkFormOpened);
    console.log('Cliked');
  };

  return (
    //

    <React.Fragment>
      <PageContainer heading={'BULK UPLOAD'} breadcrumbs={breadcrumbs}>
        <div style={isBullkFormOpened ? { display: 'flex' } : { display: 'flex', display: 'none' }}>
          <BulkUploadForm closeEvent={addNewEventHandler} />
        </div>
        <GridContainer className={classes.mainForm} style={!isBullkFormOpened ? {} : { display: 'none' }}>
          <Grid item xs={12} sm={12} style={{ padding: 0 }}>
            <Box className={classes.header}>
              <div className={classes.root}>
                <Paper className={classes.paper}>
                  <BulkUploadDataTable popUpHandler={addNewEventHandler} />
                </Paper>
              </div>
            </Box>
          </Grid>
        </GridContainer>
      </PageContainer>
      <BulkUploadDialog />
      <SnackBar />
    </React.Fragment>

    /*
           
         
         */
  );
}

export default BulkUpload;
