export const SET_IS_RECORDS_AVAILABLE = 'SET_IS_RECORDS_AVAILABLE';

function setIsRecordsAvailable() {
  return {
    type: SET_IS_RECORDS_AVAILABLE,
    payload: null,
  };
}

export { setIsRecordsAvailable };
