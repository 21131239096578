import { createMuiTheme } from '@material-ui/core';

const defaultTheme = createMuiTheme({});

const {
  breakpoints,
  typography: { pxToRem },
} = defaultTheme;

const dashboardTheme = {
  ...defaultTheme,
  overrides: {
    MuiTypography: {
      h4: {
        fontSize: '28px',
        fontFamily: 'Normal',
        [breakpoints.down('lg')]: {
          fontSize: '20px',
        },
      },
      h6: {
        fontSize: '16px',
        fontFamily: 'Normal',
        [breakpoints.down('lg')]: {
          fontSize: '13px',
        },
      },
    },
  },
};

export default dashboardTheme;
