import { SET_OVERVIEW_IN_STORE } from 'redux/actions/Overview';

const INIT_STATE = {
  data: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_OVERVIEW_IN_STORE:
      return {
        ...state,
        data: action.payload.overview,
      };

    default:
      return state;
  }
};
