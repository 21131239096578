import React from 'react';
import { Breadcrumbs, Button, Icon, makeStyles } from '@material-ui/core';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import AddIcon from '@material-ui/icons/Add';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Box from '@material-ui/core/Box';
import { useHistory } from 'react-router';

const useStyles = makeStyles(() => ({
  textSm: {
    fontSize: 12,
  },
}));

const PageHeaderActionButtons = ({ items, ...rest }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <ButtonGroup>
      {items.map((item, index) => {
        let icon = '';
        let href = '';
        let onclick = null;

        if (item.label == 'Pickup Request') {
          icon = <NotificationsIcon />;
          onclick = () => {
            history.push({
              pathname: '/pickup-request',
            });
          };
        } else if (item.label == 'New Order') {
          icon = <AddIcon />;
          onclick = () => {
            history.push({
              pathname: '/add-order',
            });
          };
        }

        return (
          <Button index={index} key={index} startIcon={icon} color="default" onClick={onclick}>
            {item.label}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

// if (item.label == 'Pickup Request') {
//   icon = <NotificationsIcon />;
//   onclick = ()=>{
//     history.push({
//       pathname: '/pickup-request',
//      });
//   }
// } else if (item.label == 'New Order') {
//   icon = <AddIcon />;
//   onclick = ()=>{
//     history.push({
//       pathname: '/add-order',
//      });
//   }
// }

export default PageHeaderActionButtons;
