import { closeDialog } from '../../redux/actions/PrintOrder';
import { fetchError, fetchStart, fetchSuccess } from '../../redux/actions';

import axios from '../auth/jwt/config';

const printOrder = {
  printWaybills: (ids, pageSize, perPageWayBillCount) => {
    return dispatch => {
      dispatch(fetchStart());
      let params = [];
      ids.map(item => {
        params.push(`tracking_numbers[]=${item}`);
      });
      params.push(`page_size=${pageSize}`);
      perPageWayBillCount && params.push(`per_page_waybill_count=${perPageWayBillCount}`);
      // window.open('https://system.citypak.net/waybill?' + params.join('&'));
      let baseUrl =
        process.env.NODE_ENV !== 'production'
          ? 'http://localhost:8000/client_panel_api/v1/'
          : process.env.REACT_APP_API_BASE_URL;

      //TODO: Secure this route with auth header
      window.open(baseUrl + 'waybill?' + params.join('&'));
      // window.open('https://falcon.citypak.lk/waybill?' + params.join('&'));

      dispatch(fetchSuccess());
      dispatch(closeDialog());
    };
  },
  printBulkUploads(ids, pageSize = 'A4') {
    return dispatch => {
      const trackingNumber = ids[0];
      dispatch(fetchStart());
      console.log('Downloading Image ...............');
      const token = localStorage.getItem('token'); //check
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      axios
        .get(`/bulk-uploads/${trackingNumber}/print?page_size=${pageSize}`, {
          responseType: 'blob',
        })
        .then(data => {
          //console.log("Ddata",data);

          if (data.status == 200) {
            const result = data.data;
            dispatch(fetchSuccess());
            //dispatch(setBulkUploads(result,1));
            const downloadUrl = window.URL.createObjectURL(new Blob([result]));

            const link = document.createElement('a');

            link.href = downloadUrl;

            link.setAttribute('download', trackingNumber + '.pdf'); //any other extension

            document.body.appendChild(link);

            link.click();

            link.remove();
          } else {
            const data = data.data;
            dispatch(fetchError('Erro'));
          } //
        })
        .catch(function(error) {
          console.log(error);
          dispatch(fetchError(error.message));
        });
    };
  },
};

export default printOrder;
