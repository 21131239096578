import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { Grid } from '@material-ui/core';
import GridContainer from '../../../@jumbo/components/GridContainer';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';

import reducer, { initialState } from './reducer/reducer';
import { OrderContext } from './context/orderContext';
import { useSelector } from 'react-redux';
import CreatedOrderTabPanelNew from 'custom_components/Pages/Orders/CreatedOrderTabPanelNew';
import InTransitOrderTabPanelNew from 'custom_components/Pages/Orders/InTransitOrderTabPanelNew';
import OutOfDeliveryTabPanelNew from 'custom_components/Pages/Orders/OutOfDeliveryTabPanelNew';
import DelliveredTabPanelNew from 'custom_components/Pages/Orders/DelliveredTabPanelNew';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
  },

  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

  line: {
    width: '100%',
    height: 2,
    backgroundColor: 'black',
  },
}));

const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'Orders', isActive: true },
];

const actionButtons = [
  { label: 'Pickup Request', link: '/' },
  { label: 'New Order', isActive: true },
];

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function OrdersNew() {
  const classes = useStyles();
  const location = useLocation();
  const [status, setStatus] = useState();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [currentComponent, setCurrentComponent] = useState(null);

  const [state, dispatch] = useReducer(reducer, initialState);

  useMemo(() => {
    if (location.state && location.state.searchTerm) {
      const params = location.state.searchTerm.split('&');

      params.forEach(param => {
        const [key, value] = param.split('=');
        if (key === 'status') {
          setStatus(value);
        } else if (key === 'fromDate') {
          setFromDate(value);
        } else if (key === 'toDate') {
          setToDate(value);
        }
      });
    }
  }, [location.state]);

  useEffect(() => {
    const getComponent = () => {
      switch (status) {
        case 'PICKED':
          return <CreatedOrderTabPanelNew dashboardFromDate={fromDate} dashboardToDate={toDate} />;
        case 'OUD':
          return <OutOfDeliveryTabPanelNew dashboardFromDate={fromDate} dashboardToDate={toDate} />;
        case 'DL':
        case 'RTM':
        case 'completed':
          return <DelliveredTabPanelNew status={status} dashboardFromDate={fromDate} dashboardToDate={toDate} />;
        case 'RT':
        case 'UD':
        case 'undelivered':
          return <InTransitOrderTabPanelNew dashboardFromDate={fromDate} dashboardToDate={toDate} status={status} />;
        default:
          return null;
      }
    };

    setCurrentComponent(getComponent());
  }, [status, fromDate, toDate]);


  return (
    <OrderContext.Provider value={{ state: state, dispatch: dispatch }}>
      <PageContainer heading={'ORDERS'} style={{ backgroundColor: 'blue' }} actionButtons={actionButtons}>
        <GridContainer>
          <Grid item xs={12} sm={12} style={{ padding: 0 }}>
            <Box className={classes.header}>
              {currentComponent}
            </Box>
          </Grid>
        </GridContainer>
      </PageContainer>
    </OrderContext.Provider>
  );
}

export default OrdersNew;