import React from 'react';
import { Box, Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  badge: {
    background: '#BE0707',
    color: '#fff',
    width: 'fit-content',
    padding: '4px 10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 4,
  },
  img: {
    marginLeft: 6,
    marginTop: 3,
  },
  closeBtn: {
    cursor: 'pointer',
  },
}));

const RemoveableTag = ({ children, handleDel }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.badge}>
        {children}
        <div className={classes.closeBtn} onClick={handleDel}>
          <img className={classes.img} src="/images/close-circle.png" />
        </div>
      </div>
    </>
  );
};

export default RemoveableTag;
