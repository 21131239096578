import {
  DISPLAY_CREATED_ORDERS,
  DISPLAY_INTRANSIT_ORDERS,
  DISPLAY_OUT_FOR_DELIVERY_ORDERS,
  DISPLAY_DELIVERED_ORDERS,
  DISPLAY_CREATED_ORDERS_ES,
  DISPLAY_INTRANSIT_ORDERS_ES,
  DISPLAY_OUT_FOR_DELIVERY_ORDERS_ES,
  DISPLAY_DELIVERED_ORDERS_ES,
  DISPLAY_ALL_ORDERS_ES,
  DISPLAY_INTRANSIT_ORDERS_NEW,
  DISPLAY_OUT_FOR_DELIVERY_ORDERS_NEW,
  DISPLAY_DELIVERED_ORDERS_NEW,
  DISPLAY_SEARCH,
  DISPLAY_UNDELIVERED_ORDERS_NEW,
  DISPLAY_CREATED_ORDERS_NEW,
} from '../../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  created: {
    orders: [],
    page: 1,
    totalRecords: 0,
    isNextPage: false,
  },
  intransit: {
    orders: [],
    page: 1,
    isNextPage: false,
    perPage: 10,
    total: 0,
  },
  outForDelivery: {
    orders: [],
    page: 1,
    isNextPage: false,
    perPage: 10,
    total: 0,
  },
  delivered: {
    orders: [],
    page: 1,
    isNextPage: false,
    perPage: 10,
    total: 0,
  },
  createdNew: {
    orders: [],
    page: 1,
    perPage: 10,
    total: 0,
  },
  intransitNew: {
    orders: [],
    page: 1,
    perPage: 10,
    total: 0,
  },
  outForDeliveryNew: {
    orders: [],
    page: 1,
    perPage: 10,
    total: 0,
  },
  deliveredNew: {
    orders: [],
    page: 1,
    perPage: 10,
    total: 0,
  },
  undeliveredNew: {
    orders: [],
    page: 1,
    perPage: 10,
    total: 0,
  },
  search: {
    orders: [],
    page: 1,
    perPage: 10,
    total: 0,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case DISPLAY_CREATED_ORDERS: {
      if (action.payload.isInitial) {
        return {
          ...state,

          created: {
            orders: action.payload.records,
            page: action.payload.page,
            isNextPage: action.payload.isNextPage,
            totalRecords: action.payload.totalRecords,
          },
        };
      } else {
        return {
          ...state,

          // ...state.orders,
          created: {
            orders: [...state.created.orders, ...action.payload.records],
            page: action.payload.page,
            isNextPage: action.payload.isNextPage,
            totalRecords: action.payload.totalRecords,
          },
        };
      }
    }

    case DISPLAY_INTRANSIT_ORDERS: {
      return {
        ...state,

        intransit: {
          orders: action.payload.records,
          page: action.payload.page,
          isNextPage: action.payload.isNextPage,
          perPage: action.payload.perPage,
          total: action.payload.total,
        },
      };

      // if (action.payload.isInitial) {
      //   return {
      //     ...state,
      //
      //     intransit: {
      //       orders: action.payload.records,
      //       page: action.payload.page,
      //       isNextPage: action.payload.isNextPage,
      //     },
      //   };
      // } else {
      //   return {
      //     ...state,
      //
      //     ...state.orders,
      //     intransit: {
      //       orders: [...state.orders.intransit, ...action.payload.records],
      //       page: action.payload.page,
      //       isNextPage: action.payload.isNextPage,
      //     },
      //   };
      // }
    }

    case DISPLAY_OUT_FOR_DELIVERY_ORDERS: {
      return {
        ...state,

        outForDelivery: {
          orders: action.payload.records,
          page: action.payload.page,
          isNextPage: action.payload.isNextPage,
          perPage: action.payload.perPage,
          total: action.payload.total,
        },
      };

      // if (action.payload.isInitial) {
      //   return {
      //     ...state,
      //
      //     outForDelivery: {
      //       orders: action.payload.records,
      //       page: action.payload.page,
      //       isNextPage: action.payload.isNextPage,
      //     },
      //   };
      // } else {
      //   return {
      //     ...state,
      //
      //     ...state.orders,
      //     outForDelivery: {
      //       orders: [...state.orders.outForDelivery, ...action.payload.records],
      //       page: action.payload.page,
      //       isNextPage: action.payload.isNextPage,
      //     },
      //   };
      // }
    }

    case DISPLAY_DELIVERED_ORDERS: {
      return {
        ...state,

        delivered: {
          orders: action.payload.records,
          page: action.payload.page,
          isNextPage: action.payload.isNextPage,
          perPage: action.payload.perPage,
          total: action.payload.total,
        },
      };

      // if (action.payload.isInitial) {
      //   return {
      //     ...state,
      //
      //     delivered: {
      //       orders: action.payload.records,
      //       page: action.payload.page,
      //       isNextPage: action.payload.isNextPage,
      //       perPage: action.payload.perPage,
      //       total: action.payload.total,
      //     },
      //   };
      // } else {
      //   return {
      //     ...state,
      //
      //     ...state.orders,
      //     delivered: {
      //       orders: [...state.orders.delivered, ...action.payload.records],
      //       page: action.payload.page,
      //       isNextPage: action.payload.isNextPage,
      //       perPage: action.payload.perPage,
      //       total: action.payload.total,
      //     },
      //   };
      // }
    }
    case DISPLAY_CREATED_ORDERS_NEW: {
      return {
        ...state,
        createdNew: {
          orders: action.payload.records,
          page: action.payload.page,
          perPage: action.payload.perPage,
          total: action.payload.total,
        },
      };
    }

    case DISPLAY_INTRANSIT_ORDERS_NEW: {
      return {
        ...state,
        intransitNew: {
          orders: action.payload.records,
          page: action.payload.page,
          perPage: action.payload.perPage,
          total: action.payload.total,
        },
      };
    }

    case DISPLAY_OUT_FOR_DELIVERY_ORDERS_NEW: {
      return {
        ...state,
        outForDeliveryNew: {
          orders: action.payload.records,
          page: action.payload.page,
          perPage: action.payload.perPage,
          total: action.payload.total,
        },
      };
    }

    case DISPLAY_DELIVERED_ORDERS_NEW: {
      return {
        ...state,
        deliveredNew: {
          orders: action.payload.records,
          page: action.payload.page,
          perPage: action.payload.perPage,
          total: action.payload.total,
        },
      };
    }

    case DISPLAY_UNDELIVERED_ORDERS_NEW: {
      return {
        ...state,
        undeliveredNew: {
          orders: action.payload.records,
          page: action.payload.page,
          perPage: action.payload.perPage,
          total: action.payload.total,
        },
      };
    }

    case DISPLAY_SEARCH: {
      return {
        ...state,
        search: {
          orders: action.payload.records,
          page: action.payload.page,
          perPage: action.payload.perPage,
          total: action.payload.total,
        },
      };
    }

    default:
      return state;
  }
};