import { OPEN_ORDER_PRINT_DIALOG, CLOSE_ORDER_PRINT_DIALOG, PRINT_ORDERS } from '../../@jumbo/constants/ActionTypes';

export const openDialog = payload => {
  return dispatch => {
    dispatch({
      type: OPEN_ORDER_PRINT_DIALOG,
      payload: payload,
    });
  };
};
export const closeDialog = () => {
  return dispatch => {
    dispatch({
      type: CLOSE_ORDER_PRINT_DIALOG,
    });
  };
};
