import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import Common from './Common';
import Auth from './Auth';
import BulkUpload from './BulkUpload';
import Orders from './CreateOrder';
import TrackingHistory from './TrackingHistory';
import PickUps from './Pickups/index';
import Cities from './Cities/index';
import PrintOrder from './PrintOrder';
import SnackBar from './SnackBar';
import Reports from './Reports';
import CodPayments from './CodPayments';
import Overview from './Overview';
import Tickets from './Tickets';

export default history =>
  combineReducers({
    router: connectRouter(history),
    common: Common,
    auth: Auth,
    bulkUpload: BulkUpload,
    orders: Orders,
    trackingHistory: TrackingHistory,
    pickups: PickUps,
    cities: Cities,
    printOrder: PrintOrder,
    snackBar: SnackBar,
    reports: Reports,
    codPayments: CodPayments,
    overview: Overview,
    tickets: Tickets,
    // userBehaviours:UserBehaviours,
  });
