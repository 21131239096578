import { Tooltip,IconButton,Checkbox, makeStyles, Paper,Tab, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Tabs, Typography, Box} from '@material-ui/core';
import EnhancedTableHead from 'custom_components/EnhancedTableHead';
import EnhancedTableToolbar from 'custom_components/EnhancedTableToolbar';
import TabPanel from 'custom_components/TabPanel';
import PrintIcon from '@material-ui/icons/Print';
import Button from '@material-ui/core/Button';
import React, { useState } from 'react'
import  styles from './CSS/TableRow.css';
import ExportIcon from 'custom_components/ExportIcon';
import InTransitTableHeader from 'custom_components/InTransitTableHeader';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
    DatePicker,
  } from '@material-ui/pickers';


  
const useStyles = makeStyles(theme => ({   

    root: {
      width: '100%',
    },

    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
      },

      paginationContainer:{
        
        display:'flex',
        justifyContent:'right',
        alignItems:'center'
       
      },

      toolContainer:{
        display:'flex',
        justifyContent:'right',
        alignItems:'center',
        padding:5
      }
      ,


      exportButtonContainer:{
        display:'flex',
        flex:2,
        justifyContent:'right',
        alignItems:'center',
        paddingRight:20,
        //backgroundColor:'green'
      },
      datePickerContainer:{
        display:'flex',
        flex:6,
        justifyContent:'right',
        alignItems:'center',
        paddingRight:20,
        //backgroundColor:'yellow'
      },
      tabPanel:{
        
        '& > div':{
          paddingLeft:0,
          paddingRight:0,
          //backgroundColor:'blue'
        }
        
      },
      exportIcon:{
        marginRight:20
      },



     


   
    
     
     
      
      
     
      
    

}));




const headCells = [
    {
      id: 'waybill',
      numeric: false,
      disablePadding: true,
      label: 'WAYBILL',
      alignment:'left'
    },
    { id: 'reference', numeric:true, disablePadding: false, label: 'REFERENCE',alignment:'left' },
    { id: 'returnedDate', numeric: false, disablePadding: false, label: 'RETURNED DATE',alignment:'left'},
    { id: 'branch', numeric: false, disablePadding:false, label: 'BRANCH',alignment:'left' },
    { id: 'attempts', numeric:true, disablePadding: false, label: 'ATTEMPTS',alignment:'right' },
    { id: 'consignee', numeric: false, disablePadding:false, label: 'CONSIGNEE',alignment:'right' },
    { id: 'remark', numeric: false, disablePadding:false, label: 'REMARK',alignment:'right' },
    
 
  ];



  function createData(waybill, reference,returnedDate,branch,attempts,consignee,remark) {
    return {waybill, reference,returnedDate,branch,attempts,consignee,remark};
  };


  const rows = [
    createData('D00125477', 59816551, '20-03-2021', 'COLOMBO', 0,'ANANDA KARUNATHILAKE','DOOR CLOSED'),
    
   


   
   
    //
  ];



  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };


  
  function ReturnedTabPanel({value,index}) {

    const classes = useStyles();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('calories');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [selectedDate, setSelectedDate] = useState(new Date());

    
    const handleClick = (event, name) => {


        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

            if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
            } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
            }

        setSelected(newSelected);
    }

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
          const newSelecteds = rows.map((n) => n.waybill);
          setSelected(newSelecteds);
          return;
        }
        setSelected([]);
    }
    
    
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    }


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const statusCheker=(status)=>{
        let textColor='#000000';
          switch (status) {
            case "PICKED":
              textColor='#FF9100';
              break;
            case "AT HUB":
              textColor='#1E96CB';
              break;
            
            case "DISPATCHED TO FACILITY":
              textColor='#1E96CB';
              break;
            case "RETURNED TO FACILITY":
              textColor='#1E96CB'; 
              break;
            case "NOT DELIVERED":
              textColor='#BE0707';  
              break;
          
            default:
              textColor='#000000';
              break;
          }

          return textColor;

    }

    const handleDateChange = (date) => {
      setSelectedDate(date);
    };



      return (
        <TabPanel value={value} index={index} className={classes.tabPanel} >
          
        <div className={classes.root} >
            <Paper className={classes.paper}>

                <EnhancedTableToolbar numSelected={selected.length} />
                <Box className={classes.toolContainer}>
                             
                             <Box className={classes.exportButtonContainer}>
                              

                             </Box>
                             <Box className={classes.datePickerContainer}>
                              <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                label="Date picker inline"
                                value={selectedDate}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                              />

                             </Box>
                         
                        


                    </Box>
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                            aria-label="enhanced table"
                            >
                            <InTransitTableHeader
                                classes={classes}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={rows.length}
                                headCells={headCells}
                                
                            />
                            <TableBody>
                                {stableSort(rows, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
        
                                const isItemSelected = isSelected(row.waybill);
                                const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                                <TableRow
                                    hover
                                    
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.waybill}
                                    selected={isItemSelected}
                                    classes={{hover:'MuiTableRow-hover'}}
                                    
                                    

                                    

                                    
                                >
                                <TableCell 
                                padding="checkbox" 
                                onClick={(event) => handleClick(event, row.waybill)}>
                                <Checkbox
                                    checked={isItemSelected}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                    color={'primary'}
                                />
                                </TableCell>

                                <TableCell component="th" id={labelId} scope="row" padding="none" onClick={(event) => handleClick(event, row.waybill)}>
                                {row.waybill}
                                </TableCell>

                                <TableCell align="left" onClick={(event) => handleClick(event, row.waybill)}>{row.reference}</TableCell>
                                <TableCell align="left"  onClick={(event) => handleClick(event, row.waybill)}>{row.returnedDate}</TableCell>
                                <TableCell align="left"  onClick={(event) => handleClick(event, row.waybill)}>{row.branch}</TableCell>
                                <TableCell align="right"  onClick={(event) => handleClick(event, row.waybill)}>{row.attempts}</TableCell>
                                <TableCell align="right" onClick={(event) => handleClick(event, row.waybill)}>{row.consignee}</TableCell>
                                <TableCell align="right"  onClick={(event) => handleClick(event, row.waybill)}>{row.remark}</TableCell>
                
                               
                                
                                
                                </TableRow>

                                    );
                                        }
                                            )
      
                                }
                                    {

                                        emptyRows > 0 && (
                                        <TableRow style={{ height: (53) * emptyRows }}>
                                            <TableCell colSpan={12} />
                                        </TableRow>
                                        )

                                    }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box className={classes.paginationContainer}>

                                <Button
                                  variant="outlined"
                                  
                                  size={'small'}
                                  startIcon={<ExportIcon />}
                                  className={classes.exportIcon}
                                  >
                                   Export
                                </Button>
                          
                        <TablePagination
                            rowsPerPageOptions={[10, 25]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />


                    </Box>
                       

            </Paper>
            
  
        </div>
    </TabPanel>
      )
  }
  
  export default ReturnedTabPanel
  