import { SHOW_SNACK_BAR, HIDE_SNACK_BAR } from '../../@jumbo/constants/ActionTypes';

export const showSnackBar = data => {
  return dispatch => {
    dispatch({
      type: SHOW_SNACK_BAR,
      payload: data,
    });
  };
};
export const hideSnackBar = () => {
  return dispatch => {
    dispatch({
      type: HIDE_SNACK_BAR,
    });
  };
};
