import { SvgIcon } from '@material-ui/core'
import React from 'react'

function DownloadIcon() {
    return (
        <SvgIcon  viewBox="0 0 26 26" >
                 <g id="export_icon" transform="translate(3023.373 404.947)">
    <path id="Path_93" data-name="Path 93" d="M-3022.873-404.447h25v25h-25Z" fill="none" stroke="#707070" opacity="0"/>
    <g id="Excel" transform="translate(-3034.913 -405.596)">
      <g id="Group_27" data-name="Group 27" transform="translate(14 2)">
        <g id="Group_19" data-name="Group 19" transform="translate(6.287 11.045)">
          <path id="Path_13" data-name="Path 13" d="M53.92,68.07H52.386l-1.474-2.4-1.474,2.4H48L50.1,64.8l-1.967-3.068h1.482l1.365,2.28,1.339-2.28h1.447l-1.989,3.142Z" transform="translate(-48 -61.734)" fill="#007732"/>
        </g>
        <g id="Group_26" data-name="Group 26" transform="translate(0 0)">
          <g id="Group_20" data-name="Group 20" transform="translate(12.204 14.423)">
            <path id="Path_14" data-name="Path 14" d="M84.438,80a4.438,4.438,0,1,0,4.438,4.438A4.438,4.438,0,0,0,84.438,80Zm2.012,3.141-.021.033-1.522,2.115a.555.555,0,0,1-.938,0l-1.543-2.147a.555.555,0,0,1,.482-.83H83.7v-1.2a.555.555,0,0,1,.555-.555h.37a.555.555,0,0,1,.555.555v1.2h.791a.555.555,0,0,1,.482.83Z" transform="translate(-80 -80)" fill="#007732"/>
          </g>
          <g id="Group_25" data-name="Group 25">
            <g id="Group_21" data-name="Group 21" transform="translate(12.934 22.919)">
              <path id="Path_15" data-name="Path 15" d="M83.959,125.95l-.009.009h.009Z" transform="translate(-83.95 -125.95)" fill="#ff9a30"/>
            </g>
            <g id="Group_22" data-name="Group 22" transform="translate(18.481 13.859)">
              <path id="Path_16" data-name="Path 16" d="M113.959,76.959V76.95l-.009.009Z" transform="translate(-113.95 -76.95)" fill="#ff9a30"/>
            </g>
            <g id="Group_23" data-name="Group 23">
              <path id="Path_17" data-name="Path 17" d="M31.949,9.811,24.68,2.542A1.837,1.837,0,0,0,23.372,2H15.849A1.851,1.851,0,0,0,14,3.849v19.23a1.851,1.851,0,0,0,1.849,1.849H26.934l-.74-.74H15.849a1.111,1.111,0,0,1-1.109-1.109V3.849A1.111,1.111,0,0,1,15.849,2.74h7.523a1.1,1.1,0,0,1,.784.325l7.269,7.269a1.118,1.118,0,0,1,.325.784v4l.74.74v-4.74A1.837,1.837,0,0,0,31.949,9.811Z" transform="translate(-14 -2)" fill="#007732"/>
            </g>
            <g id="Group_24" data-name="Group 24" transform="translate(17.751 13.119)">
              <path id="Path_18" data-name="Path 18" d="M110.73,73.7l.009-.009-.74-.74" transform="translate(-110 -72.95)" fill="#fff"/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
            </SvgIcon>
    )
}

export default DownloadIcon
