import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';
import { SvgIcon } from '@material-ui/core';

function ExportIcon() {
    return (
        <SvgIcon  viewBox="0 0 26 26" >
                <g id="export_icon" transform="translate(3273.093 957.063)">
                <path id="ic_file_download_24px" d="M20.957,9.839H16.4V3H9.559V9.839H5l7.979,7.979ZM5,20.1v2.28H20.957V20.1Z" transform="translate(-3273.072 -956.751)" fill="#00ab3f"/>
                <path id="Path_95" data-name="Path 95" d="M-3272.593-956.562h25v25h-25Z" fill="none" stroke="#707070"  opacity="0"/>
                </g>
            </SvgIcon>
    )
}

export default ExportIcon
