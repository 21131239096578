import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';
import { SvgIcon } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
   icon:{
       
       backgroundColor:'green',

   }
  }));

function MenuIcon({image}) {
    return (
        // <div>

        //     <img src={image} alt={"order_icon"}></img>
            
        // </div>
        <SvgIcon width="54.09" height="59.934" viewBox="0 0 54.09 59.934">
            <path d="M49.747,6.843H37.534a8.73,8.73,0,0,0-16.478,0H8.843A5.861,5.861,0,0,0,3,12.687v40.9a5.861,5.861,0,0,0,5.843,5.843h40.9A5.861,5.861,0,0,0,55.59,53.59v-40.9A5.861,5.861,0,0,0,49.747,6.843Zm-20.452,0a2.922,2.922,0,1,1-2.922,2.922A2.93,2.93,0,0,1,29.3,6.843Zm-5.843,40.9L11.765,36.06l4.12-4.12,7.567,7.538L42.706,20.225l4.12,4.149Z" transform="translate(-2.25 -0.25)" fill="none" stroke="#be0707" stroke-width="1.5"/>
       </SvgIcon>
    )
}



export default MenuIcon
