import { Box, Button, IconButton, Toolbar, Tooltip, Typography } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import PrintIcon from '@material-ui/icons/Print';
import React from 'react';
import ExportIcon from 'custom_components/ExportIcon';
import DownloadIcon from 'custom_components/DownloadIcon';
import AddIcon from '@material-ui/icons/Add';
import AppFiles from 'Utils/AppFiles';
import { saveAs } from 'file-saver';
import { useDispatch, useSelector } from 'react-redux';
import { openBulkUploadDialog, closeBulkUploadDialog } from '../../../redux/actions/BulkUpload';

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    backgroundColor: '#FFFFFF',
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.main, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },

  toolBarbg: {
    backgroundColor: '#F7F8FA', //'#F7F8FA'
  },
  line: {
    width: '100%',
    height: 1,
    backgroundColor: 'black',
  },

  text: {
    marginRight: 10,
  },

  options: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
    // backgroundColor:'green',
    flex: '1 1 100%',
  },
}));

function BulkUploadTableToolbar({ addNewEventHandler, numSelected }) {
  const dispatch = useDispatch();
  const classes = useToolbarStyles();
  ///const { numSelected } = props;

  const handleAddNew = () => {
    dispatch(openBulkUploadDialog());
  };

  //console.log("Nums",numSelected);
  const saveTemplate = () => {
    window.location.href = AppFiles.excelTemplate;
  };
  return (
    <React.Fragment>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}>
        {numSelected > 0 ? (
          <Typography component={'span'} className={classes.title} color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography component={'span'} className={classes.title} variant="h6" id="tableTitle" />
        )}

        {numSelected > 0 ? (
          <React.Fragment>
            <Tooltip title="Delete">
              <IconButton aria-label="delete">
                <DeleteIcon color={'primary'} />
              </IconButton>
            </Tooltip>
          </React.Fragment>
        ) : (
          <Box className={classes.options}>
            <Button
              onClick={saveTemplate}
              variant="outlined"
              size={'small'}
              startIcon={<DownloadIcon />}
              className={(classes.downloadIcon, classes.text)}>
              Download Template
            </Button>
            <Button
              variant="outlined"
              size={'small'}
              startIcon={<AddIcon />}
              className={(classes.addIcon, classes.text)}
              onClick={handleAddNew}>
              Add New
            </Button>
          </Box>
        )}
      </Toolbar>
      <div className={classes.line} />
    </React.Fragment>
  );
}

BulkUploadTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default BulkUploadTableToolbar;
