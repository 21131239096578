import {
  Tooltip,
  IconButton,
  Checkbox,
  makeStyles,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tabs,
  Typography,
  Box,
} from '@material-ui/core';
import EnhancedTableHead from 'custom_components/EnhancedTableHead';
import EnhancedTableToolbar from 'custom_components/EnhancedTableToolbar';
import TabPanel from 'custom_components/TabPanel';
import PrintIcon from '@material-ui/icons/Print';
import Button from '@material-ui/core/Button';
import React, { useEffect, useState } from 'react';
import styles from './CSS/TableRow.css';
import ExportIcon from 'custom_components/ExportIcon';
import InTransitTableHeader from 'custom_components/InTransitTableHeader';
import CameraAltIcon from '@material-ui/icons/CameraAlt';

import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker, DatePicker } from '@material-ui/pickers';

import { useDispatch, useSelector } from 'react-redux';
import Orders from 'services/orders';
import ErrorToast from '../common/ErrorToast';
import moment from 'moment';
import reports from '../../../services/reports';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },

  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

  paginationContainer: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
  },

  toolContainer: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
    padding: 5,
  },
  exportButtonContainer: {
    display: 'flex',
    flex: 2,
    justifyContent: 'right',
    alignItems: 'center',
    paddingRight: 20,
    //backgroundColor:'green'
  },
  datePickerContainer: {
    display: 'flex',
    flex: 6,
    justifyContent: 'right',
    alignItems: 'center',
    paddingRight: 20,
    //backgroundColor:'yellow'
  },
  tabPanel: {
    '& > div': {
      paddingLeft: 0,
      paddingRight: 0,
      //backgroundColor:'blue'
    },
  },
}));

const headCells = [
  {
    id: 'waybill',
    numeric: false,
    disablePadding: true,
    label: 'WAYBILL',
    alignment: 'left',
  },
  {
    id: 'reference',
    numeric: true,
    disablePadding: false,
    label: 'REFERENCE',
    alignment: 'left',
  },
  {
    id: 'deliveredDate',
    numeric: false,
    disablePadding: false,
    label: 'DELIVERED DATE',
    alignment: 'left',
  },
  {
    id: 'branch',
    numeric: false,
    disablePadding: false,
    label: 'BRANCH',
    alignment: 'left',
  },
  {
    id: 'attempts',
    numeric: true,
    disablePadding: false,
    label: 'ATTEMPTS',
    alignment: 'right',
  },
  {
    id: 'consignee',
    numeric: false,
    disablePadding: false,
    label: 'CONSIGNEE',
    alignment: 'left',
  },
  {
    id: 'cash_on_delivery_amount',
    numeric: true,
    disablePadding: false,
    label: 'COD',
  },
  {
    id: 'status_type',
    numeric: false,
    disablePadding: false,
    label: 'STATUS TYPE',
  },
];

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function DelliveredTabPanel({ value, index }) {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isInitial, setIsInitial] = useState(true);

  const [fromDate, setFromDate] = useState(moment());
  const [toDate, setToDate] = useState(moment());

  const dispatch = useDispatch();
  let dataCreated = false;
  let isInitialRequest = true;

  useEffect(() => {
    //request

    //   dispatch(
    //     Orders.getDeliveredOrders(
    //       1,
    //       true,
    //       `${selectedDate.getFullYear()}-${selectedDate.getMonth() + 1}-${selectedDate.getDate()}`,
    //     ),
    //   );
    // }, []);

    dispatch(
      Orders.getDeliveredOrdersByDateRange(
        page,
        true,
        rowsPerPage,
        `${fromDate.format('DD-MM-YYYY')}`,
        `${toDate.format('DD-MM-YYYY')}`,
      ),
    );
  }, []);

  const delivered = useSelector(({ orders }) => orders.delivered);

  const handleRefresh = () => {
    // dispatch(
    //   Orders.getDeliveredOrders(
    //     1,
    //     true,
    //     `${selectedDate.getFullYear()}-${selectedDate.getMonth() + 1}-${selectedDate.getDate()}`,
    //   ),
    // );
    setPage(0);
    dispatch(
      Orders.getDeliveredOrdersByDateRange(
        0,
        true,
        rowsPerPage,
        `${fromDate.format('DD-MM-YYYY')}`,
        `${toDate.format('DD-MM-YYYY')}`,
      ),
    );
  };

  const exportToCsv = () => {
    dispatch(reports.openDeliveredOrdersReportUrl(fromDate, toDate));
  };

  function createData(waybill, reference, deliveredDate, branch, attempts, consignee, cash_on_delivery_amount, status_type) {
    return {
      waybill,
      reference,
      deliveredDate,
      branch,
      attempts,
      consignee,
      cash_on_delivery_amount,
      status_type,
    };
  }

  const dataBuilder = data => {
    const setDelivereddate = (d1, d2) => {
      if (d1 !== null) {
        return d1.delivered_datetime;
      } else if (d2 !== null) {
        return d2.delivered_datetime;
      }
      return '';
    };

    if (data.length === 0) {
      dataCreated = false;
      return [];
      // return [
      //   createData('loading...', 0, 'loading...', 'loading...', 0,'loading...','loading...',0,''),
      //
      // ];
    } else {
      dataCreated = true;
      return data.map(entry => {
        return createData(
          entry.tracking_number,
          entry.reference,
          setDelivereddate(entry.delivery_dispatch_item, entry.delivery_hand_over_item),
          entry.last_public_tracking_status !== null ? entry.last_public_tracking_status.facility.name : '',
          entry.delivery_dispatch_items_count,
          entry.to_address_title,
          entry.cash_on_delivery_amount,
          entry.last_public_tracking_status !== null ? entry.last_public_tracking_status.category_code : '',
        );
      });
    }
  };

  const dataForTable = dataBuilder(delivered.orders); //cashing data

  const rows = () => {
    if (dataCreated) {
      return dataForTable;
    } else {
      return dataBuilder(delivered.orders);
    }
  };

  const errorChecker = () => {
    if (!isInitial && delivered.orders.length === 0) {
      return true;
    }
    return false;
  };

  const sendNewRequestWithDate = date => {
    setIsInitial(false);
    dispatch(Orders.getDeliveredOrders(1, true, date));
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows().map(n => n.waybill);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(
      Orders.getDeliveredOrdersByDateRange(
        newPage + 1,
        false,
        rowsPerPage,
        `${fromDate.format('DD-MM-YYYY')}`,
        `${toDate.format('DD-MM-YYYY')}`,
      ),
    );
  };

  const handleChangeRowsPerPage = event => {
    // alert(parseInt(event.target.value));
    setRowsPerPage(event.target.value);
    setPage(0);
    dispatch(
      Orders.getDeliveredOrdersByDateRange(
        1,
        true,
        event.target.value,
        `${fromDate.format('DD-MM-YYYY')}`,
        `${toDate.format('DD-MM-YYYY')}`,
      ),
    );
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const statusCheker = status => {
    let textColor = '#000000';
    switch (status) {
      case 'PICKED':
        textColor = '#FF9100';
        break;
      case 'AT HUB':
        textColor = '#1E96CB';
        break;

      case 'DISPATCHED TO FACILITY':
        textColor = '#1E96CB';
        break;
      case 'RETURNED TO FACILITY':
        textColor = '#1E96CB';
        break;
      case 'NOT DELIVERED':
        textColor = '#BE0707';
        break;

      default:
        textColor = '#000000';
        break;
    }

    return textColor;
  };

  const handleDateChange = date => {
    //console.log(date._d.getFullYear(),date._d.getMonth(),date._d.getDate());
    //2020-10-07
    sendNewRequestWithDate(`${date._d.getFullYear()}-${date._d.getMonth() + 1}-${date._d.getDate()}`);
    setSelectedDate(date);
  };

  return (
    <div>
      <TabPanel value={value} index={index} className={classes.tabPanel}>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <EnhancedTableToolbar numSelected={selected.length} enableRefresh={true} handleRefresh={handleRefresh} />
            <Grid container>
              <Grid item xs={12} md={6} />
              <Grid item xs={12} md={6}>
                <Grid container justifyContent="center" alignItems="flex-end" spacing={2}>
                  <Grid item xs={12} md={5}>
                    <Box p={2}>
                      <KeyboardDatePicker
                        style={{ width: '100%' }}
                        disableToolbar
                        variant="inline"
                        format="DD-MM-yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="From Date"
                        value={fromDate}
                        autoOk={true}
                        onChange={e => {
                          setFromDate(moment(e._d));
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Box p={2}>
                      <KeyboardDatePicker
                        style={{ width: '100%' }}
                        disableToolbar
                        variant="inline"
                        format="DD-MM-yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="To Date"
                        value={toDate}
                        minDate={fromDate}
                        autoOk={true}
                        onChange={e => {
                          setToDate(moment(e._d));
                        }}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <Box p={2} py={5} sx={{ alignContent: 'flex-end' }}>
                      <Button
                        variant="contained"
                        fullWidth={true}
                        className={classes.newRequestButton}
                        onClick={() => {
                          // alert(moment(new Date(fromDate)).format('YYYY-MM-DD'))
                          handleRefresh();
                          // dispatch(
                          //   PickUps.getPickUps(
                          //     true,
                          //     undefined,
                          //     moment(new Date(fromDate)).format('YYYY-MM-DD'),
                          //     moment(new Date(toDate)).format('YYYY-MM-DD'),
                          //   ),
                          //   setPage(0),
                          // );
                        }}>
                        Filter
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <TableContainer>
              <Table className={classes.table} aria-labelledby="tableTitle" size={'medium'} aria-label="enhanced table">
                <InTransitTableHeader
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows().length}
                  headCells={headCells}
                />
                <TableBody>
                  {stableSort(rows(), getComparator(order, orderBy))
                    //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.waybill);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.waybill}
                          selected={isItemSelected}
                          classes={{ hover: 'MuiTableRow-hover' }}>
                          <TableCell padding="checkbox" onClick={event => handleClick(event, row.waybill)}>
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                              color={'primary'}
                            />
                          </TableCell>

                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            onClick={event => handleClick(event, row.waybill)}>
                            {row.waybill}
                          </TableCell>

                          <TableCell align="left" onClick={event => handleClick(event, row.waybill)}>
                            {row.reference}
                          </TableCell>
                          <TableCell align="left" onClick={event => handleClick(event, row.waybill)}>
                            {row.deliveredDate}
                          </TableCell>
                          <TableCell align="center" onClick={event => handleClick(event, row.waybill)}>
                            {row.branch}
                          </TableCell>
                          <TableCell align="center" onClick={event => handleClick(event, row.waybill)}>
                            {row.attempts}
                          </TableCell>
                          <TableCell align="center" onClick={event => handleClick(event, row.waybill)}>
                            {row.consignee}
                          </TableCell>
                          <TableCell align="right" onClick={event => handleClick(event, row.waybill)}>
                            {row.cash_on_delivery_amount}
                          </TableCell>
                          <TableCell align="center" onClick={event => handleClick(event, row.waybill)}>
                            {row.status_type}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {/*{emptyRows > 0 && (*/}
                  {/*  <TableRow style={{ height: 53 * emptyRows }}>*/}
                  {/*    <TableCell colSpan={12} />*/}
                  {/*  </TableRow>*/}
                  {/*)}*/}
                </TableBody>
              </Table>
            </TableContainer>
            <Box className={classes.paginationContainer}>
              <Button
                variant="outlined"
                onClick={exportToCsv}
                size={'small'}
                startIcon={<ExportIcon />}
                className={classes.exportIcon}>
                Export
              </Button>

              <TablePagination
                rowsPerPageOptions={[2, 10, 25, 50, 100]}
                component="div"
                count={delivered.total !== null ? delivered.total : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Box>
          </Paper>
        </div>
      </TabPanel>
      <ErrorToast open={errorChecker()} message="No Records Found" />
    </div>
  );
}

export default DelliveredTabPanel;
