import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';
import { SvgIcon } from '@material-ui/core';

function ReportIcon({color}) {
    return (
       
      
            <SvgIcon  viewBox="0 0 25 25" >//6

                        <g transform="translate(2102.209 -665.5)">
                        <path d="M-2094.525,649.061h24v24h-24Z" transform="translate(-7.183 16.939)" fill="none" stroke="#707070" opacity="0"/>
                        <path d="M18.792,3H4.974A2.092,2.092,0,0,0,3,5.181v15.27a2.092,2.092,0,0,0,1.974,2.181H18.792a2.092,2.092,0,0,0,1.974-2.181V5.181A2.092,2.092,0,0,0,18.792,3ZM8.922,18.27H6.948V10.635H8.922Zm3.948,0H10.9V7.363H12.87Zm3.948,0H14.844V13.907h1.974Z" transform="translate(-2101.592 665.183)" />
                        </g>


            </SvgIcon>

      
            
    )
            
     
}

export default ReportIcon
