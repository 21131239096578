import {
  Tooltip,
  IconButton,
  Checkbox,
  makeStyles,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tabs,
  Typography,
  Box,
  Grid,
} from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import Button from '@material-ui/core/Button';
import React, { useEffect, useState } from 'react';
import ExportIcon from 'custom_components/ExportIcon';
import CustomTableHeader from 'custom_components/Pages/BulkUpload/CustomTableHeader';
import DownloadIcon from 'custom_components/DownloadIcon';
import BulkUploadTableToolbar from 'custom_components/Pages/BulkUpload/BulkUploadTableToolbar';
import { useDispatch, useSelector } from 'react-redux';
import BulkUploads from 'services/bulkUploads';
import AppConfigs from 'Utils/AppConfigs';
import { openDialog, closeDialog } from '../../../redux/actions/PrintOrder';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const headCells = [
  {
    id: 'bulk_upload_number',
    numeric: false,
    disablePadding: true,
    label: 'BULK UPLOAD NUMBER',
    alignment: 'left',
  },
  {
    id: 'no_of_waybill',
    numeric: true,
    disablePadding: false,
    label: 'NO OF WAYBILLS',
    alignment: 'left',
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'DATE',
    alignment: 'left',
  },

  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'STATUS',
    alignment: 'left',
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: false,
    label: 'ACTION',
    alignment: 'center',
  },
];

function createData(id, bulkUploadNumber, noOfWaybill, date, status, action, fileName) {
  //console.log(fileName);
  return { id, bulkUploadNumber, noOfWaybill, date, status, action, fileName };
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: 'green',
  },

  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

  paginationContainer: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
  },
  tableCellPadding: {
    paddingBottom: 0,
    paddingTop: 0,
  },

  exportIcon: {
    marginRight: 20,
  },

  tabPanel: {
    '& > div': {
      paddingLeft: 0,
      paddingRight: 0,
      //backgroundColor:'blue'
    },

    bulkForm: {
      display: 'flex',
      display: 'none',
    },

    mainForm: {
      display: 'none',
    },
  },
}));

function BulkUploadDataTable({ popUpHandler }) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const classes = useStyles();

  //const [apiPage, setApiPage] = useState(1);

  const dispatch = useDispatch();

  let dataCreated = false;

  const bulkUploadData = useSelector(({ bulkUpload }) => bulkUpload.bulkUploads);
  const apiPage = useSelector(({ bulkUpload }) => bulkUpload.page);
  const isNextPage = useSelector(({ bulkUpload }) => bulkUpload.isNextPage);

  console.log('apiPage', apiPage, isNextPage);

  const dataBuilder = bulkUploadData => {
    if (bulkUploadData.length === 0) {
      console.log('No data');
      dataCreated = false;
      return [
        // createData('B16163754073330001888', 45, '22-03-2021', '10:00'),
        // createData('B16163754073330001889', 59, '22-03-2021', '10:00'),
        // createData('B16163754073330001881', 59, '22-03-2021', '10:00'),
      ];
    } else {
      dataCreated = true;
      console.log('cashed');
      console.log(bulkUploadData);
      return bulkUploadData.map(entry => {
        return createData(
          entry.id,
          entry.tracking_number,
          entry.waybills,
          entry.started_datetime,
          entry.status,
          entry.action,
          entry.fileName,
        );
      });
    }
  };

  const dataForTable = dataBuilder(bulkUploadData); //cashing data

  console.log(dataForTable);

  const rows = () => {
    console.log('calling Raw');
    if (dataCreated) {
      console.log('Sending Cashed');
      return dataForTable;
    } else {
      console.log('preparing....');
      return dataBuilder(bulkUploadData);
    }
  };

  const downloadHanlder = (event, fileName) => {
    //console.log("FileNAme",fileName);
    dispatch(BulkUploads.downloadFile(fileName));
  };

  const printHanlder = (event, fileName) => {
    //console.log("FileNAme",fileName);
    // dispatch(BulkUploads.printBulkUploads(fileName));
    dispatch(openDialog({ ids: [fileName], origin: 'bulkUploads' }));
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows().map(n => n.bulkUploadNumber);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const shouldGetNextDataBulk = newPage => {
    const lastPageNum = page;
    const nextPageNum = newPage;
    if (page < nextPageNum) {
      //moving forward
      const remaining = rows().length - nextPageNum * rowsPerPage;
      if (remaining <= rowsPerPage) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const handleChangePage = (event, newPage) => {
    //console.log("newPAge",newPage,page,rows().length,rowsPerPage);
    if (shouldGetNextDataBulk(newPage) && isNextPage === true) {
      getNewDataBulk(apiPage + 1);
      //setApiPage(apiPage+1);
    }

    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows().length - page * rowsPerPage);

  let rowNumber = 0;

  const getNewDataBulk = () => {
    dispatch(BulkUploads.getBulkUploads(apiPage + 1));
  };

  return (
    <div>
      <BulkUploadTableToolbar numSelected={selected.length} addNewEventHandler={popUpHandler} />
      <TableContainer>
        <Table className={classes.table} aria-labelledby="tableTitle" size={'medium'} aria-label="enhanced table">
          <CustomTableHeader
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows().length}
            headCells={headCells}
            excludeCell={'print'}
          />
          <TableBody>
            {stableSort(rows(), getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.bulkUploadNumber);
                const labelId = `enhanced-table-checkbox-${index}`;
                const rowKey = `Row${index}`;
                rowNumber = index;

                return (
                  <TableRow
                    id={rowKey}
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.bulkUploadNumber}
                    selected={isItemSelected}
                    classes={{ hover: 'MuiTableRow-hover' }}>
                    <TableCell
                      padding="checkbox"
                      onClick={event => handleClick(event, row.bulkUploadNumber)}
                      className={classes.tableCellPadding}>
                      <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} color={'primary'} />
                    </TableCell>

                    <TableCell component="th" scope="row" onClick={event => handleClick(event, row.waybill)}>
                      {row.bulkUploadNumber}
                    </TableCell>
                    <TableCell align="left" onClick={event => handleClick(event, row.bulkUploadNumber)}>
                      {row.noOfWaybill}
                    </TableCell>
                    <TableCell align="left" onClick={event => handleClick(event, row.waybill)}>
                      {AppConfigs.formatDisplayDate(row.date)}
                    </TableCell>

                    <TableCell align="left" onClick={event => handleClick(event, row.waybill)}>
                      {row.status}
                    </TableCell>

                    <TableCell padding="default" align="right">
                      <Tooltip title="Print">
                        <IconButton aria-label="print" color={'primary'} onClick={event => printHanlder(event, row.id)}>
                          <PrintIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="download">
                        <IconButton
                          aria-label="download"
                          color={'primary'}
                          onClick={event => downloadHanlder(event, row.id)}>
                          <DownloadIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              }) //end of map
            }
            {emptyRows > 0 && (
              <TableRow id={`Row${rowNumber++}`} style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={12} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box className={classes.paginationContainer}>
        <Button variant="outlined" size={'small'} startIcon={<ExportIcon />} className={classes.exportIcon}>
          Export
        </Button>
        <TablePagination
          rowsPerPageOptions={[10, 25]}
          component="div"
          count={rows().length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Box>
    </div>
  );
}

export default BulkUploadDataTable;
