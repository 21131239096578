import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtImage from '@coremat/CmtImage';
import { CmtTitle } from '@coremat/CmtTypography';
import { JumboCard } from '@jumbo/components/Common';
import AppDatePicker from '@jumbo/components/Common/formElements/AppDatePicker';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import AppSelectBox from '@jumbo/components/Common/formElements/AppSelectBox';
import GrowthCard from '@jumbo/components/Common/GrowthCard';
import LabelForm from '@jumbo/components/Common/LabelForm';
import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { Box, Button, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormattedDate } from 'react-intl';
import data from '@coremat/CmtTimeLine/data';
import { useDispatch } from 'react-redux';
import User from 'services/user';
import Grid from '@material-ui/core/Grid';
import SnackBar from 'custom_components/SnackBar';

const rightPanelImage = '/images/welcomeImage.png';

const useStyles = makeStyles(theme => ({
  /*[theme.breakpoints.up('md')]: { */

  profileInfoContainer: {
    backgroundColor: theme.palette.background.paper,
    //alignContent:'center',
    //justifyContent:'center',
    borderRadius: 10,
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 15,
    paddingBottom: 5,
    display: 'flex',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)',
    //height:'100%',
  },

  form: {
    display: 'flex',
    flex: 1,
    //width:'50%',
    //paddingLeft:20,
    paddingRight: 15,
    flexDirection: 'column',
  },

  formInput: {
    marginTop: '5px',
    marginBottom: '5px',
  },

  panelContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    //85
  },

  // leftPanel: {
  //   display: 'flex',
  //   flex: 1,
  // },
  // rightPanel: {
  //   display: 'none',
  //   [theme.breakpoints.up('md')]: {
  //     visibility: 'visible',
  //     display: 'flex',
  //     flex: 1,
  //     //   backgroundColor: 'green',
  //     resize: 'contained',
  //   },
  // },

  buttonContainer: {
    display: 'flex',

    justifyContent: 'center',
    paddingTop: '25px',
  },
}));

const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'Profile', isActive: true },
];

const Profile = () => {
  //const user= useSelector(({auth})=> auth.authUser);
  const classes = useStyles('default');
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.authUser);
  const [displayName, setDisplayName] = useState(user.display_name);
  const [email, setEmail] = useState(user.user.email); //in user Model
  const [nic, setNic] = useState(user.nic);
  const [contact, setContact] = useState(user.contact_number);
  const [dob, setDob] = useState(user.date_of_birth.substring(0, 10));
  const [gender, setGender] = useState(user.gender);
  const [accountName, setAccountName] = useState(user.account.display_name);
  const [accountNumber, setaccountNumber] = useState(user.account.account_number);

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    nic: '',
    contact: '',
    dob: '',
    gender: '',
    accName: '',
    accNum: '',
    hasError: 'false',
  });

  const [passwordErrors, setPasswordErrors] = useState({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    hasError: 'false',
  });

  const displayNameHandler = event => {
    setDisplayName(event.target.value);
  };

  const emailHandler = event => {
    setEmail(event.target.value);
  };

  const nicHandler = event => {
    setNic(event.target.value);
  };

  const dobHanlder = event => {
    const year = event._d.getFullYear();
    const month = event._d.getMonth() + 1;
    const date = event._d.getDate();

    setDob(`${year}-${month}-${date}`);
  };

  const accNameHandler = event => {
    setAccountName(event.target.value);
  };

  const genderHandler = event => {
    //console.log(event.target.value);
    setGender(event.target.value);
  };

  const contactHandler = event => {
    setContact(event.target.value);
  };
  const accNumberHandler = event => {
    setaccountNumber(event.target.value);
  };

  const oldPasswordHandler = event => {
    setOldPassword(event.target.value);
  };
  const newPasswordHandler = event => {
    setNewPassword(event.target.value);
  };
  const confirmPasswordHandler = event => {
    setConfirmNewPassword(event.target.value);
  };

  const changePasswordHandler = event => {
    let localErrors = {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      hasError: 'false',
    };

    console.log(oldPassword.trim().length);

    if (oldPassword.trim() === '') {
      localErrors = {
        ...localErrors,
        oldPassword: 'Password cant be empty',
        hasError: 'true',
      };
    } else {
      localErrors = { ...localErrors, oldPassword: '' };
      if (oldPassword.trim().length < 6) {
        localErrors = {
          ...localErrors,
          oldPassword: 'Password must be at lease 6 characters',
          hasError: 'true',
        };
      } else {
        localErrors = { ...localErrors, oldPassword: '' };
      }
    }
    if (newPassword.trim() === '') {
      localErrors = {
        ...localErrors,
        newPassword: 'Password cant be empty',
        hasError: 'true',
      };
    } else {
      localErrors = { ...localErrors, newPassword: '' };
      if (newPassword.trim().length < 6) {
        localErrors = {
          ...localErrors,
          newPassword: 'Password must be at lease 6 characters',
          hasError: 'true',
        };
      } else {
        localErrors = { ...localErrors, newPassword: '' };
      }
    }
    if (confirmNewPassword.trim() === '') {
      localErrors = {
        ...localErrors,
        confirmNewPassword: 'Conform password cant be empty',
        hasError: 'true',
      };
    } else {
      localErrors = { ...localErrors, confirmNewPassword: '' };
      if (confirmNewPassword.trim() !== newPassword.trim()) {
        localErrors = {
          ...localErrors,
          confirmNewPassword: 'New and confirm passwords not matching ',
          hasError: 'true',
        };
      } else {
        localErrors = { ...localErrors, confirmNewPassword: '' };
      }
    }

    if (localErrors.hasError === 'false') {
      // console.log('submit');
      setOldPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
      dispatch(User.changePassword({ oldPassword, newPassword, confirmNewPassword }));
    }
    setPasswordErrors(localErrors);
  };

  const updateHandler = event => {
    //console.log("validationg");

    let localErrors = {
      name: '',
      email: '',
      nic: '',
      contact: '',
      dob: '',
      gender: '',
      accName: '',
      accNum: '',
      hasError: 'false',
    };

    if (!textValidate(displayName.trim())) {
      localErrors = { ...localErrors, name: 'Invalied', hasError: 'true' };
    } else {
      localErrors = { ...localErrors, name: '' };
    }

    if (!emailValidate(email)) {
      localErrors = { ...localErrors, email: 'Invalied', hasError: 'true' };
    } else {
      localErrors = { ...localErrors, email: '' };
    }

    if (!contactValidate(contact)) {
      localErrors = { ...localErrors, contact: 'Invalied', hasError: 'true' };
    } else {
      localErrors = { ...localErrors, contact: '' };
    }

    if (!numValidate(accountNumber)) {
      localErrors = { ...localErrors, accNum: 'Invalied', hasError: 'true' };
    } else {
      localErrors = { ...localErrors, accNum: '' };
    }

    if (!textValidate(accountName.trim())) {
      localErrors = { ...localErrors, accName: 'Invalied', hasError: 'true' };
    } else {
      localErrors = { ...localErrors, accName: '' };
    }

    if (!nicValidate(nic)) {
      localErrors = { ...localErrors, nic: 'Invalied', hasError: 'true' };
    } else {
      localErrors = { ...localErrors, nic: '' };
    }

    if (localErrors.hasError === 'false') {
      const updatedUser = {
        ...user,
        display_name: displayName,
        nic: nic,
        contact_number: contact,
        date_of_birth: dob,
        gender: gender,
        account: {
          ...user.account,
          display_name: accountName,
          account_number: accountNumber,
        },
        user: {
          ...user.user,
          email: email,
        },
      };
      console.log('NAme', user, updatedUser);
      dispatch(
        User.onUpdateCustomeProfile({
          displayName,
          email,
          nic,
          contact,
          dob,
          gender,
          updatedUser,
        }),
      );
    }

    setErrors(localErrors);

    // event.preventDefault();
    // console.log();
    //console.log(event.target.value);
    //inputvalidation
  };

  const textValidate = value => {
    const pattern = /^[A-Za-z ]+$/;

    if (value.match(pattern) && value.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const contactValidate = value => {
    const pattern = /^[0-9]+$/;

    if (value.match(pattern) && value.length == 10) {
      return true;
    } else {
      return false;
    }
  };

  const numValidate = value => {
    const pattern = /^[0-9]+$/;
    if (value.match(pattern)) {
      return true;
    } else {
      return false;
    }
  };

  const emailValidate = value => {
    const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (value.match(pattern)) {
      return true;
    } else {
      return false;
    }
  };

  const nicValidate = value => {
    const pattern = /[0-9]{9}[vV]$/;
    if (value.match(pattern)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <React.Fragment>
      <PageContainer heading={'Profile'} breadcrumbs={breadcrumbs} style={{ backgroundColor: 'blue' }}>
        <Box className={classes.profileInfoContainer}>
          <Grid container>
            <Grid item md={6}>
              <Box className={classes.form}>
                <form>
                  <AppTextInput
                    className={classes.formInput}
                    label={'Name'}
                    value={displayName}
                    onChange={displayNameHandler}
                    required={true}
                    type={'text'}
                  />
                  <Typography color={'primary'}>{errors.name}</Typography>

                  <AppTextInput
                    className={classes.formInput}
                    label={'Email'}
                    value={email}
                    onChange={emailHandler}
                    required={true}
                  />
                  <Typography color={'primary'}>{errors.email}</Typography>

                  <AppTextInput
                    className={classes.formInput}
                    label={'Nic'}
                    value={nic}
                    onChange={nicHandler}
                    required={true}
                  />
                  <Typography color={'primary'}>{errors.nic}</Typography>

                  <AppTextInput
                    className={classes.formInput}
                    label={'Contact'}
                    value={contact}
                    onChange={contactHandler}
                    required={true}
                  />
                  <Typography color={'primary'}>{errors.contact}</Typography>

                  <AppDatePicker label={'Date Of Birth'} value={dob} onChange={dobHanlder} required={true} />

                  <AppSelectBox
                    data={[
                      { labelKey: 'MALE', valueKey: 'MALE' },
                      { labelKey: 'FEMALE', valueKey: 'FEMALE' },
                    ]}
                    valueKey={'valueKey'}
                    labelKey={'labelKey'}
                    label={'Gender'}
                    value={gender}
                    onChange={genderHandler}
                    name={'gender'}
                    required={true}
                  />
                  <AppTextInput
                    className={classes.formInput}
                    label={'Account Name'}
                    value={accountName}
                    onChange={accNameHandler}
                    disabled={true}
                  />
                  <Typography color={'primary'}>{errors.accName}</Typography>

                  <AppTextInput
                    className={classes.formInput}
                    label={'Account Number'}
                    value={accountNumber}
                    onChange={accNumberHandler}
                    disabled={true}
                  />
                  <Typography color={'primary'}>{errors.accNum}</Typography>

                  <Box className={classes.buttonContainer}>
                    <Button size={'large'} color={'primary'} variant="contained" onClick={updateHandler}>
                      Submit
                    </Button>
                  </Box>
                </form>
              </Box>
            </Grid>
            <Grid item md={6}>
              <form>
                <AppTextInput
                  type="password"
                  className={classes.formInput}
                  label={'Old Password'}
                  value={oldPassword}
                  onChange={oldPasswordHandler}
                  required={true}
                />
                <Typography color={'primary'}>{passwordErrors.oldPassword}</Typography>

                <AppTextInput
                  type="password"
                  className={classes.formInput}
                  label={'New Password'}
                  value={newPassword}
                  onChange={newPasswordHandler}
                  required={true}
                />
                <Typography color={'primary'}>{passwordErrors.newPassword}</Typography>

                <AppTextInput
                  type="password"
                  className={classes.formInput}
                  label={'Confirm Password'}
                  value={confirmNewPassword}
                  onChange={confirmPasswordHandler}
                  required={true}
                />
                <Typography color={'primary'}>{passwordErrors.confirmNewPassword}</Typography>

                <Box className={classes.buttonContainer}>
                  <Button size={'large'} color={'primary'} variant="contained" onClick={changePasswordHandler}>
                    Change Password
                  </Button>
                </Box>
              </form>
            </Grid>
          </Grid>
        </Box>
      </PageContainer>
      <SnackBar />
    </React.Fragment>
  );
};

export default Profile;
