import { makeStyles, Box, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React, { useEffect, useState } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { useDispatch, useSelector } from 'react-redux';
import { Tree, TreeNode } from 'react-organizational-chart';
import OverviewCard from '@jumbo/components/Common/OverviewCard';
import PieChart from '@jumbo/components/Common/PieChart';
import BarChart from '@jumbo/components/Common/BarChart';
import Overviews from 'services/overview';
import moment from 'moment';
import ChartCard from '@jumbo/components/Common/ChartCard';

const useStyles = makeStyles(theme => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gridTemplateRows: 'repeat(2, 1fr)',
    gap: '28px',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  item1: {
    gridRow: 'span 2',
  },
  item2: {
    gridColumn: 'span 2',
    gridRow: 'span 2',
    '& > .css-nubo1m': {
      width: '100%',
    },
  },
  item3: {
    gridColumn: 'span 2',
    gridRow: 'span 2',
    gridColumnStart: 4,
    gridColumnEnd: 6,
    '& > .css-nubo1m': {
      width: '100%',
    },
  },
  item4: {
    gridRow: 'span 2',
    gridColumnStart: 6,
  },
  item5: {
    gridRow: 'span 2',
    gridColumn: 'span 2',
    gridColumnStart: 1,
    gridColumnEnd: 3,
  },
  item6: {
    gridRow: 'span 4',
    gridColumn: 'span 2',
    gridColumnStart: 3,
    gridColumnEnd: 7,
  },
  datePickerContainer: {
    display: 'flex',
    flex: 6,
    justifyContent: 'start',
    alignItems: 'center',
    paddingRight: 20,

    //backgroundColor:'yellow'
  },
  cardRoot: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: '4px',
    padding: 24,
  },
}));

const shopLocation = '/images/icons/shop-location.png';
const deliveryTime = '/images/icons/delivery-time.png';
const fastDelivery = '/images/icons/fast-delivery.png';
const outForDelivery = '/images/icons/out-for-delivery.png';
const shipped = '/images/icons/shipped.png';
const complete = '/images/icons/complete.png';
const truck = '/images/icons/truck.png';
const deliveryStatus = '/images/icons/delivery-status.png';

function getBgColor(bgColor) {
  switch (bgColor) {
    case 'picked':
      return '#0EA5E9';
    case 'undelivered':
      return '#EAB308';
    case 'completed':
      return '#22C55E';
    case 'returned':
      return '#BE0707';
    case 'toBeRetured':
      return '#EF4444';
    default:
      return null;
  }
}

function Overview() {
  const dispatch = useDispatch();
  const overviewData = useSelector(state => state.overview);
  const [data, setData] = useState({});
  const [fromDate, setFromDate] = useState(
    moment()
      .clone()
      .startOf('month'),
  );
  const [toDate, setToDate] = useState(
    moment()
      .clone()
      .endOf('month'),
  );

  useEffect(() => {
    // isVisible = true;
    Overviews.getOverview(
      moment()
        .clone()
        .startOf('month')
        .format('DD-MM-YYYY'),
      moment()
        .clone()
        .endOf('month')
        .format('DD-MM-YYYY'),
    )(dispatch);
  }, []);
  useEffect(() => {
    setData(overviewData.data);
  }, [overviewData]);

  const classes = useStyles();
  return (
    <React.Fragment>
      <PageContainer heading={'Dashboard'.toUpperCase()}>
        <div style={{ marginBottom: 12 }}>
          <Box className={classes.datePickerContainer}>
            <div style={{ backgroundColor: '#fff', marginRight: 12, borderRadius: 4 }}>
              <KeyboardDatePicker
                style={{ margin: '4px 15px 4px' }}
                disableToolbar
                variant="inline"
                format="DD/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Period From"
                value={fromDate}
                onChange={e => {
                  setFromDate(e._d);
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </div>
            <div style={{ backgroundColor: '#fff', marginRight: 12, borderRadius: 4 }}>
              <KeyboardDatePicker
                style={{ margin: '4px 15px 4px' }}
                disableToolbar
                variant="inline"
                format="DD/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Period To"
                value={toDate}
                onChange={e => {
                  setToDate(e._d);
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </div>
            <Button
              variant="contained"
              style={{ marginRight: 15 }}
              className={classes.newRequestButton}
              onClick={() => {
                dispatch(
                  Overviews.getOverview(
                    moment(new Date(fromDate)).format('DD-MM-YYYY'),
                    moment(new Date(toDate)).format('DD-MM-YYYY'),
                  ),
                );
              }}>
              Filter
            </Button>
          </Box>
        </div>
        <Grid container spacing={4} style={{ justifyContent: 'space-between' }}>
          <Grid item xs={12} sm={6} md={6} lg={2}>
            <div style={{ height: '100%' }}>
              <OverviewCard
                fromDate={fromDate}
                toDate={toDate}
                title={data.picked?.toLocaleString('en-LK')}
                subTitle={'Picked(FM Scans)'}
                titleProps={{ fontSize: '28px', marginRight: '8px' }}
                subTitleProps={{ color: '#64748B', fontSize: '16px' }}
                icon={shopLocation}
                bgColor={getBgColor('picked')}
                status={'picked'}
                statusType={'PICKED'}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <div style={{ height: '100%' }}>
              <Tree
                lineWidth={'2px'}
                lineColor={'black'}
                lineBorderRadius={'2px'}
                css={{ width: '100%' }}
                label={
                  <div>
                    <OverviewCard
                      fromDate={fromDate}
                      toDate={toDate}
                      title={(data.inTransit + data.outForDelivery).toLocaleString('en-LK')}
                      titleProps={{ fontSize: '28px', marginRight: '8px' }}
                      subTitle={'Undelivered (UD)'}
                      subTitleProps={{ color: '#64748B', fontSize: '16px' }}
                      icon={deliveryTime}
                      bgColor={getBgColor('undelivered')}
                      status={'undelivered'}
                      statusType={'undelivered'}
                      isHorizontal={true}
                      isFirstTier={true}
                    />
                  </div>
                }>
                <TreeNode
                  label={
                    <div style={{ height: '100%' }}>
                      <OverviewCard
                        fromDate={fromDate}
                        toDate={toDate}
                        title={data.inTransit?.toLocaleString('en-LK')}
                        titleProps={{ fontSize: '28px', marginRight: '8px' }}
                        subTitle={'In Transit'}
                        subTitleProps={{ color: '#64748B', fontSize: '16px' }}
                        icon={fastDelivery}
                        bgColor={getBgColor('undelivered')}
                        status={'undelivered'}
                        statusType={'UD'}
                        isHorizontal={true}
                      />
                    </div>
                  }></TreeNode>
                <TreeNode
                  label={
                    <div>
                      <OverviewCard
                        fromDate={fromDate}
                        toDate={toDate}
                        title={data.outForDelivery?.toLocaleString('en-LK')}
                        titleProps={{ fontSize: '28px', marginRight: '8px' }}
                        subTitle={'Out for Delivery'}
                        subTitleProps={{ color: '#64748B', fontSize: '16px' }}
                        icon={outForDelivery}
                        bgColor={getBgColor('undelivered')}
                        status={'undelivered'}
                        statusType={'OUD'}
                        isHorizontal={true}
                      />
                    </div>
                  }></TreeNode>
              </Tree>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <div style={{ height: '100%' }}>
              <Tree
                lineWidth={'2px'}
                lineColor={'black'}
                lineBorderRadius={'2px'}
                label={
                  <div>
                    <OverviewCard
                      fromDate={fromDate}
                      toDate={toDate}
                      title={(data.delivered + data.returned).toLocaleString('en-LK')}
                      titleProps={{ fontSize: '28px', marginRight: '8px' }}
                      subTitle={'Completed'}
                      subTitleProps={{ color: '#64748B', fontSize: '16px' }}
                      icon={shipped}
                      bgColor={getBgColor('completed')}
                      status={'completed'}
                      statusType={'completed'}
                      isHorizontal={true}
                      isFirstTier={true}
                    />
                  </div>
                }>
                <TreeNode
                  label={
                    <div>
                      <OverviewCard
                        fromDate={fromDate}
                        toDate={toDate}
                        title={data.delivered?.toLocaleString('en-LK')}
                        titleProps={{ fontSize: '28px', marginRight: '8px' }}
                        subTitle={'Delivered (DL)'}
                        subTitleProps={{ color: '#64748B', fontSize: '16px' }}
                        icon={complete}
                        bgColor={getBgColor('completed')}
                        status={'completed'}
                        statusType={'DL'}
                        isHorizontal={true}
                      />
                    </div>
                  }></TreeNode>
                <TreeNode
                  label={
                    <div>
                      <OverviewCard
                        fromDate={fromDate}
                        toDate={toDate}
                        title={data.returned?.toLocaleString('en-LK')}
                        titleProps={{ fontSize: '28px', marginRight: '8px' }}
                        subTitle={'Returned (RTM)'}
                        subTitleProps={{ color: '#64748B', fontSize: '16px' }}
                        icon={truck}
                        bgColor={getBgColor('returned')}
                        status={'returned'}
                        statusType={'RTM'}
                        isHorizontal={true}
                      />
                    </div>
                  }></TreeNode>
              </Tree>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2}>
            <div style={{ height: '100%' }}>
              <OverviewCard
                fromDate={fromDate}
                toDate={toDate}
                title={data.toBeReturned?.toLocaleString('en-LK')}
                titleProps={{ fontSize: '28px', marginRight: '8px' }}
                subTitle={'To Be Returned (RT)'}
                subTitleProps={{ color: '#64748B', fontSize: '16px' }}
                icon={deliveryStatus}
                bgColor={getBgColor('toBeRetured')}
                status={'toBeRetured'}
                statusType={'RT'}
              />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={4} style={{ justifyContent: 'space-between', marginTop: 28 }}>
          <Grid item xs={12} sm={12} md={5} lg={4}>
            <ChartCard title={'Shipment breakdown'}>
              <PieChart data={data.breakdown} />
            </ChartCard>
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={8}>
            <ChartCard title={'Delivery Performance (DL)'}>
              <BarChart data={data.deliveryPerformace} />
            </ChartCard>
          </Grid>
        </Grid>
      </PageContainer>
    </React.Fragment>
  );
}

export default Overview;