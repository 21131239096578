import { IconButton, Toolbar, Tooltip, Typography } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import PrintIcon from '@material-ui/icons/Print';
import React from 'react';
import { openDialog, closeDialog } from '../redux/actions/PrintOrder';
import { useDispatch } from 'react-redux';
import RefreshIcon from '@material-ui/icons/Refresh';

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    backgroundColor: '#FFFFFF',
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.main, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },

  toolBarbg: {
    backgroundColor: '#F7F8FA', //'#F7F8FA'
  },
  line: {
    width: '100%',
    height: 1,
    backgroundColor: 'black',
  },

  text: {
    paddingRight: 10,
  },
}));

function EnhancedTableToolbar(props) {
  const dispatch = useDispatch();
  const classes = useToolbarStyles();
  const { numSelected, selected, enablePrint, enableRefresh, handleRefresh, origin } = props;

  // console.log('Nums', numSelected);
  // console.log('Ids', selected);
  return (
    <React.Fragment>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}>
        {numSelected > 0 ? (
          <Typography component={'span'} className={classes.title} color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography component={'span'} className={classes.title} variant="h6" id="tableTitle" />
        )}

        {numSelected > 0 ? (
          <React.Fragment>
            {enablePrint && (
              <Tooltip title="Print">
                <IconButton
                  aria-label="print"
                  onClick={() => {
                    dispatch(openDialog({ ids: selected, origin: origin }));
                  }}>
                  <PrintIcon color={'primary'} />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Delete">
              <IconButton aria-label="delete">
                <DeleteIcon color={'primary'} />
              </IconButton>
            </Tooltip>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {enableRefresh ? (
              <Tooltip title="Refresh">
                <IconButton aria-label="refresh" onClick={handleRefresh}>
                  <RefreshIcon color={'primary'} />
                </IconButton>
              </Tooltip>
            ) : (
              <Typography className={classes.text} component={'span'} variant={'h5'}>
                OPTIONS
              </Typography>
            )}
          </React.Fragment>
        )}
      </Toolbar>
      <div className={classes.line} />
    </React.Fragment>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default EnhancedTableToolbar;
