import { SET_TRACKING_HISTORY_IN_REF_MODE, SET_TRACKING_HISTORY_IN_TN_MODE } from '../../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  data: {},
  history: [],
  refCollection: [],
  refMode: false,
  loaded: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_TRACKING_HISTORY_IN_REF_MODE: {
      console.log('tnt', action.payload);
      return {
        ...state,
        refCollection: action.payload,
        refMode: true,
        loaded: true,
      };
    }

    case SET_TRACKING_HISTORY_IN_TN_MODE: {
      console.log('tnt track number ekak', action.payload);
      return {
        ...state,
        data: action.payload,
        history: action.payload.tracking_history,
        refMode: false,
        loaded: true,
      };
    }

    default:
      return state;
  }
};
