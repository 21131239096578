import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Orders from './Pages/Orders';
import OrdersEs from './Pages/OrdersEs';
import Profile from './Pages/Profile';
import Account from './Pages/Account';
import Error404 from './Pages/404';
import Login from './Auth/Login';
import Register from './Auth/Register';
import ForgotPasswordPage from './Auth/ForgotPassword';

import Overview from './Pages/Overview';
import BulkUpload from './Pages/BulkUpload';
import OrderTracking from './Pages/TrackingStatus';
import PickupRequest from './Pages/PickupRequest';
import AddOrder from './Pages/AddOrder';
import Reports from './Pages/Reports';
import PrintDialog from 'custom_components/PrintDialog';
import CodPayments from './Pages/CodPayments';
import Support from './Pages/Support';
import OrderList from './Pages/TrackingStatus/TrackingList';
import OrdersNew from './Pages/OrdersNew';

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  console.log('Restrict', authUser);
  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const location = useLocation();

  if (location.pathname === '' || location.pathname === '/') {
    return <Redirect to={'/orders'} />;
  } else if (
    authUser &&
    (location.pathname === '/signin' || location.pathname === '/signup' || location.pathname === '/forgot-password')
  ) {
    //authUser && location.pathname === '/signin'
    return <Redirect to={'/orders'} />;
  }

  return (
    <React.Fragment>
      <Switch>
        {/*  Replace the following URL with the old orders page*/}
        <RestrictedRoute path="/orders" component={Orders} />
        <RestrictedRoute path="/orders-new" component={OrdersNew} />
        {/* <RestrictedRoute path="/orders-es" component={OrdersEs} /> */}
        <RestrictedRoute path="/add-order" component={AddOrder} />
        <RestrictedRoute path="/pickup-request" component={PickupRequest} />
        <RestrictedRoute path="/bulk-upload" component={BulkUpload} />
        {/*<RestrictedRoute path="/customer-profile" component={Profile} />*/}
        <RestrictedRoute path="/account" component={Account} />
        <RestrictedRoute path="/reports" component={Reports} />
        <RestrictedRoute path="/cod-payments" component={CodPayments} />
        <RestrictedRoute path="/dashboard" component={Overview} />
        <RestrictedRoute path="/support" component={Support} />
        <RestrictedRoute path="/order_tracking" component={OrderTracking} />
        <RestrictedRoute path="/order_list" component={OrderList} />
        <Route path="/signin" component={Login} />
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        //only for dev
        <Route component={Error404} />
      </Switch>
      <PrintDialog />
    </React.Fragment>
  );
};

export default Routes;
