import { Box } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react'
import AppTheme from 'theme/AppTheme';

const useStyles = makeStyles(theme => ({
    root: {
        display:'flex',
        width:'100',
        height: 72,
        backgroundColor:AppTheme.primaryColor,

    },
  }));

function SidebarCustomHeader() {

    
    const classes=useStyles();
    return (
        <Box className={classes.root}></Box>
    )
}

export default SidebarCustomHeader
