import { DISPLAY_BULK_UPLOADS, OPEN_BULK_UPLOAD_DIALOG, CLOSE_BULK_UPLOAD_DIALOG } from '../../@jumbo/constants/ActionTypes';

export const setBulkUploads = (records, page, isNextPage, isInitial) => {
  return dispatch => {
    dispatch({
      type: DISPLAY_BULK_UPLOADS,
      payload: { records, page, isNextPage, isInitial },
    });
  };
};
export const openBulkUploadDialog = () => {
  return dispatch => {
    dispatch({
      type: OPEN_BULK_UPLOAD_DIALOG,
    });
  };
};
export const closeBulkUploadDialog = () => {
  return dispatch => {
    dispatch({
      type: CLOSE_BULK_UPLOAD_DIALOG,
    });
  };
};
