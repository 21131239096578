import React from 'react';
import CmtVertical from '../../../../../@coremat/CmtNavigation/Vertical';
import PerfectScrollbar from 'react-perfect-scrollbar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IntlMessages from '../../../../utils/IntlMessages';
import { PostAdd } from '@material-ui/icons';
import MenuIcon from 'custom_components/MenuIcon';
import AppImages from 'Utils/AppImages';
import OrderIcon from 'custom_components/OrderIcon';
import ReportIcon from 'custom_components/ReportIcon';
import BulkUploadIcon from 'custom_components/BulkUploadIcon';
import CODIcon from 'custom_components/CODIcon';
import AppLogo from 'custom_components/AppLogo';
import Logo from '../Logo';
import PickupRequest from '../../../../../routes/Pages/PickupRequest/index';
import PickUpRequestIcon from '../../../../../custom_components/PickUpRequestIcon';
import CommentIcon from '@material-ui/icons/Comment';

const useStyles = makeStyles(theme => ({
  perfectScrollbarSidebar: {
    //height: '80%',
    //backgroundColor:'green',

    transition: 'all 0.3s ease',
    height: 'calc(100% - 167px)',
    // '.Cmt-sidebar-fixed &, .Cmt-Drawer-container &': {
    //   height: 'calc(100% - 167px)',
    // },
    // '.Cmt-modernLayout &': {
    //   height: 'calc(100% - 72px)',
    // },
    // '.Cmt-miniLayout &': {
    //   height: 'calc(100% - 91px)',
    // },
    // '.Cmt-miniLayout .Cmt-sidebar-content:hover &': {
    //   height: 'calc(100% - 167px)',
    // },
  },
}));

const SideBar = () => {
  const classes = useStyles();
  const navigationMenus = [
    {
      name: <IntlMessages id={'sidebar.main'} />,
      type: 'section',
      children: [
        {
          name: 'Dashboard',
          icon: <ReportIcon />,
          type: 'item',
          link: '/dashboard',
        },
        {
          name: 'Orders',
          icon: <OrderIcon />,
          type: 'item',
          link: '/orders',
        },
        {
          name: 'Bulk Upload',
          icon: <BulkUploadIcon />,
          type: 'item',
          link: '/bulk-upload',
        },
        {
          name: 'COD Payments',
          icon: <ReportIcon />,
          type: 'item',
          link: '/cod-payments',
        },
        {
          name: 'Pickup Request',
          icon: <PickUpRequestIcon />,
          type: 'item',
          link: '/pickup-request',
        },
        {
          name: 'Reports',
          icon: <ReportIcon />,
          type: 'item',
          link: '/reports',
        },
        {
          name: 'Support',
          icon: <CommentIcon />,
          type: 'item',
          link: '/support',
        },
      ],
    },
  ];

  return (
    <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
      <CmtVertical menuItems={navigationMenus} />
    </PerfectScrollbar>
  );
};

export default SideBar;
