import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { useDispatch, useSelector } from 'react-redux';
import { openDialog, closeDialog } from '../redux/actions/PrintOrder';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import { Paper, FormControl, FormControlLabel, Radio, Grid } from '@material-ui/core';
import printOrder from 'services/printOrder';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.white,
  },
  closeButton: {
    color: theme.palette.text.white,
  },
});

const useStyles = makeStyles(theme => ({
  paperContainer: {
    width: '140px',
    margin: '0 auto',
    minHeight: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  paperContainerSelected: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
  contentWrapper: {
    display: 'flex',
    margin: '20px 100px',
    width: 'fit-content',
  },
  gridItemWrapper: {
    borderRight: '1px solid',
    borderRightColor: theme.palette.borderColor.dark,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={clsx([classes.root, classes.titleWrapper])} {...other}>
      <Typography variant="h4">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const PrintDialog = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { open, ids, origin } = useSelector(state => state.printOrder);
  const [pageSize, setPageSize] = useState('A4');
  const [perPageWayBillCount, setPerPageWayBillCount] = useState('1');

  const handleClose = () => {
    dispatch(closeDialog());
  };
  const handlePrint = () => {
    if (origin === 'waybills') {
      dispatch(printOrder.printWaybills(ids, pageSize, perPageWayBillCount));
    } else {
      dispatch(printOrder.printBulkUploads(ids, pageSize));
    }
    // dispatch(closeDialog());
  };
  return (
    <React.Fragment>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        TransitionComponent={Transition}
        open={open}
        fullWidth={true}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Print Size Options
        </DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid item md={6} className={classes.gridItemWrapper}>
              <Paper
                onClick={() => {
                  setPageSize('A4');
                  setPerPageWayBillCount('1');
                }}
                variant="outlined"
                className={clsx(classes.paperContainer, {
                  [classes.paperContainerSelected]: pageSize === 'A4',
                })}>
                <Typography align="center" variant="h4">
                  A4
                </Typography>
              </Paper>
              {origin === 'waybills' && (
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="4InOne"
                    name="radio-buttons-group"
                    value={perPageWayBillCount}
                    onChange={e => {
                      setPerPageWayBillCount(e.target.value);
                    }}>
                    <FormControlLabel
                      value="4"
                      control={<Radio disabled={pageSize !== 'A4'} />}
                      label="4 WAYBILLS IN ONE PAGE"
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio disabled={pageSize !== 'A4'} />}
                      label="2 WAYBILLS IN ONE PAGE"
                    />
                    <FormControlLabel
                      value="1"
                      control={<Radio disabled={pageSize !== 'A4'} />}
                      label="1 WAYBILL IN ONE PAGE"
                    />
                  </RadioGroup>
                </FormControl>
              )}
            </Grid>
            <Grid item md={6}>
              <Paper
                onClick={() => {
                  setPageSize('4X6');
                  setPerPageWayBillCount(null);
                }}
                variant="outlined"
                // style={{ marginLeft: '15px' }}
                className={clsx(classes.paperContainer, {
                  [classes.paperContainerSelected]: pageSize === '4X6',
                })}>
                <Typography align="center" variant="h4">
                  4 X 6 Labels
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus variant="outlined" onClick={handlePrint}>
            Print
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default PrintDialog;
