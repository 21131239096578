import { SET_IS_RECORDS_AVAILABLE, setIsRecordsAvailable } from '../actions/actions';

export const initialState = {
  isRecordsAvailable: true,
  errMessage: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_IS_RECORDS_AVAILABLE:
      return {
        ...state,
        isRecordsAvailable: action.payload,
      };

    default:
      return state;
  }
}
