import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';
import { SvgIcon } from '@material-ui/core';

function BulkUploadIcon() {
    return (
        <SvgIcon  viewBox="0 0 25 25" >
            <g transform="translate(2102.209 -665.5)">
            <path d="M-2094.525,649.061h24v24h-24Z" transform="translate(-7.183 16.939)" fill="none" stroke="#707070" opacity="0"/>
            <path d="M9.673,5.449V0H1.036A1,1,0,0,0,0,.962V19.553a1,1,0,0,0,1.036.962h14.51a1,1,0,0,0,1.036-.962V6.411H10.71A1,1,0,0,1,9.673,5.449ZM12.488,14.1H9.673v3.205a.667.667,0,0,1-.691.641H7.6a.667.667,0,0,1-.691-.641V14.1H4.095a.636.636,0,0,1-.487-1.1L7.772,9.173a.778.778,0,0,1,1.038,0l4.164,3.834A.636.636,0,0,1,12.488,14.1Zm3.792-9.9L12.053.28A1.078,1.078,0,0,0,11.318,0h-.263V5.129h5.528V4.884A.925.925,0,0,0,16.28,4.207Z" transform="translate(-2098 667.743)" />
            </g>
        
        </SvgIcon>
    )
}

export default BulkUploadIcon
