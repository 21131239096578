import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import { setAuthUser, setForgetPassMailSent, updateLoadUser } from '../../../redux/actions/Auth';
import React from 'react';
import axios from './config';
import { loadCitiesSuccess } from '../../../redux/actions/Cities';

//axios.defaults.headers.common["Accept"]='application/json';

const JWTAuth = {
  onRegister: ({ name, email, password }) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('auth/register', {
          email: email,
          password: password,
          name: name,
        })
        .then(({ data }) => {
          if (data.result) {
            localStorage.setItem('token', data.token.access_token);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token.access_token;
            dispatch(fetchSuccess());
            dispatch(JWTAuth.getAuthUser(true, data.token.access_token));
          } else {
            //meka balaam
            dispatch(fetchError(data.error));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },

  onLogin: ({ email, password }) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        axios
          .post('/login', {
            email: email,
            password: password,
          })
          .then(({ data }) => {
            const result = data.data;
            const status = data.status;
            console.log(data.status);

            //data={"status":"fail","data":{"error":"Invalied Credentials"}}}

            if (status == 'success') {
              console.log(result.user.token);
              localStorage.setItem('token', result.user.token);
              axios.defaults.headers.common['Authorization'] = 'Bearer ' + result.user.token;
              dispatch(fetchSuccess());
              dispatch(JWTAuth.getAuthUser(true, result.user.token));
              dispatch(loadCitiesSuccess(result['cities']));
            } else {
              //check forinvalied

              if (result.validation_error) {
                const email = result.validation_error[0].email;
                const password = result.validation_error[1].password;
                dispatch(fetchError(`${email} ${password}`));
              } else {
                dispatch(fetchError(result.error));
              }
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  onLogout: () => {
    return dispatch => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token'); //check
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios
        .get('/logout')
        .then(({ data }) => {
          const result = data.data;
          const status = data.status;
          if (status == 'success') {
            dispatch(fetchSuccess());
            localStorage.removeItem('token');
            dispatch(setAuthUser(null));
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },

  getAuthUser: (loaded = false, token) => {
    //console.log(axios.defaults.headers);

    return dispatch => {
      if (!token) {
        const token = localStorage.getItem('token');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      }

      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));
      axios
        .get('user-profile') //auth/me
        .then(({ data }) => {
          console.log('Hello', data);

          const status = data.status;
          const result = data.data;

          console.log(data, status);

          if (result) {
            console.log('inside', result);
            dispatch(fetchSuccess());
            dispatch(setAuthUser(result['customer_profile']));
          } else {
            dispatch(updateLoadUser(true));
          }
        })
        .catch(function(error) {
          console.log('err');
          console.log('heeeeeeeeee', error);
          //const errorMessage=error.response.data.message;
          dispatch(updateLoadUser(true));
          //dispatch(fetchError(errorMessage));//added my me
        });
    };
  },

  onForgotPassword: () => {
    return dispatch => {
      dispatch(fetchStart());

      setTimeout(() => {
        dispatch(setForgetPassMailSent(true));
        dispatch(fetchSuccess());
      }, 300);
    };
  },
  getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>;
  },
};

export default JWTAuth;
