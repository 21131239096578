import React from 'react';
import BaseModal from './BaseModal';
import { makeStyles } from '@material-ui/core';

function AttachmentModal({ open, onClose, image }) {
  return (
    <BaseModal
      open={open}
      onClose={onClose}
      title="Attachment"
      content={
        <div>
          <img src={image} />
        </div>
      }
      // actions={
      //   <Box style={{ display: 'inline-flex', width: '100%', gap: 12 }}>
      //     <Box style={{ flex: 1, width: '100%' }}>
      //       <Button style={{ width: '100%' }} size={'large'} color={'primary'} variant="outlined" onClick={() => {}}>
      //         Close
      //       </Button>
      //     </Box>
      //     <Box style={{ flex: 1, width: '100%' }}>
      //       <Button style={{ width: '100%' }} size={'large'} color={'primary'} variant="contained" onClick={() => {}}>
      //         Create
      //       </Button>
      //     </Box>
      //   </Box>
      // }
    />
  );
}

export default AttachmentModal;
