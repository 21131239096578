import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import { Box, Button, IconButton, makeStyles, Paper, TextField, Typography } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { DateTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import CancelIcon from '@material-ui/icons/Cancel';
import React, { useReducer, useState } from 'react';
import AppDatePicker from '@jumbo/components/Common/formElements/AppDatePicker';
import PickUps from 'services/pickups';
import Snackbar from '@material-ui/core/Snackbar';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { resetPickUpCreateState } from 'redux/actions/Pickups';
import AppAutocomplete from '@jumbo/components/Common/formElements/AppAutocomplete';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  /*[theme.breakpoints.up('md')]: { */

  profileInfoContainer: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 15,
    paddingBottom: 5,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)',
    //display:'none',
  },

  form: {
    display: 'flex',
    flex: 1,
    width: '50%',
    paddingLeft: 20,
    paddingRight: 15,
    flexDirection: 'column',
  },

  formTitle: {
    paddingBottom: 20,
    paddingTop: 15,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  formInput: {
    marginTop: '5px',
    marginBottom: '5px',
  },

  panelContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    //85
  },

  leftPanel: {
    display: 'flex',
    flex: 1,
  },

  rightPanel: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      visibility: 'visible',
      display: 'flex',
      flex: 1,
      backgroundColor: 'green',
      resize: 'contained',
    },
  },
  labelMargin: {
    marginTop: 25,
  },

  datePickerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  datePickerWrapper: {
    display: 'flex',
  },
}));
function NewRequest({ toggleRequest }) {
  const [validationErrors, setValidationErrors] = useState({
    requestedBy: '',
    approvedBy: '',
    remarks: '',
    fileTyle: '',
    hasError: 'false',
  });
  const classes = useStyles();

  const showAlert = useSelector(state => state.pickups.showAlert);
  const message = useSelector(state => state.pickups.message);
  const success = useSelector(state => state.pickups.success);
  const cities = useSelector(state => state.cities.cities);

  const [merchant, setMerchant] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [addressLine3, setAddressLine3] = useState('');
  const [pickupAddressCity, setPickupAddressCity] = useState('');
  const [contactName, setContactName] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [pickUpTimeFrom, setPickUpTimeFrom] = useState(new Date());
  const [pickUpTimeTo, setPickUpTimeTo] = useState(new Date());
  const [waybillCount, setWaybillCount] = useState('');
  const [maxWeight, setMaxWeight] = useState('0.5');

  const dispatch = useDispatch();

  const [errors, setErrors] = useState({
    //merchantName: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    pickupAddressCity: '',
    name: '',
    contactNumber: '',
    time: '',
    waybillCount: '',
    maxWeight: '',
    dateFrom: '',
    dateTo: '',
    hasError: 'false',
  });

  function merchantHAndler(event) {
    setMerchant(event.target.value);
  }
  function addressLine1Handler(event) {
    setAddressLine1(event.target.value);
  }

  function addressLine2Handler(event) {
    setAddressLine2(event.target.value);
  }
  function addressLine3Handler(event) {
    setAddressLine3(event.target.value);
  }

  function cityHandler(event) {
    pickupAddressCity(event.target.value);
  }

  function contactNameHandler(event) {
    setContactName(event.target.value);
  }

  function contactNumberHandler(event) {
    setContactNumber(event.target.value);
  }

  function pickUpTimeFromHandler(value) {
    setPickUpTimeFrom(value._d);
  }

  function pickUpTimeToHandler(value) {
    setPickUpTimeTo(value._d);
  }

  function waybillCountHandler(event) {
    setWaybillCount(event.target.value);
  }

  function maxWeightHandler(event) {
    setMaxWeight(event.target.value);
  }

  //validation
  const updateHandler = event => {
    event.preventDefault();

    let localErrors = {
      //merchantName: '',
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      city: '',
      name: '',
      contactNumber: '',
      time: '',
      waybillCount: '',
      maxWeight: '',
      dateFrom: '',
      dateTo: '',
      hasError: 'false',
    };

    // if (!textValidate(merchant.trim())) {
    //   localErrors = {
    //     ...localErrors,
    //     merchantName: 'Incorrect',
    //     hasError: 'true',
    //   };
    // } else {
    //   localErrors = { ...localErrors, merchantName: '' };
    // }

    // if (!numValidate(city.trim())) {
    //   localErrors = { ...localErrors, city: 'Incorrect', hasError: 'true' };
    // } else {
    //   localErrors = { ...localErrors, city: '' };
    // }
    if (!textValidate(contactName.trim())) {
      localErrors = { ...localErrors, name: 'Incorrect', hasError: 'true' };
    } else {
      localErrors = { ...localErrors, name: '' };
    }

    if (!addressValidate(addressLine1.trim())) {
      localErrors = {
        ...localErrors,
        addressLine1: 'Incorrect',
        hasError: 'true',
      };
    } else {
      localErrors = { ...localErrors, addressLine1: '' };
    }

    // if (!addressValidate(addressLine2.trim())) {
    //   localErrors = {
    //     ...localErrors,
    //     addressLine2: 'Incorrect',
    //     hasError: 'true',
    //   };
    // } else {
    //   localErrors = { ...localErrors, addressLine2: '' };
    // }

    // if (!addressValidate(addressLine3.trim())) {
    //   localErrors = {
    //     ...localErrors,
    //     addressLine3: 'Incorrect',
    //     hasError: 'true',
    //   };
    // } else {
    //   localErrors = { ...localErrors, addressLine3: '' };
    // }

    if (!contactValidate(contactNumber)) {
      localErrors = {
        ...localErrors,
        contactNumber: 'Invalid',
        hasError: 'true',
      };
    } else {
      localErrors = { ...localErrors, contactNumber: '' };
    }

    if (!numValidate(waybillCount.trim())) {
      localErrors = {
        ...localErrors,
        waybillCount: 'Invalid',
        hasError: 'true',
      };
    } else {
      localErrors = { ...localErrors, waybillCount: '' };
    }

    if (!floatingNumValidation(maxWeight.trim())) {
      localErrors = { ...localErrors, maxWeight: 'Invalid', hasError: 'true' };
    } else {
      localErrors = { ...localErrors, maxWeight: '' };
    }

    const pickupData = {
      pickup_address_line_1: addressLine1,
      pickup_address_line_2: addressLine2,
      pickup_address_line_3: addressLine3,
      pickup_address_city_id: pickupAddressCity,
      pickup_contact_person: contactName,
      pickup_contact_number_1: contactNumber,
      pickup_from_datetime: `${pickUpTimeFrom.getDate()}/${pickUpTimeFrom.getMonth() + 1}/${pickUpTimeFrom.getFullYear()} ${
        pickUpTimeFrom.getHours() < 10 ? '0' + pickUpTimeFrom.getHours() : pickUpTimeFrom.getHours()
      }:${pickUpTimeFrom.getMinutes() < 10 ? '0' + pickUpTimeFrom.getMinutes() : pickUpTimeFrom.getMinutes()}`,
      pickup_to_datetime: `${pickUpTimeTo.getDate()}/${pickUpTimeTo.getMonth() + 1}/${pickUpTimeTo.getFullYear()} ${
        pickUpTimeTo.getHours() < 10 ? '0' + pickUpTimeTo.getHours() : pickUpTimeTo.getHours()
      }:${pickUpTimeTo.getMinutes() < 10 ? '0' + pickUpTimeTo.getMinutes() : pickUpTimeTo.getMinutes()}`,
      estimated_item_count: waybillCountHandler,
      estimated_pickup_weight_kg: maxWeight,
    };
    if (localErrors.hasError === 'false') {
      dispatch(PickUps.setPickUp(pickupData));
    }

    //dateValidate(pickUpDateFre);

    console.log(pickupData);
    setErrors(localErrors);
  };

  const dateValidate = date => {
    const selectedDate = new Date(date);
    alert(selectedDate);
  };

  const numValidate = value => {
    const pattern = /^[0-9]+$/;
    if (value.match(pattern)) {
      return true;
    } else {
      return false;
    }
  };
  const floatingNumValidation = value => {
    const pattern = /^([0-9]*[.])?[0-9]+$/;
    if (value.match(pattern)) {
      return true;
    } else {
      return false;
    }
  };

  const textValidate = value => {
    const pattern = /^[A-Za-z ]+$/;

    if (value.match(pattern) && value.length > 0) {
      return true;
    } else {
      return false;
    }
  };
  const addressValidate = value => {
    const pattern = /^(#.0-9a-zA-Z\s,-)+$/;
    return true;

    // if (value.match(pattern) && value.length > 0) {
    //   return true;
    // } else {
    //   return false;
    // }
  };

  const contactValidate = value => {
    const pattern = /^[0-9]+$/;

    if (value.match(pattern) && value.length == 10) {
      return true;
    } else {
      return false;
    }
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => {
          alert();
        }}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Paper className={classes.paper}>
        <Box className={classes.titleBar}>
          <IconButton aria-label="close" onClick={toggleRequest}>
            <CancelIcon />
          </IconButton>
        </Box>
        <Box p={5}>
          <Grid container direction="row" alignItems="center" style={{ backgroundColor: 'white' }}>
            <Grid item xs={12} sm={12} md={6}>
              <form onSubmit={updateHandler}>
                <Typography>{'Pickup Address'}</Typography>
                <AppTextInput
                  className={classes.formInput}
                  label={'Address Line 1'}
                  required={true}
                  type={'text'}
                  value={addressLine1}
                  onChange={addressLine1Handler}
                />
                <Typography color={'primary'}>{errors.addressLine1}</Typography>

                <AppTextInput
                  className={classes.formInput}
                  label={'Address Line 2'}
                  // required={true}
                  type={'text'}
                  value={addressLine2}
                  onChange={addressLine2Handler}
                />
                <Typography color={'primary'}>{errors.addressLine2}</Typography>

                <AppTextInput
                  className={classes.formInput}
                  label={'Address Line 3'}
                  type={'text'}
                  value={addressLine3}
                  onChange={addressLine3Handler}
                />

                <Typography color={'primary'}>{errors.addressLine3}</Typography>

                <AppAutocomplete
                  id="autocomplete_shipper_city"
                  label={'Address City'}
                  options={cities}
                  variant="standard"
                  required={true}
                  onChange={(event, value) => {
                    if (value != null) {
                      setPickupAddressCity(value.id);
                    } else {
                      setPickupAddressCity('');
                    }
                  }}
                  getOptionLabel={option => option.name_en}
                  renderInput={params => <TextField {...params} label="Address City" required={true} />}
                />
                <Typography color={'primary'}>{errors.city}</Typography>
                <Typography className={classes.labelMargin}>{'Contact Details'}</Typography>
                <AppTextInput
                  className={classes.formInput}
                  label={'Name'}
                  required={true}
                  type={'text'}
                  value={contactName}
                  onChange={contactNameHandler}
                />
                <Typography color={'primary'}>{errors.name}</Typography>

                <AppTextInput
                  className={classes.formInput}
                  label={'Contact Number'}
                  required={true}
                  type={'text'}
                  value={contactNumber}
                  onChange={contactNumberHandler}
                />
                <Typography color={'primary'}>{errors.contactNumber}</Typography>

                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={5}>
                  <Grid item xs={12} sm={12} md={6}>
                    <Box pt={5}>
                      <DateTimePicker
                        label={'PickUpTime From'}
                        value={pickUpTimeFrom}
                        format="DD/MM/yyyy hh:mm a"
                        //onChange={pickUpTimeFromHandler}
                        onChange={pickUpTimeFromHandler}
                        //format="MM/dd/yyyy"
                        required={true}
                        minDate={new Date()}
                        style={{ width: '100%' }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Box pt={5}>
                      <DateTimePicker
                        label={'PickUpTime To'}
                        value={pickUpTimeTo}
                        format="DD/MM/yyyy hh:mm a"
                        //onChange={pickUpTimeFromHandler}
                        onChange={pickUpTimeToHandler}
                        //format="MM/dd/yyyy"
                        required={true}
                        minDate={pickUpTimeFrom}
                        style={{ width: '100%' }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <AppTextInput
                      className={classes.formInput}
                      label={'Waybill Count'}
                      required={true}
                      value={waybillCount}
                      onChange={waybillCountHandler}
                      type={'text'}
                    />
                    <Typography color={'primary'}>{errors.waybillCount}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <AppTextInput
                      className={classes.formInput}
                      label={'Estimated Weight(kg)'}
                      required={true}
                      type={'text'}
                      value={maxWeight}
                      onChange={maxWeightHandler}
                    />
                    <Typography color={'primary'}>{errors.maxWeight}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Button type="submit" variant="outlined" size={'small'} startIcon={<FileCopyIcon />} name="submit">
                      Create
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <div>
        <Snackbar
          open={showAlert}
          autoHideDuration={2000}
          onClose={() => {
            dispatch(resetPickUpCreateState());
            if (success) toggleRequest();
          }}
          message={message}
          action={action}
        />
      </div>
    </div>
  );
}

export { NewRequest };
