import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { setAuthUser } from 'redux/actions/Auth';
import { setCreatedOrders, setDeliveredOrders, setIntransitOrders, setOutForDeliveryOrders } from 'redux/actions/Orders';
import BulkUpload from 'routes/Pages/BulkUpload';
import { database } from 'services/auth/firebase/config';
import { fetchError, fetchStart, fetchSuccess } from '../../redux/actions';
import axios from '../auth/jwt/config';
import { setCodPayments, setCodPaymentSummary } from '../../redux/actions/CodPayments';

const CodPayments = {
  getCodPayments: (page = 1, isInitial = false, fromDate, toDate) => {
    return dispatch => {
      dispatch(fetchStart());
      console.log('Fetch created orders');
      const token = localStorage.getItem('token'); //check
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      axios
        .get(`cod-payments?page=${page}&from_date=${fromDate}&to_date=${toDate}`)
        .then(({ data }) => {
          console.log('cod-payments', data);
          if (data.status == 'success') {
            const result = data.data.data;
            const numberOfRecords = data.data.total;
            console.log(result);
            const isNextPage = data.data.next_page_url ? true : false;
            dispatch(fetchSuccess());
            dispatch(setCodPayments(result, page, isNextPage, isInitial, numberOfRecords));
          } else {
            console.log('Created Oders Error');
            dispatch(fetchError('ERRRRRRRRRR'));
          } //
        })
        .catch(function(error) {
          console.log(error);
          dispatch(fetchError(error.message));
        });
    };
  },
  getCodPaymentSummary: () => {
    return dispatch => {
      dispatch(fetchStart());
      console.log('Fetch getCodPaymentSummary orders');
      const token = localStorage.getItem('token'); //check
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      axios
        .get(`cod-payment-summary`)
        .then(({ data }) => {
          console.log('cod-payment-summary', data);
          if (data.status == 'success') {
            const result = data.data;
            dispatch(fetchSuccess());
            dispatch(setCodPaymentSummary(result.total_cod_deposited, result.next_cod_payment, result.period));
          } else {
            console.log('cod-payment-summary Error');
            dispatch(fetchError('ERRRRRRRRRR'));
          } //
        })
        .catch(function(error) {
          console.log(error);
          dispatch(fetchError(error.message));
        });
    };
  },
  downloadFile: codPaymentsId => {
    return dispatch => {
      dispatch(fetchStart());
      console.log('Downloading COD Payments');
      const token = localStorage.getItem('token'); //check
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      axios
        .get(`/cod-payment-items-excel/${codPaymentsId}`, {
          responseType: 'blob',
        })
        .then(data => {
          //console.log("Ddata",data);

          if (data.status == 200) {
            const result = data.data;
            dispatch(fetchSuccess());
            //dispatch(setBulkUploads(result,1));
            const downloadUrl = window.URL.createObjectURL(new Blob([result]));

            const link = document.createElement('a');

            link.href = downloadUrl;

            link.setAttribute('download', codPaymentsId + '.xlsx'); //any other extension

            document.body.appendChild(link);

            link.click();

            link.remove();
          } else {
            const data = data.data;
            dispatch(fetchError('Error'));
          } //
        })
        .catch(function(error) {
          console.log(error);
          dispatch(fetchError(error.message));
        });
    };
  },
};

export default CodPayments;
