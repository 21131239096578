import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import { makeStyles } from '@material-ui/core/styles';
import TableFooter from '@material-ui/core/TableFooter';
import TablePaginationActions from './TablePaginationActions';
import { Box } from '@material-ui/core';
import AppConfigs from '../../../Utils/AppConfigs';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
    border: '1px solid gray',
    //backgroundColor:'gray',
    borderRadius: '2px',
  },
});

function createData(date, time, status, location, statusType, remarks) {
  return { date, time, status, location, statusType, remarks };
}

// const rows = [
//   createData('2021-08-30', '07:07:36PM', 'FIRST MILE RECIVE SCANE', 'KANDY'),
//   createData('2021-08-31', '07:07:36PM', 'FIRST MILE RECIVE SCANE', 'KANDY'),
//   createData('2021-08-32', '07:07:36PM', 'FIRST MILE RECIVE SCANE', 'KANDY'),
//   createData('2021-08-33', '07:07:36PM', 'FIRST MILE RECIVE SCANE', 'KANDY'),
//   createData('2021-08-34', '07:07:36PM', 'FIRST MILE RECIVE SCANE', 'KANDY'),
//   createData('2021-08-35', '07:07:36PM', 'FIRST MILE RECIVE SCANE', 'KANDY'),
//   createData('2021-08-36', '07:07:36PM', 'FIRST MILE RECIVE SCANE', 'KANDY'),
//   createData('2021-08-37', '07:07:36PM', 'FIRST MILE RECIVE SCANE', 'KANDY'),
//   createData('2021-08-38', '07:07:36PM', 'FIRST MILE RECIVE SCANE', 'KANDY'),
//   createData('2021-08-39', '07:07:36PM', 'FIRST MILE RECIVE SCANE', 'KANDY'),
//   createData('2021-08-40', '07:07:36PM', 'FIRST MILE RECIVE SCANE', 'KANDY'),
//   createData('2021-08-41', '07:07:36PM', 'FIRST MILE RECIVE SCANE', 'KANDY'),
//   createData('2021-08-42', '07:07:36PM', 'FIRST MILE RECIVE SCANE', 'KANDY'),
//   createData('2021-08-43', '07:07:36PM', 'FIRST MILE RECIVE SCANE', 'KANDY'),
//   createData('2021-08-44', '07:07:36PM', 'FIRST MILE RECIVE SCANE', 'KANDY'),
// ];

function createRows(history) {
  console.log('Histooooory', history);
  return history.map(entry => {
    let remarks = '';

    if (
      entry.tracking_status_type !== null &&
      entry.tracking_status_type.type === 'NOT DELIVERED' &&
      entry.delivery_dispatch_item !== null &&
      entry.delivery_dispatch_item.not_delivered_reason !== null
    ) {
      remarks = entry.delivery_dispatch_item.not_delivered_reason.display_name;
    }

    return createData(
      entry.action_datetime,
      entry.action_datetime,
      entry.tracking_status_type.type,
      entry.facility.name,
      entry.category_code,
      remarks,
    );
  });
}

function TrackingHistoryTable({ data }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(200);
  const classes = useStyles();
  const rows = createRows(data);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderTable = () => {
    if (data.length > 0) {
      return (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="tracking_hitory_table">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell align="right">Time</TableCell>
                <TableCell align="right">Status</TableCell>
                <TableCell align="right">Status Type</TableCell>
                <TableCell align="right">Location</TableCell>
                <TableCell align="right">Remarks</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map(row => (
                <TableRow key={row.date}>
                  <TableCell component="th" scope="row">
                    {AppConfigs.formatDisplayDateAndTime(row.date)}
                  </TableCell>
                  <TableCell align="right">{AppConfigs.formatDisplayDateAndTime(row.time)}</TableCell>
                  <TableCell align="right">{row.status}</TableCell>
                  <TableCell align="center">{row.statusType}</TableCell>
                  <TableCell align="right">{row.location}</TableCell>
                  <TableCell align="right">{row.remarks}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    } else {
      return (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="tracking_hitory_table">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell align="right">Time</TableCell>
                <TableCell align="right">Status</TableCell>
                <TableCell align="right">Status Type</TableCell>
                <TableCell align="right">Location</TableCell>
                <TableCell align="right">Remarks</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell colSpan={6}>No Data Available</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      );
    }
  };

  return <React.Fragment>{renderTable()}</React.Fragment>;
}

export default TrackingHistoryTable;
