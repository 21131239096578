import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

const BarChart = data => {
  const [performace, setPerformace] = useState([]);

  useEffect(() => {
    const obj = Object.values(data)[0];
    const arr = [];
    for (const key in obj) {
      arr.push(obj[key]);
    }
    setPerformace(arr);
  }, [data]);

  const options = {
    chart: {
      height: 350,
      type: 'bar',
      toolbar: {
        show: true,
        tools: {
          download: false,
        },
      },
    },
    colors: ['#22C55E'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '20%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      showForSingleSeries: true,

      horizontalAlign: 'left',
      itemMargin: {
        horizontal: 15,
        vertical: 20,
      },
    },
    xaxis: {
      categories: ['24H', '48H', '72H', '< 72H'],
      labels: {
        style: {
          fontSize: '12px',
        },
      },
    },
    title: {
      text: 'Delivery Times',
      floating: true,
      offsetY: 300,
      align: 'center',
      style: {
        color: '#4F4F4F',
        fontWeight: 400,
      },
    },
  };

  const series = [
    {
      name: 'Total Deliveries',
      data: performace,
    },
  ];
  return (
    <div style={{ width: '100%' }}>
      <Chart options={options} series={series} type={'bar'} height={350} />
    </div>
  );
};

export default BarChart;
