import { DISPLAY_COD_PAYMENT_SUMMARY, DISPLAY_COD_PAYMENTS } from '../../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  cod_payments: {
    payments: [],
    page: 1,
    totalRecords: 0,
    isNextPage: false,
  },
  cod_payment_summary: {
    totalCodDeposited: '-',
    nextCodPayment: '-',
    period: '-',
  },
};

export default (state = INIT_STATE, action) => {
  console.log(state);
  switch (action.type) {
    case DISPLAY_COD_PAYMENTS: {
      if (action.payload.isInitial) {
        return {
          ...state,

          cod_payments: {
            payments: action.payload.records,
            page: action.payload.page,
            isNextPage: action.payload.isNextPage,
            totalRecords: action.payload.totalRecords,
          },
        };
      } else {
        return {
          ...state,

          // ...state.orders,
          cod_payments: {
            payments: [...state.cod_payments.payments, ...action.payload.records],
            page: action.payload.page,
            isNextPage: action.payload.isNextPage,
            totalRecords: action.payload.totalRecords,
          },
        };
      }
    }

    case DISPLAY_COD_PAYMENT_SUMMARY: {
      return {
        ...state,
        cod_payment_summary: {
          totalCodDeposited: action.payload.totalCodDeposited,
          nextCodPayment: action.payload.nextCodPayment,
          period: action.payload.period,
        },
      };
    }

    default:
      return state;
  }
};
