import {
  DISPLAY_CREATED_ORDERS,
  DISPLAY_INTRANSIT_ORDERS,
  DISPLAY_OUT_FOR_DELIVERY_ORDERS,
  DISPLAY_DELIVERED_ORDERS,
  DISPLAY_CREATED_ORDERS_ES,
  DISPLAY_INTRANSIT_ORDERS_ES,
  DISPLAY_OUT_FOR_DELIVERY_ORDERS_ES,
  DISPLAY_DELIVERED_ORDERS_ES,
  DISPLAY_ALL_ORDERS_ES,
  DISPLAY_CREATED_ORDERS_NEW,
  DISPLAY_INTRANSIT_ORDERS_NEW,
  DISPLAY_OUT_FOR_DELIVERY_ORDERS_NEW,
  DISPLAY_DELIVERED_ORDERS_NEW,
  DISPLAY_SEARCH,
  DISPLAY_UNDELIVERED_ORDERS_NEW,
} from '../../@jumbo/constants/ActionTypes';

export const setCreatedOrders = (records, page, isNextPage, isInitial, totalRecords) => {
  return dispatch => {
    dispatch({
      type: DISPLAY_CREATED_ORDERS,
      payload: { records, page, isNextPage, isInitial, totalRecords },
    });
  };
};

export const setIntransitOrders = (records, page, isNextPage, isInitial, perPage, total) => {
  return dispatch => {
    dispatch({
      type: DISPLAY_INTRANSIT_ORDERS,
      payload: { records, page, isNextPage, isInitial, perPage, total },
    });
  };
};

export const setOutForDeliveryOrders = (records, page, isNextPage, isInitial, perPage, total) => {
  return dispatch => {
    dispatch({
      type: DISPLAY_OUT_FOR_DELIVERY_ORDERS,
      payload: { records, page, isNextPage, isInitial, perPage, total },
    });
  };
};

export const setDeliveredOrders = (records, page, isNextPage, isInitial, perPage, total) => {
  return dispatch => {
    dispatch({
      type: DISPLAY_DELIVERED_ORDERS,
      payload: {
        records,
        page,
        isNextPage,
        isInitial,
        perPage,
        total,
      },
    });
  };
};

export const setCreatedOrdersEs = (records, page, isInitial, totalRecords) => {
  return dispatch => {
    dispatch({
      type: DISPLAY_CREATED_ORDERS_ES,
      payload: { records, page, isInitial, totalRecords },
    });
  };
};

export const setIntransitOrdersEs = (records, page, isInitial, perPage, total) => {
  return dispatch => {
    dispatch({
      type: DISPLAY_INTRANSIT_ORDERS_ES,
      payload: { records, page, isInitial, perPage, total },
    });
  };
};

export const setOutForDeliveryOrdersEs = (records, page, isInitial, perPage, total) => {
  return dispatch => {
    dispatch({
      type: DISPLAY_OUT_FOR_DELIVERY_ORDERS_ES,
      payload: { records, page, isInitial, perPage, total },
    });
  };
};

export const setDeliveredOrdersEs = (records, page, isInitial, perPage, total) => {
  return dispatch => {
    dispatch({
      type: DISPLAY_DELIVERED_ORDERS_ES,
      payload: {
        records,
        page,
        isInitial,
        perPage,
        total,
      },
    });
  };
};

export const setAllOrdersEs = (records, page, isInitial, perPage, total) => {
  return dispatch => {
    dispatch({
      type: DISPLAY_ALL_ORDERS_ES,
      payload: {
        records,
        page,
        isInitial,
        perPage,
        total,
      },
    });
  };
};

export const setCreatedOrdersNew = (records, page, isInitial, perPage, total) => {
  return dispatch => {
    dispatch({
      type: DISPLAY_CREATED_ORDERS_NEW,
      payload: { records, page, isInitial, perPage, total },
    });
  };
};

export const setIntransitOrdersNew = (records, page, isInitial, perPage, total) => {
  return dispatch => {
    dispatch({
      type: DISPLAY_INTRANSIT_ORDERS_NEW,
      payload: { records, page, isInitial, perPage, total },
    });
  };
};

export const setOutForDeliveryOrdersNew = (records, page, isInitial, perPage, total) => {
  return dispatch => {
    dispatch({
      type: DISPLAY_OUT_FOR_DELIVERY_ORDERS_NEW,
      payload: { records, page, isInitial, perPage, total },
    });
  };
};

export const setDeliveredOrdersNew = (records, page, isInitial, perPage, total) => {
  return dispatch => {
    dispatch({
      type: DISPLAY_DELIVERED_ORDERS_NEW,
      payload: {
        records,
        page,
        isInitial,
        perPage,
        total,
      },
    });
  };
};

export const setUndeliveredOrdersNew = (records, page, isInitial, perPage, total) => {
  return dispatch => {
    dispatch({
      type: DISPLAY_UNDELIVERED_ORDERS_NEW,
      payload: {
        records,
        page,
        isInitial,
        perPage,
        total,
      },
    });
  };
};

export const setOrders = (records, page, perPage, total) => {
  return dispatch => {
    dispatch({
      type: DISPLAY_SEARCH,
      payload: { records, page, perPage, total },
    });
  };
};

