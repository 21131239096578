import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: props => ({
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    backgroundColor: props.backgroundColor,
    color: props.textColor,
  }),
}));

const getStyleProps = displayName => {
  const styles = {
    CityPak: { backgroundColor: '#be0707', textColor: '#fff' },
    Merchant: { backgroundColor: '#d9d9d9', textColor: '#2d2727' },
  };
  return styles[displayName] || { backgroundColor: 'transparent', textColor: 'inherit' };
};

const CreateAvatar = ({ displayName }) => {
  const getDisplayName = typeof displayName === 'string' ? displayName : '';
  const styleProps = getStyleProps(getDisplayName);
  const classes = useStyles(styleProps);
  const initial = getDisplayName.charAt(0).toUpperCase();

  return <div className={classes.root}>{initial}</div>;
};

export default CreateAvatar;
