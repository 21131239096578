export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';

export const UPDATE_AUTH_USER = 'update_auth_user';
export const UPDATE_LOAD_USER = 'update_load_user';
export const SEND_FORGET_PASSWORD_EMAIL = 'send_forget_password_email';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'signin_google_user_success';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'signin_facebook_user_success';
export const SIGNIN_TWITTER_USER_SUCCESS = 'signin_twitter_user_success';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_SUCCESS';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';

export const SET_DASHBOARD_DATA = 'set_dashboard_data';

export const SET_TASK_CURRENT_USER = 'set_task_current_user';
export const SET_TASKS_DATA = 'set_tasks_data';
export const SET_TASK_LIST_DATA = 'set_task_list_data';
export const ADD_TASK = 'add_task';
export const DELETE_TASK = 'delete_task';
export const UPDATE_TASK = 'update_task';
export const SET_FILTER_DATA = 'set_filter_data';
export const ADD_TASK_LIST = 'add_task_list';
export const UPDATE_TASK_LIST = 'update_task_list';
export const DELETE_TASK_LIST = 'delete_task_list';
export const SET_TASK_DETAIL = 'set_task_detail';
export const SEND_MESSAGE = 'send_message';
export const TOGGLE_SIDEBAR_COLLAPSED = 'toggle_sidebar_collapsed';
export const GET_TASKS_COUNTS = 'get_tasks_counts';

//mail app
export const GET_LABELS_LIST = 'get_labels_list';
export const GET_CONNECTIONS_LIST = 'get_connections_list';
export const GET_MAILS_LIST = 'get_mails_list';
export const UPDATE_MAIL_FOLDER = 'update_mail_folder';
export const UPDATE_MAIL_LABEL = 'upade_mail_label';
export const UPDATE_FAVORITE_STATUS = 'update_favorite_status';
export const UPDATE_READ_STATUS = 'update_read_status';
export const UPDATE_IMPORTANT_STATUS = 'update_important_status';
export const COMPOSE_MAIL = 'compose_mail';
export const SET_FILTER_TYPE = 'set_filter_type';
export const GET_SELECTED_MAIL = 'GET_SELECTED_MAIL';
export const UPDATE_SELECTED_MAIL = 'update_selected_mail';
export const NULLIFY_SELECTED_MAIL = 'nullify_selected_mail';
export const REPLY_TO_MAIL = 'reply_to_mail';
export const GET_MAIL_COUNTS = 'get_mail_count';
export const ADD_LABEL = 'add_label';
export const ADD_CONNECTION = 'add_connection';
export const REMOVE_CONNECTION = 'remove_connection';

export const SET_CHAT_USERS = 'set_chat_users';
export const SET_CONTACT_USERS = 'set_contact_users';
export const SET_CURRENT_USER = 'set_current_user';
export const SET_CONVERSATION_DATA = 'set_conversation_data';
export const SEND_NEW_CHAT_MESSAGE = 'send_new_chat_message';
export const SEND_NEW_MEDIA_MESSAGE = 'send_new_media_message';

//Contact App
export const GET_CONTACTS_LIST = 'get_contacts_list';
export const SET_CURRENT_CONTACT = 'set_current_contact';
export const CREATE_CONTACT = 'create_contact';
export const UPDATE_STARRED_STATUS = 'update_starred_status';
export const DELETE_CONTACT = 'delete_contact';
export const UPDATE_CONTACT_LABEL = 'update_contact_label';
export const UPDATE_CONTACT = 'update_contact';
export const GET_CONTACT_COUNTS = 'get_contact_counts';
export const UPDATE_LABEL_ITEM = 'update_label_item';
export const DELETE_LABEL_ITEM = 'delete_label_item';

export const GET_USER_DETAIL = 'get_user_detail';
export const GET_FEED_POSTS = 'get_feed_posts';
export const CREATE_POST = 'create_post';
export const UPDATE_POST = 'update_post';

//UserBehaviurs

export const UPDATE_USER_PROFILE = 'update_user_profile';
export const UPDATE_AUTH_USER_DATA = 'update_user_profile'; //

//BULKUPLOADS

export const DISPLAY_BULK_UPLOADS = 'display_bulk_uploads';
export const OPEN_BULK_UPLOAD_DIALOG = 'open_bulk_upload_dialog';
export const CLOSE_BULK_UPLOAD_DIALOG = 'close_bulk_upload_dialog';

//Orders
export const DISPLAY_CREATED_ORDERS = 'display_created_orders';
export const DISPLAY_INTRANSIT_ORDERS = 'display_intransit_orders';
export const DISPLAY_OUT_FOR_DELIVERY_ORDERS = 'display_out_for_delivery_orders';
export const DISPLAY_DELIVERED_ORDERS = 'display_delivered_orders';
export const DISPLAY_CREATED_ORDERS_ES = 'display_created_orders_es';
export const DISPLAY_INTRANSIT_ORDERS_ES = 'display_intransit_orders_es';
export const DISPLAY_OUT_FOR_DELIVERY_ORDERS_ES = 'display_out_for_delivery_orders_es';
export const DISPLAY_DELIVERED_ORDERS_ES = 'display_delivered_orders_es';
export const DISPLAY_ALL_ORDERS_ES = 'display_all_orders_es';
export const DISPLAY_CREATED_ORDERS_NEW = 'display_created_orders_new';
export const DISPLAY_INTRANSIT_ORDERS_NEW = 'display_intransit_orders_new';
export const DISPLAY_OUT_FOR_DELIVERY_ORDERS_NEW = 'display_out_for_delivery_orders_new';
export const DISPLAY_DELIVERED_ORDERS_NEW = 'display_delivered_orders_new';
export const DISPLAY_UNDELIVERED_ORDERS_NEW = 'display_undelivered_orders_new';
export const DISPLAY_SEARCH = 'display_search';

//COD payments
export const DISPLAY_COD_PAYMENTS = 'cod_payments';
export const DISPLAY_COD_PAYMENT_SUMMARY = 'cod_payment_summary';

//Print orders
export const OPEN_ORDER_PRINT_DIALOG = 'open_order_print_dialog';
export const CLOSE_ORDER_PRINT_DIALOG = 'close_order_print_dialog';
export const PRINT_ORDERS = 'print_orders';

//trackingHistory
export const DISPLAY_TRACKING_HISTORY = 'display_tracking_history';
export const SET_REFMODE_MODE = 'set_refmode_mode';
export const SET_TR_MODE = 'set_tr_mode';
export const SET_TRACKING_HISTORY_IN_REF_MODE = 'set_tracking_history_in_ref_mode';
export const SET_TRACKING_HISTORY_IN_TN_MODE = 'set_tracking_history_in_tn_mode';

//Cities
export const LOAD_CITIES = 'LOAD_CITIES';
export const LOAD_CITIES_SUCCESS = 'LOAD_CITIES_SUCCESS';
export const LOAD_CITIES_FAILED = 'LOAD_CITIES_FAILED';

//snack bar
export const SHOW_SNACK_BAR = 'show_snack_bar';
export const HIDE_SNACK_BAR = 'hide_snack_bar';

//reports
export const SET_REPORT_DATA = 'set_report_data';
