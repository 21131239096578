import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { setAuthUser } from 'redux/actions/Auth';
import {
  setAllOrdersEs,
  setCreatedOrders,
  setCreatedOrdersEs,
  setCreatedOrdersNew,
  setDeliveredOrders,
  setDeliveredOrdersEs,
  setDeliveredOrdersNew,
  setIntransitOrders,
  setIntransitOrdersEs,
  setIntransitOrdersNew,
  setOrders,
  setOutForDeliveryOrders,
  setOutForDeliveryOrdersEs,
  setOutForDeliveryOrdersNew,
  setTrackingHistory,
} from 'redux/actions/Orders';
import BulkUpload from 'routes/Pages/BulkUpload';
import { database } from 'services/auth/firebase/config';
import { fetchError, fetchStart, fetchSuccess } from '../../redux/actions';
import axios from '../auth/jwt/config';

const Orders = {
  getCreatedOrders: (page = 1, isInitial = false, fromDate, toDate, type) => {
    return dispatch => {
      dispatch(fetchStart());
      console.log('Fetch created orders');
      const token = localStorage.getItem('token'); //check
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      axios
        .get(`orders/created?page=${page}&from_date=${fromDate}&to_date=${toDate}&type=${type}`)
        .then(({ data }) => {
          console.log('Created Orders', data);
          if (data.status == 'success') {
            const result = data.data.data;
            const numberOfRecords = data.data.total;
            const isNextPage = data.data.next_page_url ? true : false;
            dispatch(fetchSuccess());
            dispatch(setCreatedOrders(result, page, isNextPage, isInitial, numberOfRecords));
          } else {
            dispatch(fetchError('ERRRRRRRRRR'));
          } //
        })
        .catch(function(error) {
          console.log(error);
          dispatch(fetchError(error.message));
        });
    };
  },

  getIntransitOrders: (page = 1, isInitial = false, per_page = 10) => {
    return dispatch => {
      dispatch(fetchStart());
      console.log('Fetching Intransit...............');
      const token = localStorage.getItem('token'); //check
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      axios
        .get(`orders/in-transit?page=${page}&per_page=${per_page}`)
        .then(({ data }) => {
          if (data.status == 'success') {
            const result = data.data.data;
            const isNextPage = data.data.next_page_url ? true : false;
            const perPage = data.data.per_page !== null ? data.data.per_page : 10;
            const total = data.data.total !== null ? data.data.total : 0;

            dispatch(fetchSuccess());
            dispatch(setIntransitOrders(result, page, isNextPage, isInitial, perPage, total));
          } else {
            console.log('ERRRRRRRRRRRRR');
            dispatch(fetchError('ERRRRRRRRRR'));
          } //
        })
        .catch(function(error) {
          console.log(error);
          dispatch(fetchError(error.message));
        });
    };
  },

  getOutForDeliveryOrders: (page = 1, isInitial = false, per_page = 10) => {
    return dispatch => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token'); //check
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      axios
        .get(`orders/out-for-delivery?page=${page}&per_page=${per_page}`)
        .then(({ data }) => {
          if (data.status == 'success') {
            const result = data.data.data;
            const isNextPage = data.data.next_page_url ? true : false;
            const perPage = data.data.per_page !== null ? data.data.per_page : 10;
            const total = data.data.total !== null ? data.data.total : 0;

            dispatch(fetchSuccess());
            dispatch(setOutForDeliveryOrders(result, page, isNextPage, isInitial, perPage, total));
          } else {
            console.log('ERRRRRRRRRRRRR');
            dispatch(fetchError('ERRRRRRRRRR'));
          } //
        })
        .catch(function(error) {
          console.log(error);
          dispatch(fetchError(error.message));
        });
    };
  },

  getDeliveredOrders: (page = 1, isInitial = false, date) => {
    return dispatch => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token'); //check
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      axios
        .get(`orders/delivered/${date}?page=${page}`)
        .then(({ data }) => {
          console.log('DataaaaIntransitDelivered ', data);
          if (data.status == 'success') {
            const result = data.data.data;
            const isNextPage = data.data.next_page_url ? true : false;
            dispatch(fetchSuccess());
            dispatch(setDeliveredOrders(result, page, isNextPage, isInitial));
          } else {
            console.log('ERRRRRRRRRRRRR');
            dispatch(fetchError('ERRRRRRRRRR'));
          } //
        })
        .catch(function(error) {
          console.log(error);
          dispatch(fetchError(error.message));
        });
    };
  },

  getDeliveredOrdersByDateRange: (page = 1, isInitial = false, per_page = 10, fromDate, toDate) => {
    return dispatch => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token'); //check
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      axios
        .get(`orders/delivered-by-date-range?page=${page}&per_page=${per_page}&from_date=${fromDate}&to_date=${toDate}`)
        .then(({ data }) => {
          console.log('DataaaaIntransitDelivered ', data);
          if (data.status == 'success') {
            const result = data.data.data;
            const isNextPage = data.data.next_page_url ? true : false;
            const perPage = data.data.per_page !== null ? data.data.per_page : 10;
            const total = data.data.total !== null ? data.data.total : 0;
            dispatch(fetchSuccess());
            dispatch(setDeliveredOrders(result, page, isNextPage, isInitial, perPage, total));
          } else {
            console.log('ERRRRRRRRRRRRR');
            dispatch(fetchError('ERRRRRRRRRR'));
          } //
        })
        .catch(function(error) {
          console.log(error);
          dispatch(fetchError(error.message));
        });
    };
  },

  createSingleOrder: (order, history) => {
    return dispatch => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      axios
        .post(`orders`, order)
        .then(({ data }) => {
          console.log('RESPONSE', data);
          if (data.success == true) {
            dispatch(fetchSuccess());
            history.push('/orders');
          } else {
            dispatch(fetchError(data.message));
          }
        })
        .catch(function(error) {
          console.log(error);
          dispatch(fetchError(error.message));
        });
    };
  },

  getCreatedOrdersEs: (page = 1, isInitial = false, fromDate, toDate, type) => {
    return dispatch => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      let url = `orders/created-es?page=${page}`;

      if (fromDate !== undefined) {
        url += `&from_date=${fromDate}`;
      }

      if (toDate !== undefined) {
        url += `&to_date=${toDate}`;
      }

      if (type !== undefined) {
        url += `&type=${type}`;
      }

      axios
        .get(url)
        .then(({ data }) => {
          if (data.status == 'success') {
            const result = data.data.data;
            const numberOfRecords = data.data.total;
            dispatch(fetchSuccess());
            dispatch(setCreatedOrdersEs(result, page, isInitial, numberOfRecords));
          } else {
            console.log('Error');
            dispatch(fetchError('Error'));
          }
        })
        .catch(function(error) {
          console.log(error);
          dispatch(fetchError(error.message));
        });
    };
  },

  getIntransitOrdersEs: (page = 1, isInitial = false, per_page = 10, fromDate, toDate, type) => {
    return dispatch => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token'); //check
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      let url = `orders/in-transit-es?page=${page}&per_page=${per_page}`;

      if (fromDate) {
        url += `&from_date=${fromDate}`;
      }

      if (toDate) {
        url += `&to_date=${toDate}`;
      }

      if (type) {
        url += `&type=${type}`;
      }

      axios
        .get(url)
        .then(({ data }) => {
          if (data.status == 'success') {
            const result = data.data.data;
            const perPage = data.data.per_page !== null ? data.data.per_page : 10;
            const total = data.data.total !== null ? data.data.total : 0;

            dispatch(fetchSuccess());
            dispatch(setIntransitOrdersEs(result, page, isInitial, perPage, total));
          } else {
            console.log('Error');
            dispatch(fetchError('Error'));
          } //
        })
        .catch(function(error) {
          console.log(error);
          dispatch(fetchError(error.message));
        });
    };
  },
  getOutForDeliveryOrdersEs: (page = 1, isInitial = false, per_page = 10, fromDate, toDate) => {
    return dispatch => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token'); //check
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      let url = `orders/out-for-delivery-es?page=${page}&per_page=${per_page}`;

      if (fromDate !== undefined) {
        url += `&from_date=${fromDate}`;
      }

      if (toDate !== undefined) {
        url += `&to_date=${toDate}`;
      }

      axios
        .get(url)
        .then(({ data }) => {
          if (data.status == 'success') {
            const result = data.data.data;
            // const isNextPage = data.data.next_page_url ? true : false;
            const perPage = data.data.per_page !== null ? data.data.per_page : 10;
            const total = data.data.total !== null ? data.data.total : 0;

            dispatch(fetchSuccess());
            dispatch(setOutForDeliveryOrdersEs(result, page, isInitial, perPage, total));
          } else {
            console.log('Error');
            dispatch(fetchError('Error'));
          } //
        })
        .catch(function(error) {
          console.log(error);
          dispatch(fetchError(error.message));
        });
    };
  },
  getDeliveredOrdersByDateRangeEs: (page = 1, isInitial = false, per_page = 10, fromDate, toDate, status) => {
    return dispatch => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token'); //check
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      let url = `orders/delivered-by-date-range-es?page=${page}&per_page=${per_page}&from_date=${fromDate}&to_date=${toDate}`;

      if (status) {
        url += `&type=${status}`;
      }

      axios
        .get(url)
        .then(({ data }) => {
          if (data.status == 'success') {
            const result = data.data.data;
            // const isNextPage = data.data.next_page_url ? true : false;
            const perPage = data.data.per_page !== null ? data.data.per_page : 10;
            const total = data.data.total !== null ? data.data.total : 0;
            dispatch(fetchSuccess());
            dispatch(setDeliveredOrdersEs(result, page, isInitial, perPage, total));
          } else {
            console.log('Error');
            dispatch(fetchError('Error'));
          }
        })
        .catch(function(error) {
          console.log(error);
          dispatch(fetchError(error.message));
        });
    };
  },
  getCreatedOrdersNew: (page = 1, isInitial = false, per_page = 10, fromDate, toDate) => {
    return dispatch => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      let url = `orders/created-new?page=${page}&per_page=${per_page}`;

      if (fromDate) {
        url += `&from_date=${fromDate}`;
      }

      if (toDate) {
        url += `&to_date=${toDate}`;
      }

      axios
        .get(url)
        .then(({ data }) => {
          if (data.status == 'success') {
            const result = data.data.data;
            const perPage = data.data.per_page !== null ? data.data.per_page : 10;
            const total = data.data.total !== null ? data.data.total : 0;

            dispatch(fetchSuccess());
            dispatch(setCreatedOrdersNew(result, page, isInitial, perPage, total));
          } else {
            dispatch(fetchError('ERRRRRRRRRR'));
          }
        })
        .catch(function(error) {
          console.log(error);
          dispatch(fetchError(error.message));
        });
    };
  },
  getIntransitOrdersNew: (page = 1, isInitial = false, per_page = 10, fromDate, toDate, type) => {
    return dispatch => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      let url = `orders/in-transit-new?page=${page}&per_page=${per_page}`;

      if (fromDate) {
        url += `&from_date=${fromDate}`;
      }

      if (toDate) {
        url += `&to_date=${toDate}`;
      }

      if (type) {
        url += `&type=${type}`;
      }

      axios
        .get(url)
        .then(({ data }) => {
          if (data.status == 'success') {
            const result = data.data.data;
            const perPage = data.data.per_page !== null ? data.data.per_page : 10;
            const total = data.data.total !== null ? data.data.total : 0;

            dispatch(fetchSuccess());
            dispatch(setIntransitOrdersNew(result, page, isInitial, perPage, total));
          } else {
            console.error('Error');
            dispatch(fetchError('Error'));
          }
        })
        .catch(function(error) {
          console.error(error);
          dispatch(fetchError(error.message));
        });
    };
  },

  getOutForDeliveryOrdersNew: (page = 1, isInitial = false, per_page = 10, fromDate, toDate) => {
    return dispatch => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      let url = `orders/out-for-delivery-new?page=${page}&per_page=${per_page}`;

      if (fromDate !== undefined) {
        url += `&from_date=${fromDate}`;
      }

      if (toDate !== undefined) {
        url += `&to_date=${toDate}`;
      }

      axios
        .get(url)
        .then(({ data }) => {
          if (data.status == 'success') {
            const result = data.data.data;
            const perPage = data.data.per_page !== null ? data.data.per_page : 10;
            const total = data.data.total !== null ? data.data.total : 0;

            dispatch(fetchSuccess());
            dispatch(setOutForDeliveryOrdersNew(result, page, isInitial, perPage, total));
          } else {
            console.error('Error');
            dispatch(fetchError('Error'));
          }
        })
        .catch(function(error) {
          console.error(error);
          dispatch(fetchError(error.message));
        });
    };
  },

  getDeliveredOrdersByDateRangeNew: (page = 1, isInitial = false, per_page = 10, fromDate, toDate, status) => {
    return dispatch => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      let url = `orders/delivered-by-date-range-new?page=${page}&per_page=${per_page}&from_date=${fromDate}&to_date=${toDate}`;

      if (status) {
        url += `&type=${status}`;
      }

      axios
        .get(url)
        .then(({ data }) => {
          if (data.status == 'success') {
            const result = data.data.data;
            const perPage = data.data.per_page !== null ? data.data.per_page : 10;
            const total = data.data.total !== null ? data.data.total : 0;
            dispatch(fetchSuccess());
            dispatch(setDeliveredOrdersNew(result, page, isInitial, perPage, total));
          } else {
            console.log('Error');
            dispatch(fetchError('Error'));
          }
        })
        .catch(function(error) {
          console.log(error);
          dispatch(fetchError(error.message));
        });
    };
  },
  getOrders: (page = 1, per_page = 10, id) => {
    return dispatch => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token'); //check
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      const encodedData = encodeURIComponent(id);

      axios
        .get(`tracking-history/index?tracking_numbers=${encodedData}&page=${page}&per_page=${per_page}`)
        .then(({ data }) => {
          if (data.status == 'success') {
            const result = data.data;
            const perPage = data.data.per_page !== null ? data.data.per_page : 10;
            const total = data.data.total !== null ? data.data.total : 0;

            dispatch(fetchSuccess());
            dispatch(setOrders(result, page, perPage, total));
          } else {
            console.log('Error');
            dispatch(fetchError('Error'));
          }
        })
        .catch(function(error) {
          console.log(error);
          dispatch(fetchError(error.message));
        });
    };
  },
};

export default Orders;
