import React, { useReducer, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import GridContainer from '../../../@jumbo/components/GridContainer';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import {
  Checkbox,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tabs,
  Typography,
} from '@material-ui/core';
import CreatedOrderTabPanel from 'custom_components/Pages/Orders/CreatedOrderTabPanel';
import InTransitOrderTabPanel from 'custom_components/Pages/Orders/InTransitOrderTabPanel';
import OutOfDeliveryTabPanel from 'custom_components/Pages/Orders/OutOfDeliveryTabPanel';
import DelliveredTabPanel from 'custom_components/Pages/Orders/DelliveredTabPanel';
import ReturnedTabPanel from 'custom_components/Pages/Orders/ReturnedTabPanel';

import reducer, { initialState } from './reducer/reducer';
import { OrderContext } from './context/orderContext';
import PrintDialog from 'custom_components/PrintDialog';
import { useSelector } from 'react-redux';
import Badge from '@material-ui/core/Badge';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    //backgroundColor:'green',
  },

  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

  line: {
    width: '100%',
    height: 2,
    backgroundColor: 'black',
  },
}));

const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'Orders', isActive: true },
];

const actionButtons = [
  { label: 'Pickup Request', link: '/' },
  { label: 'New Order', isActive: true },
];

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Orders() {
  const created = useSelector(({ orders }) => orders.created);
  const intransit = useSelector(({ orders }) => orders.intransit);
  const outForDelivery = useSelector(({ orders }) => orders.outForDelivery);
  const delivered = useSelector(({ orders }) => orders.delivered);
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const [state, dispatch] = useReducer(reducer, initialState);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <OrderContext.Provider value={{ state: state, dispatch: dispatch }}>
      <PageContainer heading={'ORDERS'} style={{ backgroundColor: 'blue' }} actionButtons={actionButtons}>
        <GridContainer>
          <Grid item xs={12} sm={12} style={{ padding: 0 }}>
            <Box className={classes.header}>
              <Tabs
                variant="scrollable"
                scrollButtons="auto"
                indicatorColor={'primary'}
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example">
                <Tab label={'CREATED' + ' (' + created.totalRecords + ')'} {...a11yProps(0)} />
                <Tab label={'IN TRANSIT' + ' (' + intransit.total + ')'} {...a11yProps(1)} />
                <Tab label={'OUT FOR DELIVERY' + ' (' + outForDelivery.total + ')'} {...a11yProps(2)} />
                <Tab label={'DELIVERED' + ' (' + delivered.total + ')'} {...a11yProps(3)} />
                {/* <Tab label="RETURNED" {...a11yProps(4)} /> */}
              </Tabs>

              <CreatedOrderTabPanel value={value} index={0} />
              <InTransitOrderTabPanel value={value} index={1} />
              <OutOfDeliveryTabPanel value={value} index={2} />

              <DelliveredTabPanel value={value} index={3} />

              <ReturnedTabPanel value={value} index={4} />
            </Box>
          </Grid>
        </GridContainer>
      </PageContainer>
    </OrderContext.Provider>
  );
}

export default Orders;
