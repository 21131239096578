import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PickUps from 'services/pickups';
import DataTable from './DataTable';
import moment from 'moment';

function PickUpTable({ toggleNewRequestUI }) {
  const dispatch = useDispatch();
  const pickupData = useSelector(state => state.pickups);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  let isVisible = true;

  useEffect(() => {
    isVisible = true;
    PickUps.getPickUps(true)(dispatch);

    setStartDate(
      moment()
        .clone()
        .startOf('month')
        .format('YYYY-MM-DD'),
    );

    setEndDate(
      moment()
        .clone()
        .endOf('month')
        .format('YYYY-MM-DD'),
    );
    return () => {
      isVisible = false;
    };
  }, []);

  const getNewDataSet = () => {
    console.log(startDate, endDate);
    PickUps.getPickUps(false, pickupData.page + 1, startDate, endDate)(dispatch);
  };

  const filterData = () => {
    PickUps.getPickUps(true, 1, startDate, endDate)(dispatch);
  };

  const handleStartDate = e => {
    setStartDate(e);
    console.log(startDate);
  };
  const handleEndDate = e => {
    setEndDate(e);
    console.log(endDate);
  };

  // console.log(pickupData.pickUps, 'pickUpdata');
  return (
    <div>
      <DataTable
        toggleNewRequestUI={toggleNewRequestUI}
        data={pickupData.pickUps}
        startDate={handleStartDate}
        endDate={handleEndDate}
        getNewData={getNewDataSet}
        filterData={filterData}
      />
    </div>
  );
}

export { PickUpTable };
