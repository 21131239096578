import { DISPLAY_COD_PAYMENTS, DISPLAY_COD_PAYMENT_SUMMARY } from '../../@jumbo/constants/ActionTypes';

export const setCodPayments = (records, page, isNextPage, isInitial, totalRecords) => {
  return dispatch => {
    dispatch({
      type: DISPLAY_COD_PAYMENTS,
      payload: { records, page, isNextPage, isInitial, totalRecords },
    });
  };
};

export const setCodPaymentSummary = (totalCodDeposited, nextCodPayment, period) => {
  return dispatch => {
    dispatch({
      type: DISPLAY_COD_PAYMENT_SUMMARY,
      payload: { totalCodDeposited, nextCodPayment, period },
    });
  };
};
