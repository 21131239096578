import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  cardRoot: {
    height: '100%',
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: '4px',
    padding: 24,
  },
  centerContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // width: '100%',
  },
  cardHeader: {
    padding: '0 0 16px 0',
  },
  cardBody: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  hr: {
    border: '1px solid #ECECEC',
    borderRadius: '5px',
    width: '100%',
  },
}));

const ChartCard = ({ title, subTitle, titleProps, children }) => {
  const classes = useStyles();
  return (
    <Box className={classes.cardRoot} style={{ justifyContent: 'start' }}>
      <CmtCardHeader
        className={classes.cardHeader}
        title={title}
        titleProps={titleProps}
        subTitle={subTitle}
        subTitleProps={{ style: { color: '#64748B', fontSize: 14, marginTop: 0 } }}
      />
      <hr className={classes.hr} />
      <Box className={classes.cardBody}>{children}</Box>
    </Box>
  );
};

export default ChartCard;
