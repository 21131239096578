import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
  Paper,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';
import PrintIcon from '@material-ui/icons/Print';
import { useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import EnhancedTableHead from 'custom_components/EnhancedTableHead';
import Orders from 'services/orders';
import AppConfigs from '../../../Utils/AppConfigs';

import { useHistory } from 'react-router-dom';

import { openDialog } from 'redux/actions/PrintOrder';
import ExportIcon from 'custom_components/ExportIcon';
import TabPanel from 'custom_components/TabPanel';
import EnhancedTableToolbar from 'custom_components/EnhancedTableToolbar';
import ErrorToast from 'custom_components/Pages/common/ErrorToast';
import reports from 'services/reports';

const useStyles = makeStyles(theme => ({
  panelContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },

  leftPanel: {
    display: 'flex',
    flex: 1,
    paddingRight: 10,
  },
  rightPanel: {
    display: 'flex',
    flex: 1,

    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flex: 2,
      resize: 'contained',
      borderLeft: '1px solid black',
      paddingLeft: 15,
    },
  },
  wayBillNumberContainer: {
    paddingTop: 10,
    paddingBottom: 10,
  },

  consigneeInfoContainer: {
    display: 'flex',
    flexDirection: 'column',

    border: '1px solid red',
    borderRadius: '15px',
    padding: '15px',
    marginTop: '15px',
  },

  consigneeInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },

  packageDetailContainer: {
    paddingTop: 20,
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  customFontSize12: {
    fontSize: '12px',
  },

  customFontSize14: {
    fontSize: '14px',
  },

  bottomHorizontalContainer: {
    flexDirection: 'row',
    display: 'flex',
    gap: 30,
  },

  trackingHistoryContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  trackingHistoryDetailsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  trackingHistoryDetailsLeft: {
    display: 'flex',
    flex: 1,
    marginBottom: 20,
  },
  trackingHistoryInfo: {
    border: '1px solid black',
    padding: 5,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    gap: 5,
  },

  trackingStatesLabel: {
    display: 'flex',
    borderRadius: '25px',
    backgroundColor: 'green',
    color: 'white',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 20,
    maxHeight: 35,
  },
  trackingHistoryTableContainer: {
    paddingTop: 25,
  },
  historyInfoBottomRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  paginationContainer: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
  },
  waybillCell: {
    cursor: 'pointer',
  },
}));

const headCells = [
  {
    id: 'waybill',
    numeric: false,
    disablePadding: true,
    label: 'WAYBILL',
  },
  { id: 'reference', numeric: true, disablePadding: false, label: 'REFERENCE' },
  {
    id: 'created_date',
    numeric: false,
    disablePadding: false,
    label: 'CREATED DATE',
  },
  { id: 'consignee', numeric: false, disablePadding: false, label: 'CONSIGNEE' },
  {
    id: 'destination',
    numeric: false,
    disablePadding: false,
    label: 'DESTINATION',
  },
  {
    id: 'cash_on_delivery_amount',
    numeric: true,
    disablePadding: false,
    label: 'COD',
  },
  { id: 'print', numeric: true, disablePadding: false, label: 'ACTION' },
];

function stableSort(array, comparator) {
  if (!Array.isArray(array)) {
    return [];
  }

  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function OrderList() {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [updatedPage, setUpdatedPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [records, setRecords] = useState(true);

  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const search = useSelector(({ orders }) => orders.search);

  useEffect(() => {
    if (location.state && location.state.id) {
      dispatch(Orders.getOrders(1, 10, location.state.id));
    }
  }, [location, location?.state?.id]);

  useEffect(() => {
    if (location.state && location.state.id) {
      dispatch(Orders.getOrders(1, 10, location.state.id));
    }
  }, []);

  useEffect(() => {
    setUpdatedPage(page + 1);
  }, [page]);

  useEffect(() => {
    if (updatedPage >= 1) {
      getNewData();
    }
  }, [updatedPage]);

  useEffect(() => {
    if (!(search?.orders?.length > 0)) {
      setRecords(false);
    }
  }, [search]);

  const getNewData = () => {
    if (location.state && location.state.id) {
      dispatch(Orders.getOrders(updatedPage, rowsPerPage, location.state.id));
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    dispatch(Orders.getOrders(1, event.target.value, location.state.id));
  };

  const errorChecker = () => {
    if (!records && search.orders.length === 0) {
      return true;
    }
    return false;
  };

  function createData(
    waybill,
    reference,
    createdDate,
    consignee,
    destination,
    cash_on_delivery_amount,
    fm_receive_scan_datetime,
  ) {
    return {
      waybill,
      reference,
      createdDate,
      consignee,
      destination,
      cash_on_delivery_amount,
      fm_receive_scan_datetime,
    };
  }

  const dataBuilder = data => {
    if (data?.data?.length === 0) {
      return [];
    } else {
      return data?.data?.map(entry => {
        return createData(
          entry.tracking_number,
          entry.reference,
          entry.created_at,
          entry.consignee,
          entry.destination,
          entry.cash_on_delivery_amount,
        );
      });
    }
  };

  const rows = () => {
    if (search && search.orders) {
      return dataBuilder(search.orders);
    }
    return [];
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows().map(n => n.waybill);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const pushToOrderTracking = (event, id) => {
    history.push({
      pathname: '/order_tracking',
      search: `?id=${id}`,
      state: { id: id },
    });
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const exportToCsv = () => {
    dispatch(reports.searchExportReport(location.state.id));
  };
  const handleRefresh = () => {
    dispatch(Orders.getOrders(1, 10, location.state.id));
    setPage(0);
  };

  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <EnhancedTableToolbar
            enableRefresh={true}
            handleRefresh={handleRefresh}
            enablePrint={true}
            selected={selected}
            numSelected={selected.length}
            origin="waybills"
          />

          <TableContainer>
            <Table className={classes.table} aria-labelledby="tableTitle" size={'medium'} aria-label="enhanced table">
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows()?.length ?? 0}
                headCells={headCells}
                excludeCell={'print'}
              />
              <TableBody>
                {stableSort(rows(), getComparator(order, orderBy)).map((row, index) => {
                  const isItemSelected = isSelected(row.waybill);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.waybill}
                      selected={isItemSelected}
                      classes={{ hover: 'MuiTableRow-hover' }}>
                      <TableCell
                        padding="checkbox"
                        onClick={event => handleClick(event, row.waybill)}
                        className={classes.tableCellPadding}>
                        <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} color={'primary'} />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        onClick={event => pushToOrderTracking(event, row.waybill)}
                        className={classes.waybillCell}>
                        {row.waybill}
                      </TableCell>
                      <TableCell align="right" onClick={event => handleClick(event, row.waybill)}>
                        {row.reference}
                      </TableCell>
                      <TableCell align="left" onClick={event => handleClick(event, row.waybill)}>
                        {AppConfigs.formatDisplayDate(row.createdDate)}
                      </TableCell>
                      <TableCell align="left" onClick={event => handleClick(event, row.waybill)}>
                        {row.consignee}
                      </TableCell>
                      <TableCell align="left" onClick={event => handleClick(event, row.waybill)}>
                        {row.destination}
                      </TableCell>
                      <TableCell align="right" onClick={event => handleClick(event, row.waybill)}>
                        {row.cash_on_delivery_amount}
                      </TableCell>
                      <TableCell padding="default" align="right">
                        <Tooltip title="Print">
                          <IconButton
                            disabled={selected.length > 0}
                            onClick={() => {
                              dispatch(
                                openDialog({
                                  ids: [row.waybill],
                                  origin: 'waybills',
                                }),
                              );
                            }}
                            aria-label="print"
                            color={'primary'}>
                            <PrintIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box className={classes.paginationContainer}>
            <Button
              variant="outlined"
              onClick={exportToCsv}
              size={'small'}
              startIcon={<ExportIcon />}
              className={classes.exportIcon}>
              Export
            </Button>
            <TablePagination
              rowsPerPageOptions={[2, 10, 25, 50, 100]}
              component="div"
              count={search?.total ?? 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        </Paper>
      </div>
      <ErrorToast open={errorChecker()} message="No Records Found" />
    </>
  );
}

export default OrderList;
