import React, { useState, useEffect } from 'react';
import {
  Grid,
  // Box,
  // Button,
  // Checkbox,
  // IconButton,
  // Table,
  // TableBody,
  // TableCell,
  // TableContainer,
  // TablePagination,
  // TableRow,
  // Tooltip,
} from '@material-ui/core';
import GridContainer from '../../../@jumbo/components/GridContainer';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';

import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import PrintIcon from '@material-ui/icons/Print';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import TrackingHistoryTable from 'custom_components/Pages/OrderTracking/TrackingHistoryTable';
import { useParams, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import TrackingHistory from 'services/trackingHistory';
import { SerchResultUITN } from './searchByTN/SerchResultUITN';
import { SearchResultUIR } from './searchByRef/SearchResultUIR';
// import EnhancedTableHead from 'custom_components/EnhancedTableHead';
// import Orders from 'services/orders';
// import AppConfigs from '../../../Utils/AppConfigs';

// import { useHistory } from 'react-router-dom';

// import moment from 'moment/moment';
// import { openDialog } from 'redux/actions/PrintOrder';
// import ExportIcon from 'custom_components/ExportIcon';

const useStyles = makeStyles(theme => ({
  panelContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },

    //85
  },

  leftPanel: {
    display: 'flex',
    flex: 1,
    paddingRight: 10,
  },
  rightPanel: {
    display: 'flex',
    flex: 1,

    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flex: 2,
      resize: 'contained',
      borderLeft: '1px solid black',
      paddingLeft: 15,
    },
  },
  wayBillNumberContainer: {
    paddingTop: 10,
    paddingBottom: 10,
  },

  consigneeInfoContainer: {
    display: 'flex',
    flexDirection: 'column',

    border: '1px solid red',
    borderRadius: '15px',
    padding: '15px',
    marginTop: '15px',
  },

  consigneeInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },

  packageDetailContainer: {
    paddingTop: 20,
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  customFontSize12: {
    fontSize: '12px',
  },

  customFontSize14: {
    fontSize: '14px',
  },

  bottomHorizontalContainer: {
    flexDirection: 'row',
    display: 'flex',
    gap: 30,
  },

  trackingHistoryContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  trackingHistoryDetailsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  trackingHistoryDetailsLeft: {
    display: 'flex',
    flex: 1,
    marginBottom: 20,
  },
  trackingHistoryInfo: {
    border: '1px solid black',
    padding: 5,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    gap: 5,
  },

  trackingStatesLabel: {
    display: 'flex',
    borderRadius: '25px',
    backgroundColor: 'green',
    color: 'white',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 20,
    maxHeight: 35,
  },
  trackingHistoryTableContainer: {
    paddingTop: 25,
  },
  historyInfoBottomRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  paginationContainer: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
  },
}));

// const headCells = [
//   {
//     id: 'waybill',
//     numeric: false,
//     disablePadding: true,
//     label: 'WAYBILL',
//   },
//   { id: 'reference', numeric: true, disablePadding: false, label: 'REFERENCE' },
//   {
//     id: 'created_date',
//     numeric: false,
//     disablePadding: false,
//     label: 'CREATED DATE',
//   },
//   { id: 'consignee', numeric: false, disablePadding: false, label: 'CONSIGNEE' },
//   {
//     id: 'destination',
//     numeric: false,
//     disablePadding: false,
//     label: 'DESTINATION',
//   },
//   // { id: 'no_pieces', numeric: true, disablePadding: false, label: 'NO.PIECES' },
//   // { id: 'type', numeric: false, disablePadding: false, label: 'TYPE' },
//   {
//     id: 'cash_on_delivery_amount',
//     numeric: true,
//     disablePadding: false,
//     label: 'COD',
//   },
//   {
//     id: 'fm_receive_scan_datetime',
//     numeric: false,
//     disablePadding: false,
//     label: 'PICKED DATE',
//   },
//   { id: 'print', numeric: true, disablePadding: false, label: 'ACTION' },
// ];

const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'OrderTracking', isActive: true },
];

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function OrderTracking() {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState('');
  let refmode = false;

  const dispatch = useDispatch();
  const data = useSelector(({ trackingHistory }) => trackingHistory.data);

  // const [order, setOrder] = useState('asc');
  // const [orderBy, setOrderBy] = useState('calories');
  // const [selected, setSelected] = useState([]);
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(25);
  // const [maxPageNum, setMaxPageNum] = useState(0);

  // const [fromDate, setFromDate] = useState(moment('01-01-2022'));
  // const [toDate, setToDate] = useState(moment('01-01-2023'));

  // let isVisible = true;
  // const history = useHistory();

  useEffect(() => {
    if (location.state) {
      if (searchTerm !== location.state.id) {
      }
    }
    // console.log('ID' + location.state.id);

    dispatch(TrackingHistory.getTrackinghistory(location.state.id));

    // console.log('Hello....', data);
  }, [location.state.id]);

  // useEffect(() => {
  //   dispatch(Orders.getCreatedOrdersEs(1, true, `${fromDate.format('DD-MM-YYYY')}`, `${toDate.format('DD-MM-YYYY')}`));

  //   return () => {
  //     isVisible = false;
  //   };
  // }, []);

  // const created = useSelector(({ orders }) => orders.createdEs);

  // let dataCreated = false;

  // function createData(
  //   waybill,
  //   reference,
  //   createdDate,
  //   consignee,
  //   destination,
  //   cash_on_delivery_amount,
  //   fm_receive_scan_datetime,
  // ) {
  //   return {
  //     waybill,
  //     reference,
  //     createdDate,
  //     consignee,
  //     destination,
  //     cash_on_delivery_amount,
  //     fm_receive_scan_datetime,
  //   };
  // }

  // const dataBuilder = data => {
  //   if (data.length === 0) {
  //     return [];
  //   } else {
  //     dataCreated = true;
  //     return data.map(entry => {
  //       return createData(
  //         entry.tracking_number,
  //         entry.reference,
  //         entry.created_at,
  //         entry.consignee,
  //         entry.destination,
  //         entry.cash_on_delivery_amount,
  //         entry.fm_receive_scan_datetime,
  //       );
  //     });
  //   }
  // };

  // const dataForTable = dataBuilder(created.orders); //cashing data

  // const rows = () => {
  //   if (dataCreated) {
  //     return dataForTable;
  //   } else {
  //     return dataBuilder(created.orders);
  //   }
  // };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
  //   }

  //   setSelected(newSelected);
  // };

  // const handleSelectAllClick = event => {
  //   if (event.target.checked) {
  //     const newSelecteds = rows().map(n => n.waybill);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  // const handleRequestSort = (event, property) => {
  //   const isAsc = orderBy === property && order === 'asc';
  //   setOrder(isAsc ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  // const shouldGetNextDataBulk = newPage => {
  //   const nextPageNum = newPage;
  //   if (page < nextPageNum) {
  //     //moving forward
  //     const remaining = rows().length - nextPageNum * rowsPerPage;
  //     if (remaining <= rowsPerPage) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return false;
  //   }
  // };

  // const getNewData = () => {
  //   const next = page + 1;
  //   dispatch(
  //     Orders.getCreatedOrdersEs(next + 1, false, `${fromDate.format('DD-MM-YYYY')}`, `${toDate.format('DD-MM-YYYY')},`),
  //   );
  // };

  // const handleChangePage = (event, newPage) => {
  //   console.log(newPage);
  //   if (shouldGetNextDataBulk(newPage) && maxPageNum !== newPage) {
  //     getNewData();
  //   }
  //   setPage(newPage);
  //   if (newPage > maxPageNum) {
  //     setMaxPageNum(newPage);
  //   }
  // };

  // const handleChangeRowsPerPage = event => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  // const pushToOrderTracking = (event, id) => {
  //   history.push({
  //     pathname: '/order_tracking',
  //     search: `?id=${id}`,
  //     state: { id: id },
  //   });
  // };

  // const isSelected = name => selected.indexOf(name) !== -1;

  // const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows().length - page * rowsPerPage);

  // const exportToCsv = () => {
  // const headings = [];
  // headCells.map(item => (item.id !== 'print' ? headings.push(item.label) : null));
  // const data = stableSort(rows(), getComparator(order, orderBy)).slice(
  //   page * rowsPerPage,
  //   page * rowsPerPage + rowsPerPage,
  // );
  // const dataSet = data.map(item => Object.values(item));
  //
  // const csvBuilder = new CsvBuilder('order_list_export.csv')
  //   .setColumns(headings)
  //   .addRows(dataSet)
  //   .exportFile();
  // dispatch(reports.openCreatedOrdersReportUrl(fromDate, toDate, pickupStatus));
  // };
  // const handleRefresh = () => {
  //   dispatch(Orders.getCreatedOrdersEs(1, true, `${fromDate.format('DD-MM-YYYY')}`, `${toDate.format('DD-MM-YYYY')}`));
  //   setPage(0);
  //   setMaxPageNum(0);
  // };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function renderer() {
    console.log(data);
    if (data === undefined || Object.keys(data).length === 0) {
      return <h2>no data</h2>;
    } else {
      //   console.log('HEREZXXXXXXXXXXX', data);
      return refmode ? <SearchResultUIR /> : <SerchResultUITN data={data} />;
    }
  }

  // function stableSort(array, comparator) {
  //   const stabilizedThis = array.map((el, index) => [el, index]);
  //   stabilizedThis.sort((a, b) => {
  //     const order = comparator(a[0], b[0]);
  //     if (order !== 0) return order;
  //     return a[1] - b[1];
  //   });
  //   return stabilizedThis.map(el => el[0]);
  // }

  // function descendingComparator(a, b, orderBy) {
  //   if (b[orderBy] < a[orderBy]) {
  //     return -1;
  //   }
  //   if (b[orderBy] > a[orderBy]) {
  //     return 1;
  //   }
  //   return 0;
  // }

  // function getComparator(order, orderBy) {
  //   console.log(order, orderBy);
  //   return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
  // }

  return (
    <PageContainer breadcrumbs={breadcrumbs} style={{ backgroundColor: 'blue' }}>
      <GridContainer>
        <Grid item xs={12} sm={12} style={{ padding: 0 }}>
          {/* <SerchResultUITN></SerchResultUITN> */}

          {renderer()}
        </Grid>

        {/* <TableContainer>
          <Table className={classes.table} aria-labelledby="tableTitle" size={'medium'} aria-label="enhanced table">
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows().length}
              headCells={headCells}
              excludeCell={'print'}
            />
            <TableBody>
              {stableSort(rows(), getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.waybill);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.waybill}
                      selected={isItemSelected}
                      classes={{ hover: 'MuiTableRow-hover' }}>
                      <TableCell
                        padding="checkbox"
                        onClick={event => handleClick(event, row.waybill)}
                        className={classes.tableCellPadding}>
                        <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} color={'primary'} />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        onClick={event => pushToOrderTracking(event, row.waybill)}
                        className={classes.waybillCell}>
                        {row.waybill}
                      </TableCell>
                      <TableCell align="right" onClick={event => handleClick(event, row.waybill)}>
                        {row.reference}
                      </TableCell>
                      <TableCell align="left" onClick={event => handleClick(event, row.waybill)}>
                        {AppConfigs.formatDisplayDate(row.createdDate)}
                      </TableCell>
                      <TableCell align="left" onClick={event => handleClick(event, row.waybill)}>
                        {row.consignee}
                      </TableCell>
                      <TableCell align="left" onClick={event => handleClick(event, row.waybill)}>
                        {row.destination}
                      </TableCell>
                      <TableCell align="right" onClick={event => handleClick(event, row.waybill)}>
                        {row.cash_on_delivery_amount}
                      </TableCell>
                      <TableCell align="left" onClick={event => handleClick(event, row.waybill)}>
                        {row.fm_receive_scan_datetime !== null
                          ? AppConfigs.formatDisplayDateAndTime(row.fm_receive_scan_datetime)
                          : ''}
                      </TableCell>
                      <TableCell padding="default" align="right">
                        <Tooltip title="Print">
                          <IconButton
                            disabled={selected.length > 0}
                            onClick={() => {
                              dispatch(
                                openDialog({
                                  ids: [row.waybill],
                                  origin: 'waybills',
                                }),
                              );
                            }}
                            aria-label="print"
                            color={'primary'}>
                            <PrintIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={12} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box className={classes.paginationContainer}>
          <Button
            variant="outlined"
            onClick={exportToCsv}
            size={'small'}
            startIcon={<ExportIcon />}
            className={classes.exportIcon}>
            Export
          </Button>
          <TablePagination
            rowsPerPageOptions={false}
            component="div"
            count={created.total ? created.total : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box> */}
      </GridContainer>
    </PageContainer>
  );
}

export default OrderTracking;
