import moment from 'moment';
const AppConfigs = {
  formatDisplayDate(date) {
    if (date !== null || date !== '') {
      return moment(new Date(date)).format('DD/MM/YYYY');
    } else {
      return '';
    }
  },
  formatDisplayDateAndTime(date) {
    if (date !== null || date !== '') {
      return moment(new Date(date)).format('DD/MM/YYYY,  HH:MM:SS');
    } else {
      return '';
    }
  },
};

export default AppConfigs;
