import React from 'react';
import DataTable from './DataTable';

const Support = () => {

  return (
    <div>
      <DataTable />
    </div>
  );
};

export default Support;
