import { OPEN_ORDER_PRINT_DIALOG, CLOSE_ORDER_PRINT_DIALOG, PRINT_ORDERS } from '../../../@jumbo/constants/ActionTypes';
const INIT_STATE = {
  open: false,
  ids: null,
  origin: null,
};

export default (state = INIT_STATE, action) => {
  // console.log(action.payload);
  // const { ids, origin } = action.payload;
  switch (action.type) {
    case OPEN_ORDER_PRINT_DIALOG:
      return {
        ...state,
        open: true,
        ids: action.payload.ids,
        origin: action.payload.origin,
      };
    case CLOSE_ORDER_PRINT_DIALOG:
      return { ...state, open: false, ids: null, origin: null };

    default:
      return state;
  }
};
