import axios from '../auth/jwt/config';
import { fetchStart, fetchSuccess } from '../../redux/actions';
import { setOverviewInStore } from 'redux/actions/Overview';

const Overviews = {
  getOverview: (fromDate, toDate) => {
    console.log(fromDate, toDate);
    return dispatch => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      axios
        .get(`overview?from_date=${fromDate}&to_date=${toDate}`)
        .then(({ data }) => {
          const overview = data.data;
          dispatch(setOverviewInStore({ overview: overview }));
        })
        .catch(error => {
          console.log(error, 'error');
        });
      dispatch(fetchSuccess());
    };
  },
};

export default Overviews;
