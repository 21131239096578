import React from 'react';
import { Button, IconButton, TableCell, Tooltip } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import PrintIcon from '@material-ui/icons/Print';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import TrackingHistoryTable from 'custom_components/Pages/OrderTracking/TrackingHistoryTable';
import { openDialog } from '../../../../redux/actions/PrintOrder';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  orderTrackingContainer: {
    backgroundColor: theme.palette.background.paper,
    //alignContent:'center',
    //justifyContent:'center',
    borderRadius: 10,
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 15,
    paddingBottom: 5,
    display: 'flex',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)',
    //height:'100%',
  },

  panelContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },

    //85
  },

  leftPanel: {
    display: 'flex',
    flex: 1,
    paddingRight: 10,
  },
  rightPanel: {
    display: 'flex',
    flex: 1,

    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flex: 2,
      resize: 'contained',
      borderLeft: '1px solid black',
      paddingLeft: 15,
    },
  },
  wayBillNumberContainer: {
    paddingTop: 10,
    paddingBottom: 10,
  },

  consigneeInfoContainer: {
    display: 'flex',
    flexDirection: 'column',

    border: '1px solid red',
    borderRadius: '15px',
    padding: '15px',
    marginTop: '15px',
  },

  consigneeInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },

  packageDetailContainer: {
    paddingTop: 20,
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  },
  customFontSize12: {
    fontSize: '12px',
  },

  customFontSize14: {
    fontSize: '14px',
  },

  bottomHorizontalContainer: {
    flexDirection: 'row',
    display: 'flex',
    gap: 30,
  },

  trackingHistoryContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  trackingHistoryDetailsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  trackingHistoryDetailsLeft: {
    display: 'flex',
    flex: 1,
    marginBottom: 20,
  },
  trackingHistoryInfo: {
    border: '1px solid black',
    padding: 5,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    gap: 5,
  },

  trackingStatesLabel: {
    display: 'flex',
    borderRadius: '25px',
    backgroundColor: 'green',
    color: 'white',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 20,
    maxHeight: 35,
  },
  trackingHistoryTableContainer: {
    paddingTop: 25,
  },
  historyInfoBottomRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  paginationContainer: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
  },
}));

function SerchResultUITN({ data }) {
  const dispatch = useDispatch();

  console.log(data);
  const classes = useStyles();
  return (
    <Box className={classes.orderTrackingContainer}>
      <Box className={classes.panelContainer}>
        <Box className={classes.leftPanel}>
          <div className={classes.wayBillNumberContainer}>
            <Typography variant="h3" component="div" gutterBottom>
              WayBill - {data.tracking_number}
            </Typography>
            <div className={classes.consigneeInfoContainer}>
              <div className={classes.consigneeInfo}>
                <div>
                  <Typography component="div" variant="h3">
                    Consignee Details
                  </Typography>
                </div>
                <div>
                  <Typography component="div" variant={'subtitle1'}>
                    {data.consignee ? data.consignee : 'N/A'}
                  </Typography>
                </div>
                <div>
                  {data.destination_address.map(address => (
                    <div> {`${address}`}</div>
                  ))}
                </div>

                <div>
                  {/*{data.to_contact_name}*/}
                  {/*<br />*/}
                  {data.to_contact_number_1}
                  <br />
                  {data.to_contact_number_2}
                </div>
              </div>
            </div>

            <div className={classes.packageDetailContainer}>
              <div>
                <Typography className={classes.packageDetalTitle} component={'div'} variant={'h3'}>
                  Package Details
                </Typography>
              </div>

              <div>
                <Typography className={classes.customFontSize12} component={'div'}>
                  Reference
                </Typography>
                <Typography className={classes.customFontSize14} component={'div'} color={'primary'}>
                  {data.reference ? data.reference : 'N/A'}
                </Typography>
              </div>

              {/*<div>*/}
              {/*  <Typography className={classes.customFontSize12} component={'div'}>*/}
              {/*    Payment Type*/}
              {/*  </Typography>*/}
              {/*  <Typography className={classes.customFontSize12} component={'span'}>*/}
              {/*    {data.payment_type ? data.payment_type : 'N/A '}*/}
              {/*  </Typography>*/}
              {/*</div>*/}

              <div className={classes.bottomHorizontalContainer}>
                {/*<div>*/}
                {/*  <Typography className={classes.customFontSize12} component={'div'}>*/}
                {/*    Payment*/}
                {/*  </Typography>*/}
                {/*  <Typography className={classes.customFontSize14} component={'div'} color={'primary'}>*/}
                {/*    {`${data.delivery_charge ? data.delivery_charge : ''}`}*/}
                {/*  </Typography>*/}
                {/*</div>*/}

                {/*<div>*/}
                {/*  <Typography className={classes.customFontSize12} component={'div'}>*/}
                {/*    Weight*/}
                {/*  </Typography>*/}
                {/*  <Typography className={classes.customFontSize14} component={'div'} color={'primary'}>*/}
                {/*    {data.weight_kg ? data.weight_kg : ''}*/}
                {/*  </Typography>*/}
                {/*</div>*/}

                <div>
                  <Typography className={classes.customFontSize12} component={'div'}>
                    Chargeable Weight
                  </Typography>
                  <Typography className={classes.customFontSize14} component={'div'} color={'primary'}>
                    {data.billable_weight_kg ? data.billable_weight_kg : ''}
                  </Typography>
                </div>

                <div>
                  <Typography className={classes.customFontSize12} component={'div'}>
                    COD
                  </Typography>
                  <Typography className={classes.customFontSize14} component={'div'} color={'primary'}>
                    {data.is_cash_on_delivery === true ? data.cash_on_delivery_amount : 'N/A'}
                  </Typography>
                </div>

                {/*<div>*/}
                {/*  <Typography className={classes.customFontSize12} component={'div'}>*/}
                {/*    Pieces*/}
                {/*  </Typography>*/}
                {/*  <Typography className={classes.customFontSize14} component={'div'} color={'primary'}>*/}
                {/*    {data.number_of_pieces ? data.number_of_pieces : '1'}*/}
                {/*  </Typography>*/}
                {/*</div>*/}
              </div>

              <div>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  startIcon={<PrintIcon />}
                  onClick={() => {
                    dispatch(
                      openDialog({
                        ids: [data.tracking_number],
                        origin: 'waybills',
                      }),
                    );
                  }}>
                  Print
                </Button>
              </div>
            </div>
          </div>
        </Box>
        <Box className={classes.rightPanel}>
          <div className={classes.trackingHistoryContainer}>
            <div className={classes.trackingHistoryDetailsContainer}>
              <div className={classes.trackingHistoryDetailsLeft}>
                <Typography variant="h3" component="div">
                  Tracking History
                </Typography>
                {/*<div className={classes.trackingStatesLabel}>DELIVERED</div>*/}
              </div>

              <div className={classes.trackingHistoryInfo}>
                <Typography variant="h3" component="div">
                  Received by
                </Typography>
                <Typography varient="h1" component="div">
                  Name - {data.receiver_name ? data.receiver_name : 'N/A'}
                </Typography>
                <div className={classes.historyInfoBottomRow}>
                  <Typography varient="h1" component="div">
                    Nic - {data.receiver_nic ? data.receiver_nic : 'N/A'}
                  </Typography>
                  <div>
                    <Button variant="outlined" color="secondary" className={classes.button} startIcon={<CameraAltIcon />}>
                      View
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.trackingHistoryTableContainer}>
              <TrackingHistoryTable data={data.tracking_history} />
            </div>
          </div>
        </Box>
      </Box>
    </Box>
  );
}

export { SerchResultUITN };
