import {
  Tooltip,
  IconButton,
  Checkbox,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Box,
} from '@material-ui/core';
import EnhancedTableToolbar from 'custom_components/EnhancedTableToolbar';
import TabPanel from 'custom_components/TabPanel';
import PrintIcon from '@material-ui/icons/Print';
import Button from '@material-ui/core/Button';
import React, { useState, useEffect } from 'react';
import ExportIcon from 'custom_components/ExportIcon';
import { useDispatch, useSelector } from 'react-redux';
import Orders from 'services/orders';
import ErrorToast from 'custom_components/Pages/common/ErrorToast';
import { openDialog } from '../../../redux/actions/PrintOrder';
import AppConfigs from '../../../Utils/AppConfigs';
import moment from 'moment/moment';
import reports from '../../../services/reports/index';
import { useHistory } from 'react-router-dom';
import InTransitTableHeader from 'custom_components/InTransitTableHeader';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: 'green',
  },

  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

  paginationContainer: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
  },
  tableCellPadding: {
    paddingBottom: 0,
    paddingTop: 0,
  },

  exportIcon: {
    marginRight: 20,
  },
  toolContainer: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
    padding: 5,
  },
  exportButtonContainer: {
    display: 'flex',
    flex: 2,
    justifyContent: 'right',
    alignItems: 'center',
    paddingRight: 20,
    //backgroundColor:'green'
  },
  datePickerContainer: {
    display: 'flex',
    flex: 6,
    justifyContent: 'right',
    alignItems: 'center',
    paddingRight: 20,
    //backgroundColor:'yellow'
  },

  tabPanel: {
    '& > div': {
      paddingLeft: 0,
      paddingRight: 0,
      //backgroundColor:'blue'
    },
  },
  waybillCell: {
    cursor: 'pointer',
  },
}));

const headCells = [
  {
    id: 'waybill',
    numeric: false,
    disablePadding: true,
    label: 'WAYBILL',
  },
  { id: 'reference', numeric: true, disablePadding: false, label: 'REFERENCE' },
  {
    id: 'created_date',
    numeric: false,
    disablePadding: false,
    label: 'CREATED DATE',
  },
  { id: 'consignee', numeric: false, disablePadding: false, label: 'CONSIGNEE' },
  {
    id: 'destination',
    numeric: false,
    disablePadding: false,
    label: 'DESTINATION',
  },
  // { id: 'no_pieces', numeric: true, disablePadding: false, label: 'NO.PIECES' },
  // { id: 'type', numeric: false, disablePadding: false, label: 'TYPE' },
  {
    id: 'cash_on_delivery_amount',
    numeric: true,
    disablePadding: false,
    label: 'COD',
  },
  { id: 'print', numeric: true, disablePadding: false, label: 'ACTION' },
];

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array = [], comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function CreatedOrderTabPanelNew({ value, index, dashboardFromDate, dashboardToDate }) {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [updatedPage, setUpdatedPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [fromDate, setFromDate] = useState(moment());
  const [toDate, setToDate] = useState(moment());

  const dispatch = useDispatch();
  const history = useHistory();
  const created = useSelector(({ orders }) => orders.createdNew);
  let dataCreated = false;

  useEffect(() => {
    setUpdatedPage(page + 1);
  }, [page]);

  useEffect(() => {
    if (updatedPage >= 1) {
      getNewData();
    }
  }, [updatedPage]);

  const handleRefresh = () => {
    const fromDateValue = fromDate ? fromDate.format('DD-MM-YYYY') : moment().format('DD-MM-YYYY');
    const toDateValue = toDate ? toDate.format('DD-MM-YYYY') : moment().format('DD-MM-YYYY');
    dispatch(Orders.getCreatedOrdersNew(updatedPage, true, rowsPerPage, fromDateValue, toDateValue));
  };

  useEffect(() => {
    if (dashboardFromDate) {
      const parsedDate = moment(dashboardFromDate, 'DD-MM-YYYY');
      setFromDate(parsedDate);
    }
  }, [dashboardFromDate]);

  useEffect(() => {
    if (dashboardToDate) {
      const parsedDate = moment(dashboardToDate, 'DD-MM-YYYY');
      setToDate(parsedDate);
    }
  }, [dashboardToDate]);

  const getNewData = () => {
    const fromDateValue = fromDate ? fromDate.format('DD-MM-YYYY') : moment().format('DD-MM-YYYY');
    const toDateValue = toDate ? toDate.format('DD-MM-YYYY') : moment().format('DD-MM-YYYY');

    if (dashboardFromDate && dashboardToDate) {
      dispatch(Orders.getCreatedOrdersNew(updatedPage, false, rowsPerPage, fromDateValue, toDateValue));
    } else {
      dispatch(Orders.getCreatedOrdersNew(updatedPage, false, rowsPerPage));
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
    setPage(0);
    dispatch(Orders.getCreatedOrdersNew(1, true, event.target.value, dashboardFromDate, dashboardToDate));
  };

  const pushToOrderTracking = (event, id) => {
    history.push({
      pathname: '/order_tracking',
      search: `?id=${id}`,
      state: { id: id },
    });
  };

  const exportToCsv = () => {
    if (dashboardFromDate && dashboardToDate) {
      dispatch(reports.fmScanOrdersReportUrl(dashboardFromDate, dashboardToDate));
    } else {
      dispatch(reports.fmScanOrdersReportUrl());
    }
  };

  function createData(waybill, reference, createdDate, consignee, destination, cash_on_delivery_amount) {
    return {
      waybill,
      reference,
      createdDate,
      consignee,
      destination,
      cash_on_delivery_amount,
    };
  }

  const dataBuilder = data => {
    if (data.length === 0) {
      dataCreated = false;
      return [];
    } else {
      dataCreated = true;
      return data.map(entry => {
        return createData(
          entry.tracking_number,
          entry.reference,
          entry.created_at,
          entry.consignee,
          entry.destination,
          entry.cash_on_delivery_amount,
        );
      });
    }
  };

  const dataForTable = dataBuilder(created?.orders ? [...created.orders] : []);

  const rows = () => {
    if (dataCreated) {
      return dataForTable;
    } else {
      return dataBuilder(created?.orders ? [...created.orders] : []);
    }
  };

  const errorChecker = () => {
    const ordersLength = created?.orders?.length ?? 0;
    if (ordersLength === 0 && value === 1) {
      return true;
    }
    return false;
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows().map(n => n.waybill);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  return (
    <div>
      <TabPanel value={value} index={index} className={classes.tabPanel}>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <EnhancedTableToolbar numSelected={selected.length} enableRefresh={true} handleRefresh={handleRefresh} />

            <TableContainer>
              <Table className={classes.table} aria-labelledby="tableTitle" size={'medium'} aria-label="enhanced table">
                <InTransitTableHeader
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows()?.length ?? 0}
                  headCells={headCells}
                />
                <TableBody>
                  {stableSort(rows(), getComparator(order, orderBy)).map((row, index) => {
                    const isItemSelected = isSelected(row.waybill);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.waybill}
                        selected={isItemSelected}
                        classes={{ hover: 'MuiTableRow-hover' }}>
                        <TableCell
                          padding="checkbox"
                          onClick={event => handleClick(event, row.waybill)}
                          className={classes.tableCellPadding}>
                          <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} color={'primary'} />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          onClick={event => pushToOrderTracking(event, row.waybill)}
                          className={classes.waybillCell}>
                          {row.waybill}
                        </TableCell>
                        <TableCell align="right" onClick={event => handleClick(event, row.waybill)}>
                          {row.reference}
                        </TableCell>
                        <TableCell align="left" onClick={event => handleClick(event, row.waybill)}>
                          {AppConfigs.formatDisplayDate(row.createdDate)}
                        </TableCell>
                        <TableCell align="left" onClick={event => handleClick(event, row.waybill)}>
                          {row.consignee}
                        </TableCell>
                        <TableCell align="left" onClick={event => handleClick(event, row.waybill)}>
                          {row.destination}
                        </TableCell>
                        <TableCell align="right" onClick={event => handleClick(event, row.waybill)}>
                          {row.cash_on_delivery_amount}
                        </TableCell>
                        <TableCell padding="default" align="right">
                          <Tooltip title="Print">
                            <IconButton
                              disabled={selected.length > 0}
                              onClick={() => {
                                dispatch(
                                  openDialog({
                                    ids: [row.waybill],
                                    origin: 'waybills',
                                  }),
                                );
                              }}
                              aria-label="print"
                              color={'primary'}>
                              <PrintIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box className={classes.paginationContainer}>
              <Button
                variant="outlined"
                onClick={exportToCsv}
                size={'small'}
                startIcon={<ExportIcon />}
                className={classes.exportIcon}>
                Export
              </Button>
              <TablePagination
                rowsPerPageOptions={[2, 10, 25, 50, 100]}
                component="div"
                count={created?.total ?? 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Box>
          </Paper>
        </div>
      </TabPanel>
      <ErrorToast open={errorChecker()} message="No Records Found" />
    </div>
  );
}

export default CreatedOrderTabPanelNew;
