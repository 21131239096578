import React from 'react';
import { Box, MenuItem, MenuList, Paper, Popover, Typography } from '@material-ui/core';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
//import { AuthMethods } from '../../../../services/auth';
//import { CurrentAuthMethod } from '../../../constants/AppConstants';
import { AuhMethods } from '../services/auth';
import { CurrentAuthMethod } from '../@jumbo/constants/AppConstants';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import CmtAvatar from '@coremat/CmtAvatar';
import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    //padding: '30px 16px 12px 16px',
    borderBottom: `solid 1px ${theme.palette.sidebar.borderColor}`,
    //backgroundColor:'red',
    height: '100%',
  },
  userInfo: {
    paddingTop: 10,
    height: '100%',
    opacity: 1,
  },
  userTitle: {
    color: 'white',
    marginBottom: 8,
  },
  userSubTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0.25,
  },

  userNameAndButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

function UserProfile() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const user = useSelector(({ auth }) => auth.authUser);
  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const onLogoutClick = () => {
    handlePopoverClose();
    dispatch(AuhMethods[CurrentAuthMethod].onLogout());
  };
  console.log('UserProfileRendering....');
  return (
    <Box className={classes.root}>
      <Box className={classes.userInfo} onClick={handlePopoverOpen}>
        <Box className="pointer" display="flex" justifyContent="space-between" alignItems="flex-end">
          <Box mr={2} className={classes.userNameAndButtonContainer}>
            <Typography className={classes.userTitle} component="h3" variant="h6">
              Hello {user ? user.display_name : ''}
            </Typography>
            {/* <Typography className={classes.userSubTitle}>robert.johnson@gmail.com</Typography> */}
            <ArrowDropDownIcon style={{ marginLeft: 10 }} />
          </Box>
        </Box>
      </Box>

      {open && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          container={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}>
          <Paper elevation={8}>
            <MenuList>
              {/*<MenuItem onClick={handlePopoverClose}>*/}
              {/*  <PersonIcon />*/}
              {/*  <Box ml={2}>*/}
              {/*    <NavLink to="/customer-profile">Profile</NavLink>*/}
              {/*  </Box>*/}
              {/*</MenuItem>*/}
              <MenuItem onClick={handlePopoverClose}>
                <SettingsIcon />
                <Box ml={2}>
                  <NavLink to="/account">Account</NavLink>
                </Box>
              </MenuItem>
              <MenuItem onClick={onLogoutClick}>
                <ExitToAppIcon />
                <Box ml={2}>Logout</Box>
              </MenuItem>
            </MenuList>
          </Paper>
        </Popover>
      )}
    </Box>
  );
}

export default UserProfile;
