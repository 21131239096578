import { useSelector } from 'react-redux';
import { setAuthUser } from 'redux/actions/Auth';
import { setBulkUploads } from 'redux/actions/BulkUpload';
import BulkUpload from 'routes/Pages/BulkUpload';
import { database } from 'services/auth/firebase/config';
import { fetchError, fetchStart, fetchSuccess } from '../../redux/actions';
import { showSnackBar } from 'redux/actions/SnackBar';
import { openBulkUploadDialog, closeBulkUploadDialog } from 'redux/actions/BulkUpload';
import axios from '../auth/jwt/config';

const BulkUploads = {
  downloadFile: trackingNumber => {
    return dispatch => {
      dispatch(fetchStart());
      console.log('Downloading Image ...............');
      const token = localStorage.getItem('token'); //check
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      axios
        .get(`/bulk-uploads-filled-excel/${trackingNumber}`, {
          responseType: 'blob',
        })
        .then(data => {
          //console.log("Ddata",data);

          if (data.status == 200) {
            const result = data.data;
            dispatch(fetchSuccess());
            //dispatch(setBulkUploads(result,1));
            const downloadUrl = window.URL.createObjectURL(new Blob([result]));

            const link = document.createElement('a');

            link.href = downloadUrl;

            link.setAttribute('download', trackingNumber + '.xlsx'); //any other extension

            document.body.appendChild(link);

            link.click();

            link.remove();
          } else {
            const data = data.data;
            dispatch(fetchError('Erro'));
          } //
        })
        .catch(function(error) {
          console.log(error);
          dispatch(fetchError(error.message));
        });
    };
  },

  getBulkUploads: (page = 1, isInitial = false) => {
    return dispatch => {
      dispatch(fetchStart());
      console.log('Fetching Bulk Uploads...............');
      const token = localStorage.getItem('token'); //check
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      axios
        .get(`bulk-uploads?page=${page}`)
        .then(({ data }) => {
          if (data.status == 'success') {
            const result = data.data.data;
            const isNextPage = data.data.next_page_url ? true : false;
            dispatch(fetchSuccess());
            dispatch(setBulkUploads(result, page, isNextPage, isInitial));
          } else {
            const result = data.data;
            const validationErrors = result.validation_error;

            const displayNameE = validationErrors[0].display_name;
            const nicE = validationErrors[1].nic;
            const contactE = validationErrors[2].contact_numaer;
            const dobE = validationErrors[3].date_of_birth;
            const genderE = validationErrors[4].gender;
            const emailE = validationErrors[5].email;

            const errMsg = Array(displayNameE, nicE, contactE, dobE, genderE, emailE);

            let composedErrMessage = '';
            errMsg.forEach(err => {
              if (!err == '') {
                composedErrMessage += err;
              }
            });
            dispatch(fetchError(composedErrMessage));
          } //
        })
        .catch(function(error) {
          console.log(error);
          dispatch(fetchError(error.message));
        });
    };
  },
  uploadBulkFile: function(file) {
    const main = this;
    return dispatch => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token'); //check
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';

      const formData = new FormData();

      formData.append('file', file);
      formData.append('requested_by', 1);
      formData.append('approved_by', 1);
      formData.append('remarks', 'test remarks');

      axios
        .post('bulk-uploads', formData)
        .then(data => {
          dispatch(fetchSuccess());
          const res = data.data;
          if (res.status === 'success') {
            dispatch(showSnackBar({ message: res.data.message, severity: 'success' }));
            dispatch(closeBulkUploadDialog());
            dispatch(main.getBulkUploads(1, true));
          }
          if (res.status === 'fail') {
            dispatch(
              showSnackBar({
                message: res.data.validation_error.file,
                severity: 'error',
              }),
            );
          }
        })
        .catch(err => {
          dispatch(
            showSnackBar({
              message: `Unexpected error happens`,
              severity: 'error',
            }),
          );
        });
    };
  },
};

export default BulkUploads;
