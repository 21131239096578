import { makeStyles, Typography } from '@material-ui/core';

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tickets from 'services/tickets';
import AttachmentModal from '@jumbo/components/Common/Modals/AttachmentModal';
import CreateAvatar from './CreateAvatar';
import moment from 'moment';
import AttachmentItem from './AttachmentItem';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },

  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

  paginationContainer: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
  },

  toolContainer: {
    padding: 5,
    width: '100%',
  },
  datePickerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // paddingRight: 20,
    width: '100%',
  },
  tabPanel: {
    '& > div': {
      paddingLeft: 0,
      paddingRight: 0,
      //backgroundColor:'blue'
    },
  },
  formInput: {
    marginTop: '5px',
    marginBottom: '5px',
    padding: 10,
    marginRight: 10,
    width: '100%',

    backgroundColor: '#fff',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  ticketContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  ticketHeader: {
    color: '#BE0707',
    fontWeight: 600,
    fontSize: '14px',
  },
  ticketTitle: {
    fontSize: '14px',
  },
  divider: {
    border: 'none',
    height: '1px',
    margin: '0 8px',
    flexShrink: 0,
    backgroundColor: 'rgba(190, 7, 7, 1)',
  },
}));

const ThreadItems = ({ replies, ticketId }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const ticketsData = useSelector(state => state.tickets);
  const [attachmentModalOpen, setAttachmentModalOpen] = useState(false);
  const [image, setImage] = useState(null);
  const scrollRef = useRef(null);

  const handleCloseAttachmentModal = () => {
    setAttachmentModalOpen(false);
  };

  const handleOpenAttachmentModal = () => {
    setAttachmentModalOpen(true);
  };

  const func = (id, type, ext) => {
    if (['jpg', 'jpeg', 'png'].includes(ext)) {
      handleOpenAttachmentModal();
    }

    type === 'ticket' && dispatch(Tickets.getTicketAttachment(id));
    type === 'reply' && dispatch(Tickets.getReplyAttachment(id));
  };

  useEffect(() => {
    setImage(ticketsData.attachment);
  }, [ticketsData.attachment]);

  useEffect(() => {
    if (scrollRef.current) {
      const scrollElement = scrollRef.current;
      scrollElement.scrollTop = scrollElement.scrollHeight - scrollElement.clientHeight;
    }
  }, [replies]);

  if (replies) {
    const merchant = replies?.ticket?.account?.display_name === 'Merchant';
    return (
      <div style={{ backgroundColor: '#fff' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '12px 32px' }}>
          <div className={classes.ticketHeader}>{`Ticket #${replies?.ticket?.id}`}</div>
          <div className={classes.ticketTitle}>{`Subject: ${replies?.ticket?.title}`}</div>
          <div className={classes.ticketTitle}>
            {replies?.ticket?.tracking_numbers ? `Tracking No: ${replies?.ticket?.tracking_numbers}` : ''}
          </div>
        </div>
        <hr className={classes.divider} />
        <div style={{ height: '63.7vh', overflow: 'scroll', scrollbarWidth: 'none', padding: '18px' }} ref={scrollRef}>
          <div
            style={{
              marginBottom: 10,
              display: 'flex',
              justifyContent: merchant ? 'flex-end' : 'flex-start',
              padding: '8px 16px',
            }}>
            <div style={{ display: 'flex', flexDirection: merchant ? 'row-reverse' : 'row', alignItems: 'center' }}>
              <CreateAvatar displayName={replies?.ticket?.account?.display_name} />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  borderColor: merchant ? '#d9d9d9' : '#be0707',
                  borderRadius: '4px',
                  padding: '8px 16px',
                  marginLeft: merchant ? 0 : '12px',
                  marginRight: merchant ? '12px' : 0,
                  width: 'fit-content',
                  alignItems: merchant ? 'flex-end' : 'flex-start',
                }}>
                <Typography
                  component="div"
                  variant="body2"
                  className={classes.inline}
                  color="textPrimary"
                  style={{ textAlign: merchant ? 'right' : 'left' }}>
                  {replies?.ticket?.message}
                </Typography>

                {replies?.ticket?.attachment && (
                  <AttachmentItem
                    attachmentUrl={replies.ticket.attachment}
                    onClick={fileExtension => {
                      func(replies.ticket.id, 'ticket', fileExtension);
                    }}
                  />
                )}
                <Typography
                  component="span"
                  variant="body2"
                  color="textPrimary"
                  style={{
                    fontSize: '12px',
                    paddingTop: '12px',
                  }}>
                  {replies?.ticket?.created_at && moment(replies.ticket.created_at).format('DD-MM-YYYY HH:mm')}
                </Typography>
              </div>
            </div>
          </div>

          <div>
            {replies.ticket?.replies.map(reply => {
              const merchant = reply.account.display_name === 'Merchant';
              return (
                <div
                  key={reply.id}
                  style={{
                    marginBottom: 10,
                    display: 'flex',
                    justifyContent: merchant ? 'flex-end' : 'flex-start',
                    padding: '8px 16px',
                  }}>
                  <div style={{ display: 'flex', flexDirection: merchant ? 'row-reverse' : 'row', alignItems: 'center' }}>
                    <CreateAvatar displayName={reply.account.display_name} />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        borderWidth: '1px',
                        borderStyle: 'solid',
                        borderColor: merchant ? '#d9d9d9' : '#be0707',
                        borderRadius: '4px',
                        padding: '8px 16px',
                        marginLeft: merchant ? 0 : '12px',
                        marginRight: merchant ? '12px' : 0,
                        width: 'fit-content',
                        alignItems: merchant ? 'flex-end' : 'flex-start',
                      }}>
                      <Typography
                        component="div"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                        style={{ display: 'flex', justifyContent: merchant ? 'end' : 'start' }}>
                        {reply.message}
                      </Typography>

                      {reply.attachment && (
                        <AttachmentItem
                          attachmentUrl={reply.attachment}
                          onClick={fileExtension => {
                            func(reply.id, 'reply', fileExtension);
                          }}
                        />
                      )}
                      <Typography
                        component="span"
                        variant="body2"
                        color="textPrimary"
                        style={{
                          fontSize: '12px',
                          paddingTop: '12px',
                        }}>
                        {moment(reply.created_at).format('DD-MM-YYYY HH:mm')}
                      </Typography>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <AttachmentModal open={attachmentModalOpen} onClose={handleCloseAttachmentModal} image={image} />
        </div>
      </div>
    );
  }

  return null;
};

export default ThreadItems;
