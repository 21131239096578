import axios from '../auth/jwt/config';
import { fetchError, fetchStart, fetchSuccess } from '../../redux/actions';
import { setPickUpCreatedState, setPickupsInStore } from 'redux/actions/Pickups';

const PickUps = {
  getPickUps: (isFirst, page = 1, fromDate, toDate) => {
    console.log(fromDate, toDate);
    return dispatch => {
      console.log('Executing....');
      dispatch(fetchStart());
      const token = localStorage.getItem('token'); //check
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      axios
        .get(`pickups?page=${page}&from_date=${fromDate}&to_date=${toDate}`)
        .then(({ data }) => {
          const pickups = data.data;
          // console.log(pickups, 'pickup......');
          const PickUpData = pickups.map(pickup => {
            pickup.status = {
              isPicked: pickup.is_picked,
              isScheduled: pickup.is_scheduled,
              isRejected: pickup.is_rejected,
              isCompleted: pickup.is_completed,
            };
            return pickup;
          });
          //pickup_added_datetime
          dispatch(setPickupsInStore({ pickUps: PickUpData, isFirst, page }));
        })
        .catch(error => {
          console.log(error, 'pick reeor');
        });
      dispatch(fetchSuccess());
      //dispatch(fetchError());
    };
  },

  setPickUp: data => {
    return dispatch => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token'); //check
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios
        .post('pickups', data)
        .then(({ data }) => {
          const success = data.success;
          const message = data.message;
          if (success) {
            dispatch(setPickUpCreatedState({ message: message, success: success }));
          } else {
            dispatch(setPickUpCreatedState({ message: message, success: success }));
          }
          dispatch(fetchSuccess());
        })
        .catch(({ error }) => {
          dispatch(fetchError('Something Went Wrong'));
          console.log('Err');
        });
      dispatch(fetchSuccess());
    };
  },
};

export default PickUps;
