import { Checkbox, IconButton, TableCell, TableHead, TableRow, TableSortLabel, Tooltip, Typography,makeStyles } from '@material-ui/core';
import React from 'react'
import PropTypes from 'prop-types';
import CODIcon from './CODIcon';
import PrintIcon from '@material-ui/icons/Print';




//

const useStyles = makeStyles((theme) => ({

  cellFontStyle:{
    fontSize:'0.8rem',
    fontWeight: '800'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

  header:{
    
    backgroundColor:'#F7F8FA',
    
  },


  
}));

function EnhancedTableHead(props) {

    const classes = useStyles();


    const {onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort,headCells,excludeCell } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    const headCellsNew=headCells.filter((headCell)=>{ return (headCell.id !== excludeCell)});
  
  
    return (
      <TableHead className={classes.tableHeaderStyle}>
        <TableRow className={classes.header}>
          <TableCell padding="checkbox" >
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
              color={'primary'}
            />
          </TableCell>
          {headCellsNew.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
              className={classes.cellFontStyle}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
          { 
          
          excludeCell =='print' ? 
          <TableCell 
            key={'print'}
            align={'left'}
            padding={'default'}
          
          >
            <Typography className={classes.cellFontStyle}>ACTION</Typography>
          </TableCell> : 
          <TableCell 
            key={'empty'}
            align={'left'}
            padding={'default'}
            
            
            />
            
          }

         

         
        </TableRow>
      </TableHead>
    );

}

EnhancedTableHead.propTypes = {
        classes: PropTypes.object.isRequired,
        numSelected: PropTypes.number.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired,
        //headCells:PropTypes.array.isRequired,
};

    
   


export default EnhancedTableHead
