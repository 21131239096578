import {
  IconButton,
  Checkbox,
  makeStyles,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tabs,
  Typography,
  Box,
} from '@material-ui/core';

import Button from '@material-ui/core/Button';
import React, { useEffect, useState } from 'react';
import ExportIcon from 'custom_components/ExportIcon';
import InTransitTableHeader from 'custom_components/InTransitTableHeader';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import EnhancedTableToolbar from 'custom_components/EnhancedTableToolbar';
import { KeyboardDatePicker } from '@material-ui/pickers';
import ErrorToast from 'custom_components/Pages/common/ErrorToast';
import GridContainer from '@jumbo/components/GridContainer';
import statusChecker from './utils/statusChecker';
import PickUpTableHeader from './tableHeader/PickUpTableHeader';
import moment from 'moment';
import PickUps from 'services/pickups';
import AppConfigs from 'Utils/AppConfigs';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },

  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

  paginationContainer: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
  },

  toolContainer: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
    padding: 5,
  },
  exportButtonContainer: {
    display: 'flex',
    flex: 2,
    justifyContent: 'right',
    alignItems: 'center',
    paddingRight: 20,
    //backgroundColor:'green'
  },
  datePickerContainer: {
    display: 'flex',
    flex: 6,
    justifyContent: 'right',
    alignItems: 'center',
    paddingRight: 20,
    //backgroundColor:'yellow'
  },
  tabPanel: {
    '& > div': {
      paddingLeft: 0,
      paddingRight: 0,
      //backgroundColor:'blue'
    },
  },
}));

const headCells = [
  {
    //pickUpId, addedTime, pickupTimeSlot, status
    id: 'pickUpId',
    numeric: true,
    disablePadding: true,
    label: 'PICKUP ID',
    alignment: 'left',
  },
  {
    id: 'addedTime',
    numeric: false,
    disablePadding: false,
    label: 'ADDED TIME',
    alignment: 'left',
  },
  {
    id: 'pickupTimeSlot',
    numeric: false,
    disablePadding: false,
    label: 'PICKUP TIME SLOT',
    alignment: 'left',
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'STATUS',
    alignment: 'left',
  },
];

function getComparator(order, orderBy) {
  // console.log(order, orderBy);
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator(a, b, orderBy) {
  // console.log(a, b, orderBy);
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, comparator) {
  // console.log('sortStable', array);
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    console.log(a, b, 'sssss');
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  const a = stabilizedThis.map(el => el[0]);
  console.log(a);
  return a;
}

function DataTable({ toggleNewRequestUI, data, getNewData, startDate, endDate, filterData }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('pickUpId');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [fromDate, setFromDate] = useState(
    moment()
      .clone()
      .startOf('month'),
  );
  const [toDate, setToDate] = useState(
    moment()
      .clone()
      .endOf('month'),
  );
  const [isInitial, setIsInitial] = useState(false); //true
  const [maxPageNum, setMaxPageNum] = useState(0);

  function createData(pickUpId, addedTime, pickupTimeSlot, status) {
    return { pickUpId, addedTime, pickupTimeSlot, status };
  }
  // console.log(data, 'dataaaaaa');

  const dataBuilder = data => {
    if (data.length === 0) {
      return [];
    } else {
      return data.map(entry => {
        return createData(
          entry.id,
          entry.pickup_added_datetime,
          `${entry.pickup_from_datetime} To ${entry.pickup_to_datetime}`,
          statusChecker(entry.is_picked, entry.is_completed, entry.is_scheduled),
        );
      });
    }
  };

  useEffect(() => {
    startDate(moment(new Date(fromDate)).format('YYYY-MM-DD'));
    endDate(moment(new Date(toDate)).format('YYYY-MM-DD'));
  }, [fromDate, toDate]);

  const dataForTable = dataBuilder(data); //cashing data

  const rows = () => {
    return dataForTable;
  };

  const sendNewRequestWithDate = date => {
    setIsInitial(false);
    // dispatch(Orders.getDeliveredOrders(1, true, date));
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelectedRows = rows().map(n => n.pickup_id);
      setSelected(newSelectedRows);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    // console.log('property', property);
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const shouldGetNextDataBulk = newPage => {
    const nextPageNum = newPage;
    if (page < nextPageNum) {
      //moving forward
      const remaining = rows().length - nextPageNum * rowsPerPage;
      if (remaining <= rowsPerPage) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const handleChangePage = (event, newPage) => {
    // alert(`max->${maxPageNum} next->${newPage}`);
    if (shouldGetNextDataBulk(newPage) && maxPageNum !== newPage) {
      getNewData();
    }
    setPage(newPage);
    if (newPage > maxPageNum) {
      setMaxPageNum(newPage);
    }
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setMaxPageNum(0);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleDateChange = date => {
    sendNewRequestWithDate(`${date._d.getFullYear()}-${date._d.getMonth() + 1}-${date._d.getDate()}`);
    setSelectedDate(date);
  };

  const formatPickupTimeSlot = timeSlot => {
    if (!timeSlot) return null;
    const arr = timeSlot.split('To');
    return `${AppConfigs.formatDisplayDateAndTime(arr[0])} To ${AppConfigs.formatDisplayDateAndTime(arr[1])}`;
  };
  return (
    <div>
      <GridContainer>
        <Grid item xs={12} sm={12} style={{ padding: 0 }}>
          <div>
            <div className={classes.root}>
              <Paper className={classes.paper}>
                <EnhancedTableToolbar numSelected={selected.length} />
                <Box className={classes.toolContainer}>
                  <Box className={classes.exportButtonContainer} />
                  <Box className={classes.datePickerContainer}>
                    <KeyboardDatePicker
                      style={{ marginRight: 15 }}
                      disableToolbar
                      variant="inline"
                      format="DD/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="From Date"
                      value={fromDate}
                      onChange={e => {
                        setFromDate(e._d);
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                    <KeyboardDatePicker
                      style={{ marginRight: 15 }}
                      disableToolbar
                      variant="inline"
                      format="DD/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="To Date"
                      value={toDate}
                      minDate={fromDate}
                      onChange={e => {
                        setToDate(e._d);
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                    <Button
                      variant="contained"
                      style={{ marginRight: 15 }}
                      className={classes.newRequestButton}
                      onClick={() => {
                        dispatch(
                          PickUps.getPickUps(
                            true,
                            undefined,
                            moment(new Date(fromDate)).format('YYYY-MM-DD'),
                            moment(new Date(toDate)).format('YYYY-MM-DD'),
                          ),
                          setPage(0),
                        );
                      }}>
                      Filter
                    </Button>
                    <Button variant="contained" className={classes.newRequestButton} onClick={toggleNewRequestUI}>
                      New Request
                    </Button>
                  </Box>
                </Box>

                <React.Fragment>
                  <TableContainer>
                    <Table
                      className={classes.table}
                      aria-labelledby="tableTitle"
                      size={'medium'}
                      aria-label="enhanced table">
                      <PickUpTableHeader
                        classes={classes}
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows().length}
                        headCells={headCells}
                      />
                      <TableBody>
                        {stableSort(rows(), getComparator(order, orderBy))
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row, index) => {
                            const isItemSelected = isSelected(row.pickUpId);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.pickUpId}
                                selected={isItemSelected}
                                classes={{ hover: 'MuiTableRow-hover' }}>
                                <TableCell padding="checkbox" onClick={event => handleClick(event, row.pickUpId)}>
                                  <Checkbox
                                    checked={isItemSelected}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                    color={'primary'}
                                  />
                                </TableCell>
                                <TableCell
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                  onClick={event => handleClick(event, row.pickUpId)}>
                                  {row.pickUpId}
                                </TableCell>
                                <TableCell align="left" onClick={event => handleClick(event, row.addedTime)}>
                                  {AppConfigs.formatDisplayDateAndTime(row.addedTime)}
                                </TableCell>
                                <TableCell align="left" onClick={event => handleClick(event, row.pickupTimeSlot)}>
                                  {formatPickupTimeSlot(row.pickupTimeSlot)}
                                </TableCell>
                                <TableCell align="left" onClick={event => handleClick(event, row.status)}>
                                  {row.status}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {/* {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={12} />
                          </TableRow>
                        )} */}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box className={classes.paginationContainer}>
                    <Button variant="outlined" size={'small'} startIcon={<ExportIcon />} className={classes.exportIcon}>
                      Export
                    </Button>

                    <TablePagination
                      rowsPerPageOptions={[10, 25]}
                      component="div"
                      count={rows().length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </Box>
                </React.Fragment>
              </Paper>
            </div>
          </div>
        </Grid>
      </GridContainer>
    </div>
  );
}

export default DataTable;
