import CmtImage from '@coremat/CmtImage';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import { makeStyles, Typography,Box, IconButton, Button} from '@material-ui/core'
import BulkUploadIcon from 'custom_components/BulkUploadIcon';
import CancelIcon from '@material-ui/icons/Cancel';
import React, { useState } from 'react'
import DownloadIcon from 'custom_components/DownloadIcon';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { isNull } from 'lodash-es';
const rightPanelImage = '/images/welcomeImage.png';


const useStyles = makeStyles(theme => ({  
    
    /*[theme.breakpoints.up('md')]: { */

   profileInfoContainer:{


       backgroundColor:theme.palette.background.paper,
       borderRadius:10,
       paddingLeft:15,
       paddingRight:15,
       paddingTop:15,
       paddingBottom:5,
       display:'flex',
       flexDirection:'column',
       boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)',
       //display:'none',
       
    },

    form:{
        display:'flex',
        flex:1,
        //width:'50%',
        //paddingLeft:20,
        paddingRight:15,
        flexDirection:'column',
    },

    formTitle:{
        paddingBottom:20,
        paddingTop:15,
        display:'flex',
        justifyContent: "space-between",
        alignItems: 'center',
       


    },

    formInput:{
        marginTop:'5px',
        marginBottom:'5px',
    },

    panelContainer:{

        display:'flex',
        flexDirection:'row',
        width:'100%',
//85

    },

    leftPanel:{
        display:'flex',
        flex:1,
    },

    rightPanel:{
       
       
        display:'none',
        [theme.breakpoints.up('md')]: {
            visibility:'visible',
            display:'flex',
            flex:1,
            backgroundColor:'green',
            resize:'contained',
        }
    },


    

}));


function BulkUploadForm({closeEvent}) {

    const [validationErrors, setValidationErrors] = useState({
        requestedBy:'',
        approvedBy:'',
        remarks:'',
        fileTyle:'',
        hasError:'false'
    });

    const [file, setFile] = useState(null);
    const [requestedBy, setRequestedBy] = useState("");
    const [approvedBy, setApprovedBy] = useState("");
    const [remarks, setRemarks] = useState("");
    const classes = useStyles('default');



   
   const  onFileChange = (event)=>{
        console.log(event.target.files);
            setFile(event.target.files[0]);
    }



    const requestedByHandler=(event)=>{
            setRequestedBy(event.target.value);
    }

    const approvedByHandler=(event)=>{
            setApprovedBy(event.target.value);
    }

    const remarksHandler=(event)=>{
            setRemarks(event.target.value);
    }


    const textValidate=(value)=>{

        const pattern=/^[A-Za-z ]+$/;

        if(value.match(pattern)&&(value.length>0)) {
            return  true;
        }else{
            return false;
        };


    };

    const fileTypeValidate=(value)=>{

        if(value){
          

            console.log(value.name.split(".").pop());
        

            if(value.name.split(".").pop()==='xlsx') {
                return  true;
            }else{
                return false;
            };
        }else{

            return false;
        }



       
    };

    const uploadCreateHandler=(event)=>{

        //console.log("validationg");

        let localErrors= { 
            requestedBy:'',
            approvedBy:'',
            remarks:'',
            fileTyle:'',
            hasError:'false'
        };

        if(!textValidate(requestedBy.trim())){
            localErrors={...localErrors,requestedBy:'Invalied',hasError:'true'};
        }else{
            localErrors={...localErrors,requestedBy:'',};
        }

        if(!textValidate(approvedBy.trim())){
            localErrors={...localErrors,approvedBy:'Invalied',hasError:'true'};
        }else{
            localErrors={...localErrors,approvedBy:'',};
        }

        if(!textValidate(remarks.trim())){
            localErrors={...localErrors,remarks:'Invalied',hasError:'true'};
        }else{
            localErrors={...localErrors,remarks:'',};
        }

        if(!fileTypeValidate(file)){
            localErrors={...localErrors,fileTyle:'Invalied',hasError:'true'};
        }else{
            localErrors={...localErrors,fileTyle:'',};
        }



        
        if(localErrors.hasError==='false'){
           
            const formData = new FormData();
            formData.append("requestedBy", requestedBy);
            formData.append("aprrovedBy", approvedBy);
            formData.append("rematks", remarks);
            formData.append("file", file);
            //console.log(formData.getAll(1));
            //dispatch(User.onUpdateCustomeProfile({displayName,email,nic,contact,dob,gender,updatedUser}));
            
        }

        setValidationErrors(localErrors);


        





       // event.preventDefault();
       // console.log();
                //console.log(event.target.value);
                //inputvalidation
    };

    return (

        

            <Box className={classes.profileInfoContainer}>

                    <Box className={classes.formTitle}>
                        <Typography align={'justify'}>Bulk Upload</Typography>
                        <IconButton color="primary" aria-label="close" onClick={closeEvent}>
                            <CancelIcon></CancelIcon>
                        </IconButton>
                    </Box>
                         
                    <Box className={classes.panelContainer}>

                        <Box className={classes.leftPanel}>
                        <Box className={classes.form} >
                                <form>
                                        <AppTextInput
                                        className={classes.formInput}
                                        label={'Requested By'}
                                        required={true}
                                        type={'text'}
                                        value={requestedBy}
                                        onChange={requestedByHandler}
                                        >
                                        </AppTextInput>
                                        <Typography color={'primary'}>{validationErrors.requestedBy}</Typography>

                                        <AppTextInput
                                        className={classes.formInput}
                                        label={'Approved By'}
                                        required={true}
                                        type={'text'}
                                        value={approvedBy}
                                        onChange={approvedByHandler}
                                        >
                                        </AppTextInput>
                                        <Typography color={'primary'}>{validationErrors.approvedBy}</Typography>

                                        <AppTextInput
                                        className={classes.formInput}
                                        label={'Remarks'}
                                        required={true}
                                        type={'text'}
                                        value={remarks}
                                        onChange={remarksHandler}
                                        >
                                        </AppTextInput>
                                        <Typography color={'primary'}>{validationErrors.remarks}</Typography>

                                        <input  type={'file'} multiple accept=".xlsx" className={classes.formInput} id="actual-btn" hidden={true} onChange={onFileChange}></input>
                                        
                                        <Button
                                            variant="outlined"
                                            size={'small'}
                                            startIcon={<FileCopyIcon />}
                                            component='label'
                                           
                                            htmlFor="actual-btn"
                                        >
                                        Select File    
                                        </Button>
                                        <Typography color={'primary'}>{validationErrors.fileTyle}</Typography>

                                        <Button
                                            variant="outlined"
                                            size={'small'}
                                            startIcon={<FileCopyIcon />}
                                            onClick={uploadCreateHandler}
                                           
                                        >
                                        Create    
                                        </Button>
                                
                                </form>
                            </Box>
                        </Box>
                        <Box className={classes.rightPanel}>
                                <CmtImage src={'/images/welcomeImage.png'}></CmtImage>

                        </Box>
                    </Box>

                    

            </Box>
                    


           
        
    )
}

export default BulkUploadForm
