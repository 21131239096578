export const SET_PICKUPS_IN_STORE = 'SET_PICKUPS_IN_STORE'; //move to action Types
export const SET_PICKUP_CREATE_STATE = 'SET_PICKUP_CREATE_STATE';
export const SET_PICKUP_CREATE_STATUS_RESET = 'SET_PICKUP_CREATE_STATUS_RESET';

export function setPickupsInStore(payload) {
  return {
    type: SET_PICKUPS_IN_STORE,
    payload: payload,
  };
}

export function setPickUpCreatedState(payload) {
  return {
    type: SET_PICKUP_CREATE_STATE,
    payload: payload,
  };
}

export function resetPickUpCreateState() {
  return {
    type: SET_PICKUP_CREATE_STATUS_RESET,
  };
}
