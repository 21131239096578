import { fetchStart, fetchSuccess, fetchError } from '../../redux/actions';
import { setReportData } from 'redux/actions/Reports';
import axios from '../auth/jwt/config';
import moment from 'moment';

const reports = {
  getReportData(ids) {
    return dispatch => {
      dispatch(fetchStart());

      const token = localStorage.getItem('token'); //check
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      axios
        .get(`last-status-report?tracking_numbers=${ids.toString()}`)
        .then(({ data }) => {
          console.log('Created Orders', data);
          if (data.status == 'success') {
            const result = data.data;
            dispatch(fetchSuccess());
            console.log(result);
            dispatch(setReportData(result));
          } else {
            console.log('Created Oders Error');
            dispatch(fetchError('ERRRRRRRRRR'));
          } //
        })
        .catch(function(error) {
          console.log(error);
          dispatch(fetchError(error.message));
        });
    };
  },
  openLastStatusReportUrl(tracking_numbers) {
    return dispatch => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token'); //check
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      axios
        .get(`last-status-report?tracking_numbers=${tracking_numbers.toString()}`, {
          responseType: 'blob',
        })
        .then(data => {
          if (data.status == 200) {
            const result = data.data;
            dispatch(fetchSuccess());
            //dispatch(setBulkUploads(result,1));
            const downloadUrl = window.URL.createObjectURL(new Blob([result]));

            const link = document.createElement('a');

            link.href = downloadUrl;

            link.setAttribute('download', 'last_status_report.csv'); //any other extension

            document.body.appendChild(link);

            link.click();

            link.remove();
          } else {
            const data = data.data;
            dispatch(fetchError('Error'));
          } //
        })
        .catch(function(error) {
          console.log(error);
          dispatch(fetchError(error.message));
        });
    };
  },
  openReportUrl(type, start_date, end_date) {
    return dispatch => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token'); //check
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      axios
        .get(
          `/date-range-tracking-report?type=${type}&start_date=${moment(start_date).format('DD/MM/YYYY')}&end_date=${moment(
            end_date,
          ).format('DD/MM/YYYY')}`,
          {
            responseType: 'blob',
          },
        )
        .then(data => {
          if (data.status == 200) {
            const result = data.data;
            dispatch(fetchSuccess());
            //dispatch(setBulkUploads(result,1));
            const downloadUrl = window.URL.createObjectURL(new Blob([result]));

            const link = document.createElement('a');

            link.href = downloadUrl;

            link.setAttribute('download', type + '.csv'); //any other extension

            document.body.appendChild(link);

            link.click();

            link.remove();
          } else {
            const data = data.data;
            dispatch(fetchError('Error'));
          } //
        })
        .catch(function(error) {
          console.log(error);
          dispatch(fetchError(error.message));
        });
    };
  },
  openCreatedOrdersReportUrl(start_date, end_date, type) {
    return dispatch => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token'); //check
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      axios
        .get(
          `/created-orders-export?start_date=${moment(start_date).format('DD/MM/YYYY')}&end_date=${moment(end_date).format(
            'DD/MM/YYYY',
          )}&type=${type}`,
          {
            responseType: 'blob',
          },
        )
        .then(data => {
          if (data.status == 200) {
            const result = data.data;
            dispatch(fetchSuccess());
            //dispatch(setBulkUploads(result,1));
            const downloadUrl = window.URL.createObjectURL(new Blob([result]));

            const link = document.createElement('a');

            link.href = downloadUrl;

            link.setAttribute('download', 'created_orders.csv'); //any other extension

            document.body.appendChild(link);

            link.click();

            link.remove();
          } else {
            const data = data.data;
            dispatch(fetchError('Error'));
          } //
        })
        .catch(function(error) {
          console.log(error);
          dispatch(fetchError(error.message));
        });
    };
  },
  openDeliveredOrdersReportUrl(start_date, end_date, status) {
    return dispatch => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token'); //check
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      let url = `/delivered-orders-export?start_date=${moment(start_date).format('DD/MM/YYYY')}&end_date=${moment(end_date).format(
        'DD/MM/YYYY',
      )}`;

      if (status) {
        url += `&type=${status}`;
      }

      axios
        .get(url)
        .then(data => {
          if (data.status == 200) {
            const result = data.data;
            dispatch(fetchSuccess());
            //dispatch(setBulkUploads(result,1));
            const downloadUrl = window.URL.createObjectURL(new Blob([result]));

            const link = document.createElement('a');

            link.href = downloadUrl;

            link.setAttribute('download', 'delivered_orders.csv'); //any other extension

            document.body.appendChild(link);

            link.click();

            link.remove();
          } else {
            const data = data.data;
            dispatch(fetchError('Error'));
          } //
        })
        .catch(function(error) {
          console.log(error);
          dispatch(fetchError(error.message));
        });
    };
  },
  openInTransitOrdersReportUrl(fromDate, toDate, type) {
    return dispatch => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token'); //check
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      let url = `/in-transit-orders-export`;

      if (fromDate) {
        url += `?from_date=${fromDate}`;
      }

      if (toDate) {
        url += `&to_date=${toDate}`;
      }

      if (type) {
        url += `&type=${type}`;
      }

      axios
        .get(url, {
          responseType: 'blob',
        })
        .then(data => {
          if (data.status == 200) {
            const result = data.data;
            dispatch(fetchSuccess());
            //dispatch(setBulkUploads(result,1));
            const downloadUrl = window.URL.createObjectURL(new Blob([result]));

            const link = document.createElement('a');

            link.href = downloadUrl;

            link.setAttribute('download', 'in_transit_orders.csv'); //any other extension

            document.body.appendChild(link);

            link.click();

            link.remove();
          } else {
            const data = data.data;
            dispatch(fetchError('Error'));
          } //
        })
        .catch(function(error) {
          console.log(error);
          dispatch(fetchError(error.message));
        });
    };
  },
  openOutForDeliveryOrdersReportUrl(fromDate, toDate) {
    return dispatch => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token'); //check
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      let url = `/out-for-delivery-orders-export`;

      if (fromDate) {
        url += `?from_date=${fromDate}`;
      }

      if (toDate) {
        url += `&to_date=${toDate}`;
      }

      axios
        .get(url, {
          responseType: 'blob',
        })
        .then(data => {
          if (data.status == 200) {
            const result = data.data;
            dispatch(fetchSuccess());
            //dispatch(setBulkUploads(result,1));
            const downloadUrl = window.URL.createObjectURL(new Blob([result]));

            const link = document.createElement('a');

            link.href = downloadUrl;

            link.setAttribute('download', 'out_for_delivery_orders.csv'); //any other extension

            document.body.appendChild(link);

            link.click();

            link.remove();
          } else {
            const data = data.data;
            dispatch(fetchError('Error'));
          } //
        })
        .catch(function(error) {
          console.log(error);
          dispatch(fetchError(error.message));
        });
    };
  },
  openAllOrdersReportUrl(query) {
    return dispatch => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token'); //check
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      axios
        .get(`/all-orders-export?query=${query}`, {
          responseType: 'blob',
        })
        .then(data => {
          if (data.status == 200) {
            const result = data.data;
            dispatch(fetchSuccess());
            const downloadUrl = window.URL.createObjectURL(new Blob([result]));

            const link = document.createElement('a');

            link.href = downloadUrl;

            link.setAttribute('download', 'search_export.csv'); //any other extension

            document.body.appendChild(link);

            link.click();

            link.remove();
          } else {
            const data = data.data;
            dispatch(fetchError('Error'));
          } //
        })
        .catch(function(error) {
          console.log(error);
          dispatch(fetchError(error.message));
        });
    };
  },
  fmScanOrdersReportUrl(start_date, end_date) {
    return dispatch => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token'); //check
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      axios
        .get(
          `/fm-scan-orders-export?start_date=${moment(start_date, 'DD-MM-YYYY').format('DD/MM/YYYY')}&end_date=${moment(end_date, 'DD-MM-YYYY').format('DD/MM/YYYY')}`,
          {
            responseType: 'blob',
          },
        )
        .then(data => {
          if (data.status == 200) {
            const result = data.data;
            dispatch(fetchSuccess());
            const downloadUrl = window.URL.createObjectURL(new Blob([result]));

            const link = document.createElement('a');

            link.href = downloadUrl;

            link.setAttribute('download', 'fm_scan_orders.csv'); //any other extension

            document.body.appendChild(link);

            link.click();

            link.remove();
          } else {
            const data = data.data;
            dispatch(fetchError('Error'));
          } //
        })
        .catch(function(error) {
          console.log(error);
          dispatch(fetchError(error.message));
        });
    };
  },
  searchExportReport(id) {
    return dispatch => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token'); //check
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      
      let url = `/search-export?query=${encodeURIComponent(id)}`;
  
      axios
        .get(url, {
          responseType: 'blob',
        })
        .then(data => {
          if (data.status == 200) {
            const result = data.data;
            dispatch(fetchSuccess());
            const downloadUrl = window.URL.createObjectURL(new Blob([result]));

            const link = document.createElement('a');

            link.href = downloadUrl;

            link.setAttribute('download', 'search_report_export.csv');

            document.body.appendChild(link);

            link.click();

            link.remove();
          } else {
            const data = data.data;
            dispatch(fetchError('Error'));
          }
        })
        .catch(function(error) {
          console.log(error);
          dispatch(fetchError(error.message));
        });
    };
  },
};

export default reports;
