import React, { useState, useEffect } from 'react';
import GridContainer from '../../../@jumbo/components/GridContainer';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import Box from '@material-ui/core/Box';
import IntlMessages from '../../../@jumbo/utils/IntlMessages';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import AppSelectBox from '@jumbo/components/Common/formElements/AppSelectBox';

import { Button, IconButton, TextField, Typography } from '@material-ui/core';
import AppTheme from 'theme/AppTheme';
import Paper from '@material-ui/core/Paper';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import { AccountBalanceWalletOutlined, Phone, SearchOutlined } from '@material-ui/icons';
import Orders from 'services/orders';
import { useDispatch, useSelector } from 'react-redux';
import AppAutocomplete from '@jumbo/components/Common/formElements/AppAutocomplete';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    //backgroundColor:'green',
  },

  root: {
    width: '100%',
  },
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(5),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  sectionTitle: {
    textDecorationLine: 'underline',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(5),
  },
  sectionSubTitle: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(5),
  },
}));

const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'Orders', isActive: true },
];

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const AddOrder = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const cities = useSelector(state => state.cities.cities);
  const history = useHistory();

  const [is_default_address, setIsDefaultAddress] = useState(true);
  const [is_cash_on_delivery, setIsCashOnDelivery] = useState(true);
  const [is_preallocated_tracking_number, setIsPreAllocatedTrackingNumber] = useState(false);
  const [preAllocatedTrackingNumber, setPreAllocatedTrackingNumber] = useState('');

  const [shipperName, setShipperName] = useState('');
  const [shipperAddressLine1, setShipperAddressLine1] = useState('');
  const [shipperAddressLine2, setShipperAddressLine2] = useState('');
  const [shipperAddressLine3, setShipperAddressLine3] = useState('');
  const [shipperAddressLine4, setShipperAddressLine4] = useState('');
  const [shipperAddressCity, setShipperAddressCity] = useState('');
  const [shipperContact1, setShipperContact1] = useState('');
  const [shipperContact2, setShipperContact2] = useState('');

  const [addressTitle, setAddressTitle] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [addressLine3, setAddressLine3] = useState('');
  const [addressLine4, setAddressLine4] = useState('');
  const [addressCity, setAddressCity] = useState('');
  const [contact1, setContact1] = useState('');
  const [contact2, setContact2] = useState('');

  const [reference, setReference] = useState('');
  const [pieces, setPieces] = useState('1');

  const [weight, setWeight] = useState('');
  const [length, setLength] = useState('');
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');

  const [totalWeight, setTotalWeight] = useState('0');
  const [codAmount, setCodAmount] = useState('');
  const [description, setDescription] = useState('');

  const [errors, setErrors] = useState({
    merchantName: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    city: '',
    name: '',
    contactNumber: '',
    time: '',
    waybillCount: '',
    maxWeight: '',
    dateFrom: '',
    dateTo: '',
    hasError: 'false',
  });

  const submitHandler = event => {
    event.preventDefault();

    const newOrder = {
      use_default_from_address: is_default_address,
      reference: reference,
      citypak_tracking: preAllocatedTrackingNumber,
      from_name: shipperName,
      from_address_line_1: shipperAddressLine1,
      from_address_line_2: shipperAddressLine2,
      from_address_line_3: shipperAddressLine3,
      from_address_line_4: shipperAddressLine4,
      from_address_city_id: shipperAddressCity,
      from_contact_name: shipperName,
      from_contact_1: shipperContact1,
      from_contact_2: shipperContact2,

      to_name: addressTitle,
      to_address_line_1: addressLine1,
      to_address_line_2: addressLine2,
      to_address_line_3: addressLine3,
      to_address_line_4: addressLine4,
      to_address_city_id: addressCity,
      to_contact_name: addressTitle,
      to_contact_1: contact1,
      to_contact_2: contact2,

      to_nic: '',
      description: description,
      weight_g: weight * 1000,

      assumed_width_cm: width,
      assumed_height_cm: height,
      assumed_length_cm: length,
      cash_on_delivery_amount: codAmount * 1,
      number_of_pieces: pieces,
    };

    console.log('Order', newOrder);

    dispatch(Orders.createSingleOrder(newOrder, history));
  };

  // calculation of chargeable weight
  const calculateTotalWeight = () => {
    const actualWeight = weight || 0;
    const chargeableWeight = parseFloat((((length || 0) * (width || 0) * (height || 0)) / 5000).toFixed(3));
    actualWeight <= chargeableWeight ? setTotalWeight(chargeableWeight) : setTotalWeight(actualWeight);
  };

  //this trigger when user inputs weights and dimensions
  useEffect(() => {
    calculateTotalWeight();
  }, [weight, length, width, height]);

  return (
    <PageContainer heading={'Create Single Package'}>
      <form onSubmit={submitHandler}>
        <Grid item md={6}>
          <Paper className={classes.paper}>
            <Typography variant="h3" className={classes.sectionTitle}>
              Shipper Details
            </Typography>
            <AppSelectBox
              data={[
                { labelKey: 'Default', valueKey: true },
                { labelKey: 'Custom', valueKey: false },
              ]}
              valueKey={'valueKey'}
              labelKey={'labelKey'}
              label={''}
              value={is_default_address}
              onChange={event => {
                setIsDefaultAddress(event.target.value);
              }}
              name={'shipper'}
              required={true}
            />
            {/* CUSTOM SHIPPER - START */}
            {is_default_address == false && (
              <div>
                <AppTextInput
                  className={classes.formInput}
                  label={'Shipper Name'}
                  required={true}
                  type={'text'}
                  onChange={event => {
                    setShipperName(event.target.value);
                  }}
                  value={shipperName}
                />
                <Typography color={'primary'}>{errors.merchantName}</Typography>

                <AppTextInput
                  className={classes.formInput}
                  label={'Address Line 1'}
                  required={true}
                  type={'text'}
                  value={shipperAddressLine1}
                  onChange={event => {
                    setShipperAddressLine1(event.target.value);
                  }}
                />
                <Typography color={'primary'}>{errors.addressLine1}</Typography>

                <AppTextInput
                  className={classes.formInput}
                  label={'Address Line 2'}
                  type={'text'}
                  value={shipperAddressLine2}
                  onChange={event => {
                    setShipperAddressLine2(event.target.value);
                  }}
                />
                <Typography color={'primary'}>{errors.addressLine2}</Typography>

                <AppTextInput
                  className={classes.formInput}
                  label={'Address Line 3'}
                  type={'text'}
                  value={shipperAddressLine3}
                  onChange={event => {
                    setShipperAddressLine3(event.target.value);
                  }}
                />
                <Typography color={'primary'}>{errors.addressLine3}</Typography>

                <AppTextInput
                  className={classes.formInput}
                  label={'Address Line 4'}
                  type={'text'}
                  value={shipperAddressLine4}
                  onChange={event => {
                    setShipperAddressLine4(event.target.value);
                  }}
                />
                <Typography color={'primary'}>{errors.addressLine3}</Typography>

                <AppAutocomplete
                  id="autocomplete_shipper_city"
                  label={'Address City'}
                  options={cities}
                  variant="standard"
                  required={true}
                  onChange={(event, value) => {
                    if (value != null) {
                      setShipperAddressCity(value.id);
                    } else {
                      setShipperAddressCity('');
                    }
                  }}
                  getOptionLabel={option => option.name_en}
                  renderInput={params => <TextField {...params} label="Address City" />}
                />

                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <AppTextInput
                      className={classes.formInput}
                      label={'Contact No 1'}
                      required={true}
                      margin="dense"
                      type={'text'}
                      value={shipperContact1}
                      InputProps={{
                        startAdornment: <Phone />,
                      }}
                      onChange={event => {
                        setShipperContact1(event.target.value);
                      }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <AppTextInput
                      className={classes.formInput}
                      label={'Contact No 2'}
                      margin="dense"
                      required={false}
                      type={'text'}
                      value={shipperContact2}
                      InputProps={{
                        startAdornment: <Phone />,
                      }}
                      onChange={event => {
                        setShipperContact2(event.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            )}
            {/* CUSTOM SHIPPER - END */}

            {/* CONSIGNEE - START */}
            <Typography variant="h4" className={classes.sectionSubTitle}>
              Consignee Details
            </Typography>

            <div>
              <AppTextInput
                className={classes.formInput}
                label={'Name'}
                required={true}
                type={'text'}
                onChange={event => {
                  setAddressTitle(event.target.value);
                }}
                value={addressTitle}
              />
              <Typography color={'primary'}>{errors.merchantName}</Typography>

              <AppTextInput
                className={classes.formInput}
                label={'Address Line 1'}
                required={true}
                type={'text'}
                value={addressLine1}
                onChange={event => {
                  setAddressLine1(event.target.value);
                }}
              />
              <Typography color={'primary'}>{errors.addressLine1}</Typography>

              <AppTextInput
                className={classes.formInput}
                label={'Address Line 2'}
                type={'text'}
                value={addressLine2}
                onChange={event => {
                  setAddressLine2(event.target.value);
                }}
              />
              <Typography color={'primary'}>{errors.addressLine2}</Typography>

              <AppTextInput
                className={classes.formInput}
                label={'Address Line 3'}
                type={'text'}
                value={addressLine3}
                onChange={event => {
                  setAddressLine3(event.target.value);
                }}
              />
              <Typography color={'primary'}>{errors.addressLine3}</Typography>

              <AppTextInput
                className={classes.formInput}
                label={'Address Line 4'}
                type={'text'}
                value={addressLine4}
                onChange={event => {
                  setAddressLine4(event.target.value);
                }}
              />
              <Typography color={'primary'}>{errors.addressLine3}</Typography>

              <AppAutocomplete
                id="autocomplete_city"
                label={'Address City'}
                options={cities}
                variant="standard"
                required={true}
                onChange={(event, value) => {
                  if (value) {
                    setAddressCity(value.id);
                    console.log(value.id);
                  } else {
                    setAddressCity('');
                  }
                }}
                getOptionLabel={option => option.name_en}
                renderInput={params => <TextField {...params} label="Address City" required={true} />}
              />
            </div>

            <Grid container spacing={4}>
              <Grid item xs={6}>
                <AppTextInput
                  className={classes.formInput}
                  label={'Contact No 1'}
                  required={true}
                  margin="dense"
                  type={'text'}
                  value={contact1}
                  InputProps={{
                    startAdornment: <Phone />,
                  }}
                  onChange={event => {
                    setContact1(event.target.value);
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <AppTextInput
                  className={classes.formInput}
                  label={'Contact No 2'}
                  margin="dense"
                  required={false}
                  type={'text'}
                  value={contact2}
                  InputProps={{
                    startAdornment: <Phone />,
                  }}
                  onChange={event => {
                    setContact2(event.target.value);
                  }}
                />
              </Grid>
            </Grid>
            {/* CONSIGNEE - END */}

            {/* PACKAGE DETAILS - START */}
            <Typography variant="h3" className={classes.sectionTitle}>
              Package Details
            </Typography>

            <Grid container spacing={4}>
              <Grid item xs={12}>
                <AppTextInput
                  className={classes.formInput}
                  label={'Order Reference'}
                  type={'text'}
                  required={true}
                  onChange={event => {
                    setReference(event.target.value);
                  }}
                  value={reference}
                />
                <Typography color={'primary'}>{errors.merchantName}</Typography>

                <AppTextInput
                  className={classes.formInput}
                  label={'Number Of Pieces'}
                  required={true}
                  type={'number'}
                  value={pieces}
                  onChange={event => {
                    setPieces(event.target.value);
                  }}
                />
                <Typography color={'primary'}>{errors.addressLine1}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography color={'textPrimary'}>Weight</Typography>
                    <AppTextInput
                      className={classes.formInput}
                      label={'Kg'}
                      required={true}
                      type={'number'}
                      value={weight}
                      onChange={event => {
                        setWeight(event.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Typography color={'textPrimary'}>Dimension (cm)</Typography>
                <Grid container>
                  <Grid item xs={4}>
                    <AppTextInput
                      className={classes.formInput}
                      label={'L'}
                      type={'number'}
                      value={length}
                      onChange={event => {
                        setLength(event.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <AppTextInput
                      className={classes.formInput}
                      label={'W'}
                      type={'number'}
                      value={width}
                      onChange={event => {
                        setWidth(event.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <AppTextInput
                      className={classes.formInput}
                      label={'H'}
                      type={'number'}
                      value={height}
                      onChange={event => {
                        setHeight(event.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <AppTextInput
                  className={classes.formInput}
                  label={'Total Weight (Kg)'}
                  margin="dense"
                  type={'text'}
                  value={totalWeight}
                  disabled
                  InputProps={{
                    readOnly: true,
                  }}
                  onChange={event => {
                    setTotalWeight(event.target.value);
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={6}>
                    <AppSelectBox
                      data={[
                        { labelKey: 'COD', valueKey: true },
                        { labelKey: 'Prepaid', valueKey: false },
                      ]}
                      valueKey={'valueKey'}
                      labelKey={'labelKey'}
                      label={'Payment Type'}
                      value={is_cash_on_delivery}
                      onChange={event => {
                        setIsCashOnDelivery(event.target.value);
                        if (event.target.value == false) {
                          setCodAmount('');
                        }
                      }}
                      name={'payment_type'}
                      required={true}
                      className={classes.formInput}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <AppTextInput
                  style={{ marginTop: 0 }}
                  className={classes.formInput}
                  label={'Cash On Delivery Amount (Rs.)'}
                  margin="dense"
                  type={'text'}
                  value={codAmount}
                  disabled={!is_cash_on_delivery}
                  onChange={event => {
                    setCodAmount(event.target.value);
                  }}
                  required={is_cash_on_delivery}
                />
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <AppSelectBox
                      data={[
                        { labelKey: 'NO', valueKey: false },
                        { labelKey: 'YES', valueKey: true },
                      ]}
                      valueKey={'valueKey'}
                      labelKey={'labelKey'}
                      label={'Pre-allocated tracking number'}
                      value={is_preallocated_tracking_number}
                      onChange={event => {
                        setIsPreAllocatedTrackingNumber(event.target.value);
                        if (event.target.value == false) {
                          setPreAllocatedTrackingNumber('');
                        }
                      }}
                      name={'is_pre_allocated'}
                      required={true}
                      className={classes.formInput}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <AppTextInput
                  style={{ marginTop: 0 }}
                  className={classes.formInput}
                  label={'Citypak Tracking Number'}
                  margin="dense"
                  type={'text'}
                  value={preAllocatedTrackingNumber}
                  disabled={!is_preallocated_tracking_number}
                  onChange={event => {
                    setPreAllocatedTrackingNumber(event.target.value);
                  }}
                  required={is_preallocated_tracking_number}
                />
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={12}>
                <AppTextInput
                  className={classes.formInput}
                  label={'Product Description'}
                  margin="dense"
                  type={'text'}
                  value={description}
                  onChange={event => {
                    setDescription(event.target.value);
                  }}
                />
              </Grid>

              <div
                style={{
                  marginTop: 25,
                  marginBottom: 25,
                }}>
                <Button type="submit" variant="outlined" size={'small'} name="submit">
                  SUBMIT
                </Button>
              </div>
            </Grid>
          </Paper>
        </Grid>
      </form>
    </PageContainer>
  );
};

export default AddOrder;
