import React from 'react';

function getFileTypeInfo(fileExtension) {
  let icon, text;

  switch (fileExtension) {
    case 'jpg':
    case 'jpeg':
    case 'png':
      icon = '/images/view-attachment.png';
      text = 'View Image';
      break;
    case 'pdf':
      icon = '/images/view-pdf.png';
      text = 'View PDF';
      break;
    case 'xls':
    case 'xlsx':
      icon = '/images/download-excel.png';
      text = 'Download Excel';
      break;
    default:
      icon = '';
      text = 'View File';
  }

  return { icon, text };
}

const AttachmentItem = ({ attachmentUrl, onClick }) => {
  const fileExtension = attachmentUrl
    .split('.')
    .pop()
    .toLowerCase();
  const { icon, text } = getFileTypeInfo(fileExtension);

  return (
    <div
      onClick={() => onClick(fileExtension)}
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignContent: 'center',
        marginTop: '12px',
        cursor: 'pointer',
      }}>
      <img src={icon} width={'auto'} style={{ marginRight: '8px', objectFit: 'contain' }} />
      {text}
    </div>
  );
};

export default AttachmentItem;
