import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Box, ThemeProvider, Typography } from '@material-ui/core';
import CmtImage from '@coremat/CmtImage';
import dashboardTheme from './dashboardTheme';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  cardRoot: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: '4px',
    padding: 20,
    cursor: 'pointer',
    [theme.breakpoints.down('lg')]: {
      padding: 14,
    },
    [theme.breakpoints.down('md')]: {
      padding: 8,
    },
  },
  centerContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // width: '100%',
  },
  iconContainer: {
    width: '100%',
    height: '65px',
    borderRadius: '4px',
    minWidth: '80px',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      minWidth: '60px',
      height: '50px',
      '& img': {
        width: '30px',
        height: 'auto',
      },
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      minWidth: '60px',
      height: '40px',
      '& img': {
        width: '30px',
        height: 'auto',
      },
    },
  },
  hr: {
    border: '2px solid #FECACA',
    borderRadius: '5px',
    width: '50%',
    margin: '4px 0',
  },
  firstTier: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    alignItems: 'center',
    marginLeft: 12,
  },
}));

const OverviewCard = ({
  title,
  subTitle,
  children,
  icon,
  color,
  titleProps,
  subTitleProps,
  bgColor,
  isHorizontal,
  isFirstTier,
  fromDate,
  toDate,
  status,
  statusType,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <ThemeProvider theme={dashboardTheme}>
      <div
        className={`${classes.cardRoot}`}
        style={{ justifyContent: 'start' }}
        onClick={() => {
          const queryParams = new URLSearchParams();
          if (statusType) {
            queryParams.append('status', statusType);
          }
          if (fromDate) {
            queryParams.append('fromDate', moment(new Date(fromDate)).format('DD-MM-YYYY'));
          }
          if (toDate) {
            queryParams.append('toDate', moment(new Date(toDate)).format('DD-MM-YYYY'));
          }

          const queryString = queryParams.toString();
          history.push({
            pathname: '/orders-new',
            state: { searchTerm: queryString },
          });
        }}>
        {isHorizontal ? (
          <>
            {isFirstTier ? (
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start' }}>
                <Box className={classes.centerContent} style={{ flexDirection: 'row', width: '100%' }} color={color}>
                  <Box
                    className={`${classes.centerContent} ${classes.iconContainer}`}
                    style={{ backgroundColor: bgColor, flex: 1, marginRight: 12, width: '100px' }}>
                    <CmtImage src={icon} alt={title} />
                  </Box>
                  <div>
                    <Box className={classes.firstTier}>
                      <Typography variant="h4" style={{ marginRight: 8 }}>
                        {title}
                      </Typography>
                      <Typography variant="h6" style={{ color: '#64748B' }}>
                        {subTitle}
                      </Typography>
                    </Box>
                    <hr className={classes.hr} style={{ marginLeft: 12, width: '30%' }} />
                  </div>
                </Box>
              </div>
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start' }}>
                <Box className={classes.centerContent} style={{ flexDirection: 'row', width: '100%' }} color={color}>
                  <Box
                    className={`${classes.centerContent} ${classes.iconContainer}`}
                    style={{ backgroundColor: bgColor, flex: 1 }}>
                    <CmtImage src={icon} alt={title} />
                  </Box>
                  <Box className={classes.centerContent} style={{ alignItems: 'start', marginLeft: 12, width: '100%' }}>
                    <Typography variant="h4" style={{ marginRight: 8 }}>
                      {' '}
                      {title}
                    </Typography>

                    <hr className={classes.hr} />
                    <Typography variant="h6" style={{ color: '#64748B' }}>
                      {' '}
                      {subTitle}
                    </Typography>
                  </Box>
                </Box>
              </div>
            )}
          </>
        ) : (
          <div className={classes.centerContent} style={{ width: '100%' }} color={color}>
            <Box
              style={{ backgroundColor: bgColor, width: '100%' }}
              className={`${classes.centerContent} ${classes.iconContainer}`}>
              <CmtImage src={icon} alt={title} />
            </Box>
            <Box className={classes.centerContent} style={{ alignItems: 'center' }}>
              <Typography variant="h4" style={{ marginRight: 8 }}>
                {title}
              </Typography>
              <hr className={classes.hr} />
              <Typography variant="h6" style={{ color: '#64748B' }}>
                {subTitle}
              </Typography>
            </Box>
          </div>
        )}
        {children}
      </div>
    </ThemeProvider>
  );
};

export default OverviewCard;
