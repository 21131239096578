import { useSelector } from 'react-redux';
import { setAuthUser } from 'redux/actions/Auth';
import { fetchError, fetchStart, fetchSuccess } from '../../redux/actions';
import { showSnackBar } from 'redux/actions/SnackBar';

import axios from '../auth/jwt/config';

const Account = {
  saveShipper: ({
    shipperName,
    shipperAddressLine1,
    shipperAddressLine2,
    shipperAddressLine3,
    shipperAddressLine4,
    shipperAddressCity,
    shipperContact1,
    shipperContact2,
  }) => {
    return dispatch => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token'); //check
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      //'display_name', 'nic', 'contact_number', 'date_of_birth', 'gender', 'email'

      const data = {
        address_title: shipperName,
        address_line_1: shipperAddressLine1,
        address_line_2: shipperAddressLine2,
        address_line_3: shipperAddressLine3,
        address_line_4: shipperAddressLine4,
        address_city_id: shipperAddressCity,
        contact_1: shipperContact1,
        contact_2: shipperContact2,
      };

      axios
        .post('shipping-address', data)
        .then(({ data }) => {
          if (data.success) {
            const result = data.data;
            dispatch(fetchSuccess(result.message));
            //

            //console.log(result,"here is result");
          } else {
            dispatch(fetchError('Error'));
          }
        })
        .catch(function(error) {
          console.log(error);
          dispatch(fetchError(error.message));
        });
    };
  },
  getShipper: async () => {
    const token = localStorage.getItem('token'); //check
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

    try {
      const { data } = await axios.get('shipping-address');
      return data.data;
    } catch (e) {}
  },
};

export default Account;
