import React, { useEffect, useState } from 'react';
import BaseModal from './BaseModal';
import { Box, Button, makeStyles } from '@material-ui/core';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import { useDispatch } from 'react-redux';
import Tickets from 'services/tickets';
import RemoveableTag from '../RemoveableTag';
import xss from 'xss';

const useStyles = makeStyles(theme => ({
  formInput: {
    marginTop: '5px',
    marginBottom: '5px',
    paddingTop: 10,
    paddingBottom: 10,
    marginRight: 10,
    width: '100%',
    backgroundColor: '#fff',
  },
  attachment: {
    justifyContent: 'start',
    paddingLeft: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  removeAttachment: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

function TicketModal({ open, onClose, ticketStatus }) {
  const classes = useStyles();

  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [trackingNo, setTrackingNo] = useState('');
  const [file, setFile] = useState(null);
  const [list, setList] = useState([]);
  const [newList, setNewList] = useState([]);

  useEffect(() => {
    setNewList(list.map(obj => obj.number));
  }, [list]);

  const dispatch = useDispatch();

  const titleHandler = event => {
    setTitle(event.target.value);
  };

  const messageHandler = event => {
    setMessage(event.target.value);
  };

  const trackingNoHandler = event => {
    setTrackingNo(event.target.value);
  };

  const handleFileChange = e => {
    setFile(e.target.files[0]);
  };

  const removeAttachment = () => {
    setFile(null);

    const fileInput = document.getElementById('file-input-attachment');
    if (fileInput) {
      fileInput.value = '';
    }
  };

  const handleAddTrackingNo = number => {
    const newTrackingNo = {
      id: Math.random(),
      number: number,
    };

    setList([...list, newTrackingNo]);
    setTrackingNo('');
  };

  const handleDeleteTrackingNo = id => {
    const newList = list.filter(item => item.id !== id);
    setList(newList);
  };

  const sanitizeInput = value => {
    return xss(value);
  };

  const sanitizeTrackingNumbers = trackingNumbers => {
    return trackingNumbers.map(number => sanitizeInput(number));
  };

  const submitHandler = event => {
    event.preventDefault();

    const sanitizedTitle = sanitizeInput(title.trim());
    const sanitizedMessage = sanitizeInput(message.trim());
    const sanitizedTrackingNumbers = sanitizeTrackingNumbers(newList);

    let localErrors = {
      title: '',
      message: '',
      trackingNumbers: [],
      hasError: 'false',
    };

    if (!sanitizedTitle) {
      localErrors = { ...localErrors, title: 'Incorrect', hasError: 'true' };
    }

    if (!sanitizedMessage) {
      localErrors = { ...localErrors, message: 'Incorrect', hasError: 'true' };
    }

    if (sanitizedTrackingNumbers.includes('')) {
      localErrors = { ...localErrors, trackingNumbers: 'Incorrect', hasError: 'true' };
    }

    if (localErrors.hasError === 'false') {
      const ticketData = {
        title: sanitizedTitle,
        message: sanitizedMessage,
        trackingNumbers: sanitizedTrackingNumbers,
        file: file,
      };

      try {
        dispatch(Tickets.createTicket(ticketData, ticketStatus));

        setTitle('');
        setMessage('');
        setTrackingNo('');
        setFile(null);
        setList([]);
        onClose();
      } catch (error) {
        console.error('Submission error', error);
      }
    } else {
      console.error('Form validation errors', localErrors);
    }
  };

  return (
    <BaseModal
      open={open}
      onClose={onClose}
      title="Create Ticket"
      content={
        <form onSubmit={submitHandler}>
          <AppTextInput
            className={classes.formInput}
            label={'Title'}
            required={true}
            type={'text'}
            value={title}
            onChange={titleHandler}
          />
          <AppTextInput
            className={classes.formInput}
            label={'Message'}
            value={message}
            required={true}
            onChange={messageHandler}
            multiline
            rows={6}
          />
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <AppTextInput
              className={classes.formInput}
              label={'Tracking No.'}
              type={'text'}
              value={trackingNo}
              onChange={trackingNoHandler}
              disabled={list.length >= 5}
            />
            <Box>
              <Button
                size={'large'}
                color={'primary'}
                variant="contained"
                disabled={list.length >= 5 || !trackingNo.length}
                onClick={() => handleAddTrackingNo(trackingNo)}>
                Add
              </Button>
            </Box>
          </Box>
          <Box style={{ display: 'flex' }}>
            {list.map(item => (
              <div key={item.id} style={{ marginRight: 4 }}>
                <RemoveableTag handleDel={() => handleDeleteTrackingNo(item.id)}>{item.number}</RemoveableTag>
              </div>
            ))}
          </Box>
          <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', paddingTop: 12, paddingBottom: 12 }}>
            <Button variant="text" component="label" color="default" className={classes.attachment}>
              <img src="/images/attachment.png" />
              <input id="file-input-attachment" onChange={handleFileChange} type="file" hidden />
            </Button>
            {file && (
              <div style={{ marginLeft: '10px' }}>
                {file.name}
                <Button variant="text" color="secondary" className={classes.removeAttachment} onClick={removeAttachment}>
                  ✕
                </Button>
              </div>
            )}
          </Box>
          <Box style={{ display: 'inline-flex', width: '100%', gap: 12, paddingBottom: 8 }}>
            <Box style={{ flex: 1, width: '100%' }}>
              <Button style={{ width: '100%' }} size={'large'} color={'primary'} variant="outlined" onClick={onClose}>
                Close
              </Button>
            </Box>
            <Box style={{ flex: 1, width: '100%' }}>
              <Button type="submit" style={{ width: '100%' }} size={'large'} color={'primary'} variant="contained">
                Create
              </Button>
            </Box>
          </Box>
        </form>
      }
    />
  );
}

export default TicketModal;
