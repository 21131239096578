export const SET_TICKETS = 'SET_TICKETS';
export const GET_REPLIES = 'GET_REPLIES';
export const SET_MARK_AS_READ = 'SET_MARK_AS_READ';
export const SET_TICKETS_CREATE_STORE = 'SET_TICKETS_CREATE_STORE';
export const SET_TICKET_ATTACHMENTS = 'SET_TICKET_ATTACHMENTS';
export const SET_REPLY_ATTACHMENTS = 'SET_REPLY_ATTACHMENTS';

export function setTickets(payload) {
  return {
    type: SET_TICKETS,
    payload: payload,
  };
}

export function getReplies(payload) {
  return {
    type: GET_REPLIES,
    payload: payload,
  };
}

export function setTicketCreatedState(payload) {
  return {
    type: SET_TICKETS_CREATE_STORE,
    payload: payload,
  };
}
export function setTicketAttachments(payload) {
  return {
    type: SET_TICKET_ATTACHMENTS,
    payload: payload,
  };
}
export function setReplytAttachments(payload) {
  return {
    type: SET_REPLY_ATTACHMENTS,
    payload: payload,
  };
}

export function setTicketMarkAsRead(payload) {
  return {
    type: SET_MARK_AS_READ,
    payload: payload,
  };
}
