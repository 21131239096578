import { SET_PICKUPS_IN_STORE, SET_PICKUP_CREATE_STATE, SET_PICKUP_CREATE_STATUS_RESET } from 'redux/actions/Pickups';
import { message } from 'statuses';

const INIT_STATE = {
  pickUps: [],
  page: 0, //next page
  isFirst: false,
  message: null,
  error: null,
  success: 'notDefined',
  showAlert: false,
};

export default function reducer(state = INIT_STATE, action) {
  switch (action.type) {
    case SET_PICKUPS_IN_STORE:
      let stateNew = {};
      if (action.payload.isFirst) {
        stateNew = {
          ...state,
          isFirst: true,
          page: 1,
          pickUps: action.payload.pickUps,
        };
      } else {
        stateNew = {
          ...state, //can remove
          isFirst: false,
          page: action.payload.page,
          pickUps: [...state.pickUps, ...action.payload.pickUps],
        };
      }

      return stateNew;

    case SET_PICKUP_CREATE_STATE:
      return { ...state, message: action.payload.message, success: action.payload.success, showAlert: true };

    case SET_PICKUP_CREATE_STATUS_RESET:
      return {
        ...state,
        showAlert: false,
        message: '',
        success: '',
      };

    default:
      return state;
  }
}
