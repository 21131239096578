import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import IntlMessages from '../../../utils/IntlMessages';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { Box, fade } from '@material-ui/core';
import { AuhMethods } from '../../../../services/auth';
import ContentLoader from '../../ContentLoader';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CmtImage from '../../../../@coremat/CmtImage';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import { NavLink } from 'react-router-dom';
import AuthWrapper from './AuthWrapper';
import Grid from '@material-ui/core/Grid';
const bgImage = '/images/auth/citypack_login_bg.png';

const useStyles = makeStyles(theme => ({
  rightPanelImage: {
    backgroundImage: `url(${bgImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '94vh',
    borderTopLeftRadius: '30px',
    borderBottomLeftRadius: '30px',
  },
  titleRoot: {
    fontSize: 52,
    color: '#BE0707',
  },
  subTitleRoot: {
    letterSpacing: 0.4,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 24,
    color: theme.palette.text.secondary,
    marginBottom: 0,
  },
  cityPackLogoContainer: {
    position: 'absolute',
    bottom: '30px',
    left: '30px',
  },
}));
//variant = 'default', 'standard'
const SignIn = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const classes = useStyles({ variant });

  const onSubmit = () => {
    dispatch(AuhMethods[method].onLogin({ email, password }));
  };

  return (
    // <AuthWrapper variant={wrapperVariant}>
    <Grid container direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: 'white' }}>
      <Grid item xs={12} sm={12} md={5}>
        <Box p={20} px={{ xs: 10, sm: 10, md: 30 }}>
          <Box>
            <Typography component="div" variant="h1" className={classes.titleRoot}>
              Welcome
            </Typography>
            <Typography component="div" variant="h1" className={classes.subTitleRoot}>
              Sign in to continue
            </Typography>
            <form>
              <Box mb={2}>
                <TextField
                  label={<IntlMessages id="appModule.email" />}
                  fullWidth
                  onChange={event => setEmail(event.target.value)}
                  defaultValue={email}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  type="password"
                  label={<IntlMessages id="appModule.password" />}
                  fullWidth
                  onChange={event => setPassword(event.target.value)}
                  defaultValue={password}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                />
              </Box>
              <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
                {/* <FormControlLabel
                  className={classes.formcontrolLabelRoot}
                  control={<Checkbox name="checkedA" />}
                  label="Remember me"
                /> */}
              </Box>

              <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
                <Button onClick={onSubmit} variant="contained" color="primary">
                  <IntlMessages id="appModule.signIn" />
                </Button>
              </Box>
            </form>
          </Box>

          <Box
            className={classes.cityPackLogoContainer}
            display={{
              xs: 'none',
              sm: 'none',
              md: 'block',
              lg: 'block',
              xl: 'block',
            }}>
            <CmtImage src={'/images/logo/login_logo.png'} />
          </Box>
          <Box
            display={{
              xs: 'block',
              sm: 'block',
              md: 'none',
              lg: 'none',
              xl: 'none',
            }}>
            <CmtImage src={'/images/logo/login_logo.png'} />
          </Box>
        </Box>
      </Grid>

      <Grid item md={7} p={0}>
        <Box
          className={classes.rightPanelImage}
          my={4}
          display={{
            xs: 'none',
            sm: 'none',
            md: 'block',
            lg: 'block',
            xl: 'block',
          }}
        />
      </Grid>
      <ContentLoader />
    </Grid>
    // </AuthWrapper>
  );
};

export default SignIn;
