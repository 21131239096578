import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { showSnackBar, hideSnackBar } from '../redux/actions/SnackBar';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SnackBar = () => {
  const dispatch = useDispatch();
  const { open, message, severity } = useSelector(state => state.snackBar);
  const vertical = 'bottom';
  const horizontal = 'right';

  const handleClose = (event, reason) => {
    dispatch(hideSnackBar());

    //   setOpen(false);
  };

  return (
    <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical, horizontal }}>
      <Alert onClose={handleClose} severity={severity}>
        {message}!
      </Alert>
    </Snackbar>
  );
};

export default SnackBar;
