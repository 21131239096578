import { LOAD_CITIES, LOAD_CITIES_SUCCESS, LOAD_CITIES_FAILED } from '../../@jumbo/constants/ActionTypes';

export const loadCities = () => {
  return dispatch => {
    dispatch({
      type: LOAD_CITIES,
    });
  };
};

export const loadCitiesSuccess = cities => {
  return dispatch => {
    dispatch({
      type: LOAD_CITIES_SUCCESS,
      payload: cities,
    });
  };
};

export const loadCitiesFailed = error_message => {
  return dispatch => {
    dispatch({
      type: LOAD_CITIES_FAILED,
      payload: error_message,
    });
  };
};
