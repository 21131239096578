import {
  Tooltip,
  IconButton,
  Checkbox,
  makeStyles,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tabs,
  Typography,
  Box,
} from '@material-ui/core';
import EnhancedTableHead from 'custom_components/EnhancedTableHead';
import EnhancedTableToolbar from 'custom_components/EnhancedTableToolbar';
import TabPanel from 'custom_components/TabPanel';
import PrintIcon from '@material-ui/icons/Print';
import Button from '@material-ui/core/Button';
import React, { useState, useEffect, useContext } from 'react';

import ExportIcon from 'custom_components/ExportIcon';
import { useDispatch, useSelector } from 'react-redux';
import CodPayments from 'services/codPayments';
import ErrorToast from 'custom_components/Pages/common/ErrorToast';
import { OrderContext } from 'routes/Pages/Orders/context/orderContext';
import { setIsRecordsAvailable } from 'routes/Pages/Orders/actions/actions';
import { openDialog, closeDialog } from '../../../redux/actions/PrintOrder';
import { CsvBuilder } from 'filefy';
import AppConfigs from '../../../Utils/AppConfigs';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment/moment';
import reports from '../../../services/reports/index';
import Grid from '@material-ui/core/Grid';
import CodPaymentsTableHead from './CodPaymentsTableHead';
import DownloadIcon from '../../DownloadIcon';
import BulkUploads from '../../../services/bulkUploads';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: 'green',
  },

  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

  paginationContainer: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
  },
  tableCellPadding: {
    paddingBottom: 0,
    paddingTop: 0,
  },

  exportIcon: {
    marginRight: 20,
  },
  toolContainer: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
    padding: 5,
  },
  exportButtonContainer: {
    display: 'flex',
    flex: 2,
    justifyContent: 'right',
    alignItems: 'center',
    paddingRight: 20,
    //backgroundColor:'green'
  },
  datePickerContainer: {
    display: 'flex',
    flex: 6,
    justifyContent: 'right',
    alignItems: 'center',
    paddingRight: 20,
    //backgroundColor:'yellow'
  },

  tabPanel: {
    '& > div': {
      paddingLeft: 0,
      paddingRight: 0,
      //backgroundColor:'blue'
    },
  },
}));

const headCells = [
  {
    id: 'id',
    numeric: true,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'eod_completed_from_datetime',
    numeric: false,
    disablePadding: false,
    label: 'DELIVERED DATE - FROM',
  },
  {
    id: 'eod_completed_to_datetime',
    numeric: false,
    disablePadding: false,
    label: 'DELIVERED DATE - TO',
  },
  {
    id: 'payment_datetime',
    numeric: false,
    disablePadding: false,
    label: 'DEPOSITED DATE',
  },
  {
    id: 'delivery_charge_with_tax',
    numeric: true,
    disablePadding: false,
    label: 'DELIVERY CHARGE WITH TAX',
  },
  {
    id: 'cod_charge_with_tax',
    numeric: true,
    disablePadding: false,
    label: 'COD COMM WITH TAX',
  },
  {
    id: 'total_charges',
    numeric: true,
    disablePadding: false,
    label: 'TOTAL CHARGE',
  },
  {
    id: 'deductions',
    numeric: true,
    disablePadding: false,
    label: 'DEDUCTIONS',
  },
  {
    id: 'balance_brought_forward',
    numeric: true,
    disablePadding: false,
    label: 'BALANCE B/F',
  },
  {
    id: 'total_cod_amount',
    numeric: true,
    disablePadding: false,
    label: 'TOTAL COD COLLECTED',
  },
  {
    id: 'net_payable',
    numeric: true,
    disablePadding: false,
    label: 'NET PAYABLE',
  },
  {
    id: 'customer_reference',
    numeric: true,
    disablePadding: false,
    label: 'REFERENCE',
  },
  {
    id: 'deduction_remarks',
    numeric: true,
    disablePadding: false,
    label: 'DEDUCTION REMARKS',
  },
  { id: 'excel_export', numeric: true, disablePadding: false, label: 'WAYBILLS' },
];

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function CodPaymentsDataTable() {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [maxPageNum, setMaxPageNum] = useState(0);

  const [fromDate, setFromDate] = useState(
    moment()
      .clone()
      .startOf('month'),
  );
  const [toDate, setToDate] = useState(
    moment()
      .clone()
      .endOf('month'),
  );

  const orderState = useContext(OrderContext);

  // const [isRecordsAvailable, setIsRecordsAvailable] = useState(true);
  let isVisible = true;
  const dispatch = useDispatch();

  const downloadHanlder = (event, fileName) => {
    dispatch(CodPayments.downloadFile(fileName));
  };

  useEffect(() => {
    dispatch(CodPayments.getCodPayments(1, true, `${fromDate.format('DD-MM-YYYY')}`, `${toDate.format('DD-MM-YYYY')}`));

    return () => {
      isVisible = false;
    };
  }, []);

  const cod_payments = useSelector(({ codPayments }) => codPayments.cod_payments);

  let dataCreated = false;

  function createData(cod_payment) {
    return {
      id: cod_payment.id,
      eod_completed_from_datetime: cod_payment.batch_cod_settlement.eod_completed_from_datetime,
      eod_completed_to_datetime: cod_payment.batch_cod_settlement.eod_completed_to_datetime,
      payment_datetime: cod_payment.batch_cod_settlement.payment_datetime,
      delivery_charge_with_tax: cod_payment.delivery_charge_with_tax,
      cod_charge_with_tax: cod_payment.cod_charge_with_tax,
      total_charges: cod_payment.total_charges,
      deductions: cod_payment.deductions,
      balance_brought_forward: cod_payment.balance_brought_forward,
      total_cod_amount: cod_payment.total_cod_amount,
      net_payable: cod_payment.net_payable,
      customer_reference: cod_payment.batch_cod_settlement.customer_reference,
      deduction_remarks: cod_payment.deduction_remarks,
      excel_export: cod_payment.id,
    };
  }

  const dataBuilder = data => {
    console.log('dataBuilderintializing...');
    if (data == null || data.length === 0) {
      console.log('dataBuilder no data', cod_payments);

      return [];
    } else {
      dataCreated = true;
      return data.map(entry => {
        return createData(entry);
      });
    }
  };

  const dataForTable = dataBuilder(cod_payments.payments); //cashing data

  const rows = () => {
    console.log('calling Raw');
    if (dataCreated) {
      console.log('Sending Cashed');
      return dataForTable;
    } else {
      console.log('preparing....');
      return dataBuilder(cod_payments.payments);
    }
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows().map(n => n.waybill);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  const shouldGetNextDataBulk = newPage => {
    const nextPageNum = newPage;
    if (page < nextPageNum) {
      //moving forward
      const remaining = rows().length - nextPageNum * rowsPerPage;
      if (remaining <= rowsPerPage) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const getNewData = () => {
    const next = page + 1;
    dispatch(
      CodPayments.getCodPayments(next + 1, false, `${fromDate.format('DD-MM-YYYY')}`, `${toDate.format('DD-MM-YYYY')}`),
    );
  };

  const handleChangePage = (event, newPage) => {
    // alert(`max->${maxPageNum} next->${newPage}`);
    console.log(newPage);
    if (shouldGetNextDataBulk(newPage) && maxPageNum !== newPage) {
      getNewData();
    }
    setPage(newPage);
    if (newPage > maxPageNum) {
      setMaxPageNum(newPage);
    }
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows().length - page * rowsPerPage);

  const exportToCsv = () => {
    // const headings = [];
    // headCells.map(item => (item.id !== 'print' ? headings.push(item.label) : null));
    // const data = stableSort(rows(), getComparator(order, orderBy)).slice(
    //   page * rowsPerPage,
    //   page * rowsPerPage + rowsPerPage,
    // );
    // const dataSet = data.map(item => Object.values(item));
    //
    // const csvBuilder = new CsvBuilder('order_list_export.csv')
    //   .setColumns(headings)
    //   .addRows(dataSet)
    //   .exportFile();
    dispatch(reports.openCreatedOrdersReportUrl(fromDate, toDate));
  };
  const handleRefresh = () => {
    dispatch(CodPayments.getCodPayments(1, true, `${fromDate.format('DD-MM-YYYY')}`, `${toDate.format('DD-MM-YYYY')}`));
    setPage(0);
    setMaxPageNum(0);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          enableRefresh={true}
          handleRefresh={handleRefresh}
          enablePrint={false}
          selected={selected}
          numSelected={selected.length}
          origin="waybills"
        />
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box p={5}>
              <Typography component="div" variant={'h2'}>
                COD Deposits
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container justifyContent="center" alignItems="flex-end" spacing={2}>
              <Grid item xs={12} md={5}>
                <Box p={2}>
                  <KeyboardDatePicker
                    style={{ width: '100%' }}
                    disableToolbar
                    variant="inline"
                    format="DD-MM-yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="EOD Closed Date - From"
                    value={fromDate}
                    autoOk={true}
                    onChange={e => {
                      setFromDate(moment(e._d));
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={5}>
                <Box p={2}>
                  <KeyboardDatePicker
                    style={{ width: '100%' }}
                    disableToolbar
                    variant="inline"
                    format="DD-MM-yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="EOD Closed Date - To"
                    value={toDate}
                    minDate={fromDate}
                    autoOk={true}
                    onChange={e => {
                      setToDate(moment(e._d));
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={2}>
                <Box p={2} py={5} sx={{ alignContent: 'flex-end' }}>
                  <Button
                    variant="contained"
                    fullWidth={true}
                    className={classes.newRequestButton}
                    onClick={() => {
                      // alert(moment(new Date(fromDate)).format('YYYY-MM-DD'))
                      handleRefresh();
                      // dispatch(
                      //   PickUps.getPickUps(
                      //     true,
                      //     undefined,
                      //     moment(new Date(fromDate)).format('YYYY-MM-DD'),
                      //     moment(new Date(toDate)).format('YYYY-MM-DD'),
                      //   ),
                      //   setPage(0),
                      // );
                    }}>
                    Filter
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <TableContainer>
          <Table className={classes.table} aria-labelledby="tableTitle" size={'small'} aria-label="enhanced table">
            <CodPaymentsTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows().length}
              headCells={headCells}
              excludeCell={'print'}
            />
            <TableBody>
              {stableSort(rows(), getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.waybill);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      //role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      //key={row.waybill}
                      selected={isItemSelected}
                      classes={{ hover: 'MuiTableRow-hover' }}>
                      <TableCell align="center">{row.id}</TableCell>
                      <TableCell align="center">{AppConfigs.formatDisplayDate(row.eod_completed_from_datetime)}</TableCell>
                      <TableCell align="center">{AppConfigs.formatDisplayDate(row.eod_completed_to_datetime)}</TableCell>
                      <TableCell align="center">
                        {row.payment_datetime != null && AppConfigs.formatDisplayDate(row.payment_datetime)}
                      </TableCell>
                      <TableCell align="right">{row.delivery_charge_with_tax}</TableCell>
                      <TableCell align="right">{row.cod_charge_with_tax}</TableCell>
                      <TableCell align="right">{row.total_charges}</TableCell>
                      <TableCell align="right">{row.deductions}</TableCell>
                      <TableCell align="right">{row.balance_brought_forward}</TableCell>
                      <TableCell align="right">{row.total_cod_amount}</TableCell>
                      <TableCell align="right">{row.net_payable}</TableCell>
                      <TableCell align="right">{row.customer_reference}</TableCell>
                      <TableCell align="right">{row.deduction_remarks}</TableCell>
                      <TableCell align="right">
                        <Tooltip title="download">
                          <IconButton
                            aria-label="download"
                            color={'primary'}
                            onClick={event => downloadHanlder(event, row.excel_export)}>
                            <DownloadIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={14} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box className={classes.paginationContainer}>
          {/*<Button*/}
          {/*    variant="outlined"*/}
          {/*    onClick={exportToCsv}*/}
          {/*    size={'small'}*/}
          {/*    startIcon={<ExportIcon/>}*/}
          {/*    className={classes.exportIcon}>*/}
          {/*    Export*/}
          {/*</Button>*/}
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={cod_payments.totalRecords}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
      </Paper>
      <ErrorToast
        open={cod_payments && cod_payments.payments ? (cod_payments.payments.length > 0 ? false : true) : false}
        message="No Records Found"
      />
    </div>
  );
}

export default CodPaymentsDataTable;
