import { LOAD_CITIES, LOAD_CITIES_SUCCESS, LOAD_CITIES_FAILED } from '../../../../src/@jumbo/constants/ActionTypes';

const INIT_STATE = {
  cities: null,
  is_completed: false,
  is_success: false,
  message: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOAD_CITIES: {
      return {
        ...state,
        is_success: true,
        is_completed: false,
        message: '',
      };
    }
    case LOAD_CITIES_SUCCESS: {
      return {
        ...state,
        cities: action.payload,
        is_success: true,
        is_completed: true,
        message: '',
      };
    }

    case LOAD_CITIES_FAILED: {
      return {
        ...state,
        is_success: false,
        is_completed: true,
        message: action.payload,
      };
    }
    default:
      return state;
  }
};
