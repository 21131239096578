import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import GridContainer from '../../../@jumbo/components/GridContainer';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/styles';
import {
  Checkbox,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tabs,
  Typography,
} from '@material-ui/core';
import CreatedOrderTabPanel from 'custom_components/Pages/Orders/CreatedOrderTabPanel';
import InTransitOrderTabPanel from 'custom_components/Pages/Orders/InTransitOrderTabPanel';
import OutOfDeliveryTabPanel from 'custom_components/Pages/Orders/OutOfDeliveryTabPanel';
import DelliveredTabPanel from 'custom_components/Pages/Orders/DelliveredTabPanel';
import ReturnedTabPanel from 'custom_components/Pages/Orders/ReturnedTabPanel';

import reducer, { initialState } from './reducer/reducer';
import { OrderContext } from './context/orderContext';
import PrintDialog from 'custom_components/PrintDialog';
import { useSelector } from 'react-redux';
import Badge from '@material-ui/core/Badge';
import { useLocation } from 'react-router-dom';
// import CreatedOrderTabPanelEs from 'custom_components/Pages/Orders/CreatedOrderTabPanelEs';
// import InTransitOrderTabPanelEs from 'custom_components/Pages/Orders/InTransitOrderTabPanelEs';
// import OutOfDeliveryTabPanelEs from 'custom_components/Pages/Orders/OutOfDeliveryTabPanelEs';
// import DelliveredTabPanelEs from 'custom_components/Pages/Orders/DelliveredTabPanelEs';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    //backgroundColor:'green',
  },

  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

  line: {
    width: '100%',
    height: 2,
    backgroundColor: 'black',
  },
}));

const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'Orders', isActive: true },
];

const actionButtons = [
  { label: 'Pickup Request', link: '/' },
  { label: 'New Order', isActive: true },
];

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function OrdersEs() {
  // const created = useSelector(({ orders }) => orders.created);
  // const intransit = useSelector(({ orders }) => orders.intransit);
  // const outForDelivery = useSelector(({ orders }) => orders.outForDelivery);
  // const delivered = useSelector(({ orders }) => orders.delivered);
  const createdEs = useSelector(({ orders }) => orders.createdEs);
  const intransitEs = useSelector(({ orders }) => orders.intransitEs);
  const outForDeliveryEs = useSelector(({ orders }) => orders.outForDeliveryEs);
  const deliveredEs = useSelector(({ orders }) => orders.deliveredEs);
  const classes = useStyles();
  const location = useLocation();
  const [value, setValue] = useState(0);
  const [status, setStatus] = useState();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  const [state, dispatch] = useReducer(reducer, initialState);

  useMemo(() => {
    if (location.state && location.state.searchTerm) {
      const params = location.state.searchTerm.split('&');

      params.forEach(param => {
        const [key, value] = param.split('=');
        if (key === 'status') {
          setStatus(value);
        } else if (key === 'fromDate') {
          setFromDate(value);
        } else if (key === 'toDate') {
          setToDate(value);
        }
      });
    }
  }, [location.state]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getStatus = type => {
    switch (type) {
      case 'PICKED':
        setValue(0);
        break;
      case 'RT':
        setValue(1);
        break;
      case 'OUD':
        setValue(2);
        break;
      case 'DL':
        setValue(3);
        break;
      case 'RTM':
        setValue(3);
        break;
      case 'completed':
        setValue(3);
        break;
      case 'UD':
        setValue(1);
        break;
      case 'IN-TRANSIT':
        setValue(1);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    getStatus(status);
  }, [status]);

  return (
    <OrderContext.Provider value={{ state: state, dispatch: dispatch }}>
      <PageContainer heading={'ORDERS'} style={{ backgroundColor: 'blue' }} actionButtons={actionButtons}>
        <GridContainer>
          <Grid item xs={12} sm={12} style={{ padding: 0 }}>
            <Box className={classes.header}>
              <Tabs
                variant="scrollable"
                scrollButtons="auto"
                indicatorColor={'primary'}
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example">
                <Tab label={'CREATED' + ' (' + (createdEs ? createdEs.total : 0) + ')'} {...a11yProps(0)} />
                <Tab label={'IN TRANSIT' + ' (' + (intransitEs ? intransitEs.total : 0) + ')'} {...a11yProps(1)} />
                <Tab
                  label={'OUT FOR DELIVERY' + ' (' + (outForDeliveryEs ? outForDeliveryEs.total : 0) + ')'}
                  {...a11yProps(2)}
                />
                <Tab label={'DELIVERED' + ' (' + (deliveredEs ? deliveredEs.total : 0) + ')'} {...a11yProps(3)} />
                {/* <Tab label={'CREATED ES' + ' (' + createdEs.total + ')'} {...a11yProps(4)} /> */}
                {/* <Tab label="RETURNED" {...a11yProps(4)} /> */}
              </Tabs>

              {/* <CreatedOrderTabPanelEs
                status={status}
                dashboardFromDate={fromDate}
                dashboardToDate={toDate}
                value={value}
                index={0}
              />
              <InTransitOrderTabPanelEs
                status={status}
                dashboardFromDate={fromDate}
                dashboardToDate={toDate}
                value={value}
                index={1}
              />
              <OutOfDeliveryTabPanelEs dashboardFromDate={fromDate} dashboardToDate={toDate} value={value} index={2} />

              <DelliveredTabPanelEs
                status={status}
                dashboardFromDate={fromDate}
                dashboardToDate={toDate}
                value={value}
                index={3}
              /> */}

              <ReturnedTabPanel value={value} index={4} />
            </Box>
          </Grid>
        </GridContainer>
      </PageContainer>
    </OrderContext.Provider>
  );
}

export default OrdersEs;
