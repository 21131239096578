import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
  },
});

function createData(waybill, reference, date, time, status, location) {
  return { waybill, reference, date, time, status, location };
}

// const rows = [
//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Eclair', 262, 16.0, 24, 6.0),
//   createData('Cupcake', 305, 3.7, 67, 4.3),
//   createData('Gingerbread', 356, 16.0, 49, 3.9),
// ];

function createRows(data) {
  return data.map(row => {
    let address = '';
    if (row.to_address_line_1 !== '') address += row.to_address_line_1 + ',';
    if (row.to_address_line_2 !== '') address += row.to_address_line_2 + ',';
    if (row.to_address_line_3 !== '') address += row.to_address_line_3 + ',';
    if (row.to_address_line_4 !== '') address += row.to_address_line_4;

    const dateAndTime = row.created_at.split(' ');
    return createData(row.tracking_number, row.reference, dateAndTime[0], dateAndTime[1], '', address);
  });
}

function RefListTAble({ data }) {
  const location = useLocation();
  const history = useHistory();
  console.log(location, '454665');
  const rows = createRows(data);

  const classes = useStyles();

  const redirect = value => {
    history.push({
      pathname: '/order_tracking',
      search: `?id=${value}`,
      state: { id: value },
    });
  };
  return (
    <div className={classes.container}>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Waybill No</TableCell>
              <TableCell align="right">Reference No</TableCell>
              <TableCell align="right">Date</TableCell>
              <TableCell align="right">Time</TableCell>
              <TableCell align="right">status</TableCell>
              <TableCell align="right">Location</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.waybill} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  <Button
                    onClick={() => {
                      redirect(row.waybill);
                    }}>
                    {row.waybill}
                  </Button>
                </TableCell>
                <TableCell align="right">{row.reference}</TableCell>
                <TableCell align="right">{row.date}</TableCell>
                <TableCell align="right">{row.time}</TableCell>
                <TableCell align="right">{row.status}</TableCell>
                <TableCell align="right">{row.location}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default RefListTAble;
