import React from 'react'
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import TrackingHistoryTable from 'custom_components/Pages/OrderTracking/TrackingHistoryTable';
import RefListTAble from 'custom_components/Pages/OrderTracking/RefListTAble';
import Box from '@material-ui/core/Box';



const useStyles = makeStyles(theme => ({


    orderTrackingContainer:{
        backgroundColor:theme.palette.background.paper,
        //alignContent:'center',
        //justifyContent:'center',
        borderRadius:10,
        paddingLeft:15,
        paddingRight:15,
        paddingTop:15,
        paddingBottom:5,
        //display:'flex',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)',
        //height:'100%',
        
     },


    container:{
        
      
        justifyContent:'right',
        alignItems:'center',
        flexDirection:'column'
       
      },
      titleContainer:{
          display:'flex',
        
          alignItems:'center',
            paddingTop:20,
            paddingBottom:20,

      }
  


}));



function SearchResultUIR() {
    const classes = useStyles();
    return (
        <Box className={classes.orderTrackingContainer}>
        <div className={classes.container}>
            <div className={classes.titleContainer}>
                <Typography component="div" variant="h3">Search Results For Reference Number {"num"}</Typography>
            </div>
            <div>
                <RefListTAble></RefListTAble>
            </div>
            
            
        </div>
        </Box>
    )
}

export {SearchResultUIR}

