import {
  Tooltip,
  IconButton,
  Checkbox,
  makeStyles,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tabs,
  Typography,
  Box,
  Grid,
} from '@material-ui/core';
import EnhancedTableHead from 'custom_components/EnhancedTableHead';
import EnhancedTableToolbar from 'custom_components/EnhancedTableToolbar';
import TabPanel from 'custom_components/TabPanel';
import PrintIcon from '@material-ui/icons/Print';
import Button from '@material-ui/core/Button';
import React, { useEffect, useState } from 'react';
import ExportIcon from 'custom_components/ExportIcon';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import GridContainer from '@jumbo/components/GridContainer';
import CustomTableHeader from 'custom_components/Pages/BulkUpload/CustomTableHeader';
import DownloadIcon from 'custom_components/DownloadIcon';
import BulkUploadTableToolbar from 'custom_components/Pages/BulkUpload/BulkUploadTableToolbar';
import BulkUploadForm from 'custom_components/Pages/BulkUpload/BulkUploadForm';
import { Block } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import BulkUploads from 'services/bulkUploads';
import BulkUploadDataTable from 'custom_components/Pages/BulkUpload/BulkUploadDataTable';
import BulkUploadDialog from 'custom_components/BulkUploadDialog';
import SnackBar from 'custom_components/SnackBar';
import CodPaymentsDataTable from '../../../custom_components/Pages/CodPayments/CodPaymentsDataTable';
import CodPaymentService from 'services/codPayments';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: 'green',
  },

  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

  paginationContainer: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
  },
  tableCellPadding: {
    paddingBottom: 0,
    paddingTop: 0,
  },

  exportIcon: {
    marginRight: 20,
  },

  tabPanel: {
    '& > div': {
      paddingLeft: 0,
      paddingRight: 0,
      //backgroundColor:'blue'
    },

    mainForm: {
      display: 'none',
    },
  },
}));

const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'COD Payments', isActive: true },
];

function CodPayments() {
  let isVisible = false;
  const classes = useStyles();

  const dispatch = useDispatch();
  const cod_payment_summary = useSelector(({ codPayments }) => codPayments.cod_payment_summary);

  useEffect(() => {
    console.log('Calling UseEffect');

    //dispatch(BulkUploads.getBulkUploads(1, true));
    dispatch(CodPaymentService.getCodPaymentSummary());

    return () => {
      isVisible = false;
    };
  }, []);

  const addNewEventHandler = () => {};

  return (
    //

    <React.Fragment>
      <PageContainer heading={'COD PAYMENTS'} breadcrumbs={breadcrumbs}>
        {/* Add PERIOD and buttons */}
        <Grid container alignItems="center" style={{ paddingBottom: '16px' }} spacing={2}>
          <Grid item>
            <Typography variant="h3" style={{ marginRight: '8px', fontWeight: 'bold', fontSize: '18px' }}>
              Period – {cod_payment_summary && cod_payment_summary.period ? cod_payment_summary.period : ''}
            </Typography>
          </Grid>
          <Grid item>
            <Box
              component="div"
              display="inline-block"
              bgcolor="red"
              color="white"
              borderRadius="10px"
              p={3}
              style={{ fontSize: '18px' }}>
              Next COD Payment
              <br />
              LKR{' '}
              <span style={{ fontWeight: 'bold', fontSize: '20px' }}>
                {cod_payment_summary && cod_payment_summary.nextCodPayment !== null
                  ? cod_payment_summary.nextCodPayment
                  : ''}
              </span>
            </Box>
          </Grid>
          <Grid item>
            <Box
              component="div"
              display="inline-block"
              bgcolor="green"
              color="white"
              borderRadius="10px"
              p={3}
              style={{ fontSize: '18px' }}>
              Total COD Deposited
              <br />
              LKR{' '}
              <span style={{ fontWeight: 'bold', fontSize: '20px' }}>
                {cod_payment_summary && cod_payment_summary.totalCodDeposited !== null
                  ? cod_payment_summary.totalCodDeposited
                  : ''}
              </span>
            </Box>
          </Grid>
        </Grid>

        <GridContainer className={classes.mainForm}>
          <Grid item xs={12} sm={12} style={{ padding: 0 }}>
            <Box className={classes.header}>
              <div className={classes.root}>
                <Paper className={classes.paper}>
                  <CodPaymentsDataTable />
                </Paper>
              </div>
            </Box>
          </Grid>
        </GridContainer>
      </PageContainer>
      <BulkUploadDialog />
      <SnackBar />
    </React.Fragment>

    /*
           
         
         */
  );
}

export default CodPayments;
