import {
  makeStyles,
  Paper,
  Typography,
  Box,
  List,
  ListItem,
  Avatar,
  ListItemText,
  ListItemAvatar,
  Divider,
  withStyles,
  Badge,
} from '@material-ui/core';

import Button from '@material-ui/core/Button';
import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { KeyboardDatePicker } from '@material-ui/pickers';
import GridContainer from '@jumbo/components/GridContainer';
import moment from 'moment';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import AppSelectBox from '@jumbo/components/Common/formElements/AppSelectBox';
import Tickets from 'services/tickets';
import TicketModal from '@jumbo/components/Common/Modals/TicketModal';
import ThreadItems from './ThreadItems';
import SnackBar from 'custom_components/SnackBar';
import { debounce } from 'lodash';
import xss from 'xss';

const StyledBadge = withStyles(theme => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

const SmallAvatar = withStyles(theme => ({
  root: {
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
  },
}))(Avatar);

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },

  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

  paginationContainer: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
  },

  toolContainer: {
    marginBottom: 16,
    width: '100%',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    // paddingRight: 20,
    width: '100%',
  },
  filterContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  tabPanel: {
    '& > div': {
      paddingLeft: 0,
      paddingRight: 0,
      //backgroundColor:'blue'
    },
  },
  form: {
    display: 'flex',
    flex: 1,
    //width:'50%',
    //paddingLeft:20,
    paddingRight: 15,
    flexDirection: 'column',
  },
  formInput: {
    marginTop: '5px',
    marginBottom: '5px',
    padding: 10,
    marginRight: 10,
    width: '100%',
    backgroundColor: '#fff',
  },
  formInputFilters: {
    marginTop: '5px',
    marginBottom: '5px',
    // padding: 10,
  },
  formInputWrapper: {
    padding: '10px 10px 0 10px',
    backgroundColor: '#fff',
    width: '400px',
    marginRight: 10,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  ticketContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  textUppercase: {
    textTransform: 'uppercase',
  },
  attachment: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  active: {
    border: '1px solid #BE0707',
    borderRadius: '4px',
  },
  trackingNumbers: {
    color: '#0009',
    fontSize: '14px',
    marginTop: '4px',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },
  removeAttachment: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  ticketWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
  },
  ticketBadge: {
    position: 'absolute',
    top: '-10px',
    right: '-10px',
    padding: '4px 4px',
    borderRadius: '50%',
    backgroundColor: '#BE0707',
  },
  ticketHeader: {
    color: '#BE0707',
    fontWeight: 600,
    fontSize: '14px',
  },
  ticketTitle: {
    fontWeight: 600,
    fontSize: '14px',
  },
  ticketCreatedDate: {
    color: '#000',
    fontWeight: 400,
    fontSize: '12px',
  },
  statusBadge: {
    padding: '4px 14px',
    fontWeight: 600,
    textTransform: 'capitalize',
    borderRadius: '4px',
    textTransform: 'uppercase',
    fontSize: '12px',
  },
  centerDefaultText: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  refreshBtn: {
    height: '32px',
  },
}));

function DataTable({ toggleNewRequestUI }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const ticketsData = useSelector(state => state.tickets);
  const [status, setStatus] = useState('open');
  const [ticket, setTicket] = useState(null);
  const [ticketId, setTicketId] = useState(null);
  const [replies, setReplies] = useState({});
  const [message, setMessage] = useState('');

  const [ticketModalOpen, setTicketModalOpen] = useState(false);
  const [trackingNumber, setTrackingNumber] = useState('');
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({
    message: '',
    hasError: 'false',
  });
  const [disableThread, setDisableThread] = useState(false);
  const [ticketStatus, setTicketStatus] = useState('');

  useEffect(() => {
    Tickets.getTickets('', 'open')(dispatch);
  }, []);

  const fetchTickets = () => {
    Tickets.getTickets('', status)(dispatch);
    if (ticket) {
      dispatch(Tickets.getReplies(ticket));
      dispatch(Tickets.setTicketMarkAsRead(ticket, trackingNumber, status));
    }
  };

  useEffect(() => {
    setReplies(ticketsData.replies);
  }, [ticketsData]);

  useEffect(() => {
    if (ticketStatus === 'closed') {
      setDisableThread(true);
    } else {
      setDisableThread(false);
    }
  }, [ticketStatus]);

  useEffect(() => {
    const res = ticket && ticketsData.data.tickets.filter(item => item.id == ticket)[0];

    res && setTicketStatus(res.status);

    res && setTicketId(res.id);
    if (res) {
      dispatch(Tickets.getReplies(ticket));
      dispatch(Tickets.setTicketMarkAsRead(ticket, trackingNumber, status));
    }
  }, [ticket]);

  const handleOpenTicketModal = () => {
    setTicketModalOpen(true);
  };

  const handleCloseTicketModal = () => {
    setTicketModalOpen(false);
  };

  const sanitizeInput = value => xss(value);

  const sanitizedTickets =
    ticketsData &&
    ticketsData?.data?.tickets?.map(ticket => ({
      ...ticket,
      title: sanitizeInput(ticket.title),
      status: sanitizeInput(ticket.status),
    }));

  function messageHandler(event) {
    setMessage(sanitizeInput(event.target.value));
  }

  const trackingNumberHandler = event => {
    setTrackingNumber(sanitizeInput(event.target.value));
  };

  useEffect(() => {
    const debouncedFetch = debounce(() => {
      fetchTicketDetails();
    }, 500);

    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [trackingNumber, status]);

  const handleFileChange = e => {
    setFile(e.target.files[0]);
  };

  const removeAttachment = () => {
    setFile(null);

    const fileInput = document.getElementById('file-input');
    if (fileInput) {
      fileInput.value = '';
    }
  };

  const textValidate = value => {
    const pattern = /^[A-Za-z0-9 ]+$/;

    if (value.match(pattern) && value.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const isValidFile = () => {
    if (file) {
      return file.name.split('.')[1] === 'png' || file.name.split('.')[1] === 'jpg' || file.name.split('.')[1] === 'jpeg';
    }
    return false;
  };

  const submitHandler = event => {
    event.preventDefault();

    const sanitizedMessage = sanitizeInput(message.trim());

    let localErrors = {
      message: '',
      hasError: 'false',
    };

    if (!sanitizedMessage) {
      localErrors = { ...localErrors, message: 'Incorrect', hasError: 'true' };
    } else {
      localErrors = { ...localErrors, message: '' };
    }

    const ticketData = {
      reply: encodeURIComponent(sanitizedMessage),
      ticketId: ticketId,
      file: file,
    };
    setErrors(localErrors);

    if (localErrors.hasError === 'false') {
      dispatch(Tickets.setTicket(ticketData));
      setMessage('');
      removeAttachment();
    }
  };

  const statusColor = status => {
    switch (status) {
      case 'open':
        return '#d5a90c';
      case 'closed':
        return '#4dd50c';
    }
  };

  const fetchTicketDetails = () => {
    // if (trackingNumber) {
    // }
    dispatch(Tickets.getTickets(trackingNumber, status));
  };

  return (
    <div>
      <GridContainer>
        <Grid item xs={12} sm={12} style={{ padding: 0 }}>
          <Box className={classes.root}>
            {/* <Paper> */}
            <Box className={classes.toolContainer}>
              <Box className={classes.container}>
                <Box className={classes.filterContainer}>
                  <Box className={classes.formInputWrapper}>
                    <AppTextInput
                      className={classes.formInputFilters}
                      label={'Search Tickets'}
                      type={'text'}
                      placeholder={'Tracking Number'}
                      value={trackingNumber}
                      onChange={trackingNumberHandler}
                    />
                  </Box>
                  <Box className={classes.formInputWrapper} style={{ width: 250 }}>
                    <Box className={classes.formInputFilters}>
                      <AppSelectBox
                        style={{ width: 240 }}
                        data={[
                          { labelKey: 'All', valueKey: 'all' },
                          { labelKey: 'Open', valueKey: 'open' },
                          { labelKey: 'Closed', valueKey: 'closed' },
                        ]}
                        valueKey={'valueKey'}
                        labelKey={'labelKey'}
                        label={'Statuses'}
                        value={status}
                        onChange={event => {
                          setStatus(event.target.value);
                        }}
                      />
                    </Box>
                  </Box>
                  <Button
                    variant="contained"
                    className={classes.refreshBtn}
                    style={{ width: '240px' }}
                    onClick={fetchTickets}>
                    Refresh
                  </Button>
                </Box>
                <Button
                  variant="contained"
                  className={classes.newRequestButton}
                  style={{ width: '240px' }}
                  onClick={handleOpenTicketModal}>
                  New Ticket
                </Button>
              </Box>
            </Box>

            <React.Fragment>
              <div className={classes.ticketContainer}>
                <div style={{ width: '30%', height: '80vh', overflow: 'scroll', scrollbarWidth: 'none' }}>
                  <Paper>
                    <List className={classes.root}>
                      {sanitizedTickets?.length ? (
                        sanitizedTickets.map(ticket => (
                          <div
                            key={ticket.id}
                            style={{ cursor: 'pointer' }}
                            className={ticket.id === ticketId ? classes.active : ''}
                            onClick={() => setTicket(ticket.id)}>
                            <ListItem alignItems="flex-start">
                              <ListItemText
                                primary={
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    className={classes.inline}
                                    color="textPrimary">
                                    <div className={classes.ticketWrapper}>
                                      <div>
                                        <div className={classes.ticketHeader}>Ticket #{ticket.id}</div>
                                        <div className={classes.ticketTitle}>{ticket.title}</div>
                                        <span className={classes.ticketCreatedDate}>
                                          Created: {moment(ticket.created_at).format('DD-MM-YYYY')}
                                        </span>
                                      </div>

                                      <span
                                        className={classes.statusBadge}
                                        style={{ backgroundColor: statusColor(ticket.status) }}>
                                        {ticket.status}
                                      </span>
                                      {!ticket.is_read && <span className={classes.ticketBadge}></span>}
                                    </div>
                                  </Typography>
                                }
                              />
                            </ListItem>
                            <Divider variant="fullWidth" component="li" />
                          </div>
                        ))
                      ) : (
                        <div>No tickets</div>
                      )}
                    </List>
                  </Paper>
                </div>
                <div style={{ width: 'calc(70% - 16px)' }}>
                  {ticket ? (
                    <div>
                      <ThreadItems replies={replies} />
                      <Grid container>
                        <Grid item md={12}>
                          <Box>
                            <form onSubmit={submitHandler}>
                              <Box style={{ background: '#fff' }}>
                                <Box style={{ display: 'flex', justifyContent: 'space-between', padding: '0 10px' }}>
                                  <Box style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                    <Button
                                      variant="text"
                                      component="label"
                                      color="default"
                                      className={classes.attachment}
                                      disabled={disableThread}>
                                      <img src="/images/attachment.png" />
                                      <input id="file-input" onChange={handleFileChange} type="file" hidden />
                                    </Button>
                                    <AppTextInput
                                      className={classes.formInput}
                                      label={'Your message'}
                                      value={message}
                                      variant="outlined"
                                      onChange={messageHandler}
                                      disabled={disableThread}
                                      multiline
                                      rows={3}
                                    />
                                    <Button
                                      type="submit"
                                      name="submit"
                                      size={'large'}
                                      color={'primary'}
                                      variant="contained"
                                      disabled={disableThread}>
                                      Send
                                    </Button>
                                  </Box>
                                </Box>
                                {file && (
                                  <div style={{ marginLeft: '84px' }}>
                                    {file.name}
                                    <Button
                                      variant="text"
                                      color="secondary"
                                      className={classes.removeAttachment}
                                      onClick={removeAttachment}>
                                      ✕
                                    </Button>
                                  </div>
                                )}
                              </Box>
                            </form>
                          </Box>
                        </Grid>
                      </Grid>
                    </div>
                  ) : (
                    <div className={classes.centerDefaultText}>No conversation selected</div>
                  )}
                </div>
              </div>
              <TicketModal open={ticketModalOpen} onClose={handleCloseTicketModal} ticketStatus={status} />
            </React.Fragment>
            {/* </Paper> */}
          </Box>
        </Grid>
      </GridContainer>
      <SnackBar />
    </div>
  );
}

export default DataTable;
