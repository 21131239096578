import React from 'react'
import { Checkbox, IconButton, TableCell, TableHead, TableRow, TableSortLabel, Tooltip, Typography,makeStyles } from '@material-ui/core';



const useStyles = makeStyles((theme) => ({

    cellFontStyle:{
      fontSize:'0.8rem',
      fontWeight: '800'
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  
    header:{
      
      backgroundColor:'#F7F8FA'
    }
    
  }));



function CustomTableHeader(props) {
    const classes = useStyles();


    const {onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort,headCells,excludeCell } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    const headCellsNew=headCells.filter((headCell)=>{ return (headCell.id !== excludeCell)});
  
  
    return (
      <TableHead>
        <TableRow className={classes.header}>
          <TableCell padding="checkbox" >
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
              color={'primary'}
            />
          </TableCell>
          {headCellsNew.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.alignment}
              padding={'default'}
              sortDirection={orderBy === headCell.id ? order : false}
              className={classes.cellFontStyle}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}

           
           
        

         
        </TableRow>
      </TableHead>
    );
}

export default CustomTableHeader
