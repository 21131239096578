function statusChecker(isPicked, isCompleted, isScheduled) {
  if (isCompleted && isPicked) {
    return 'PICKED';
  }

  if (isCompleted && !isPicked) {
    return 'REJECTED';
  }

  if (!isCompleted && isScheduled) {
    return 'SCHEDULED';
  }

  if (!isCompleted && !isScheduled) {
    return 'PENDING';
  }
}

export default statusChecker;
