import {
  Tooltip,
  IconButton,
  Checkbox,
  makeStyles,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tabs,
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import EnhancedTableHead from 'custom_components/EnhancedTableHead';
import EnhancedTableToolbar from 'custom_components/EnhancedTableToolbar';
import TabPanel from 'custom_components/TabPanel';
import PrintIcon from '@material-ui/icons/Print';
import Button from '@material-ui/core/Button';
import React, { useState, useEffect, useContext } from 'react';
import styles from './CSS/TableRow.css';
import ExportIcon from 'custom_components/ExportIcon';
import { useDispatch, useSelector } from 'react-redux';
import Orders from 'services/orders';
import ErrorToast from 'custom_components/Pages/common/ErrorToast';
import { OrderContext } from 'routes/Pages/Orders/context/orderContext';
import { setIsRecordsAvailable } from 'routes/Pages/Orders/actions/actions';
import { openDialog, closeDialog } from '../../../redux/actions/PrintOrder';
import { CsvBuilder } from 'filefy';
import AppConfigs from '../../../Utils/AppConfigs';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment/moment';
import reports from '../../../services/reports/index';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: 'green',
  },

  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

  paginationContainer: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
  },
  tableCellPadding: {
    paddingBottom: 0,
    paddingTop: 0,
  },

  exportIcon: {
    marginRight: 20,
  },
  toolContainer: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
    padding: 5,
  },
  exportButtonContainer: {
    display: 'flex',
    flex: 2,
    justifyContent: 'right',
    alignItems: 'center',
    paddingRight: 20,
    //backgroundColor:'green'
  },
  datePickerContainer: {
    display: 'flex',
    flex: 6,
    justifyContent: 'right',
    alignItems: 'center',
    paddingRight: 20,
    //backgroundColor:'yellow'
  },

  tabPanel: {
    '& > div': {
      paddingLeft: 0,
      paddingRight: 0,
      //backgroundColor:'blue'
    },
  },
}));

const headCells = [
  {
    id: 'waybill',
    numeric: false,
    disablePadding: true,
    label: 'WAYBILL',
  },
  { id: 'reference', numeric: true, disablePadding: false, label: 'REFERENCE' },
  {
    id: 'created_date',
    numeric: false,
    disablePadding: false,
    label: 'CREATED DATE',
  },
  { id: 'consignee', numeric: false, disablePadding: false, label: 'CONSIGNEE' },
  {
    id: 'destination',
    numeric: false,
    disablePadding: false,
    label: 'DESTINATION',
  },
  // { id: 'no_pieces', numeric: true, disablePadding: false, label: 'NO.PIECES' },
  // { id: 'type', numeric: false, disablePadding: false, label: 'TYPE' },
  {
    id: 'cash_on_delivery_amount',
    numeric: true,
    disablePadding: false,
    label: 'COD',
  },
  { id: 'print', numeric: true, disablePadding: false, label: 'ACTION' },
];

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function CreatedOrderTabPanel({ value, index }) {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [maxPageNum, setMaxPageNum] = useState(0);
  const [fromDate, setFromDate] = useState(moment());
  const [toDate, setToDate] = useState(moment());
  const [pickupStatus, setPickupStatus] = useState('ALL');

  const orderState = useContext(OrderContext);

  // const [isRecordsAvailable, setIsRecordsAvailable] = useState(true);
  let isVisible = true;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Orders.getCreatedOrders(1, true, `${fromDate.format('DD-MM-YYYY')}`, `${toDate.format('DD-MM-YYYY')}`, pickupStatus));

    return () => {
      isVisible = false;
    };
  }, []);

  const created = useSelector(({ orders }) => orders.created);

  let dataCreated = false;

  function createData(waybill, reference, createdDate, consignee, destination, cash_on_delivery_amount) {
    return {
      waybill,
      reference,
      createdDate,
      consignee,
      destination,
      cash_on_delivery_amount,
    };
  }

  const dataBuilder = data => {
    if (data.length === 0) {

      return [];
    } else {
      dataCreated = true;
      return data.map(entry => {
        return createData(
          entry.tracking_number,
          entry.reference,
          entry.created_at,
          entry.consignee,
          entry.destination,
          entry.cash_on_delivery_amount,
        );
      });
    }
  };

  const dataForTable = dataBuilder(created.orders); //cashing data

  const rows = () => {
    if (dataCreated) {
      return dataForTable;
    } else {
      return dataBuilder(created.orders);
    }
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows().map(n => n.waybill);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  const shouldGetNextDataBulk = newPage => {
    const nextPageNum = newPage;
    if (page < nextPageNum) {
      //moving forward
      const remaining = rows().length - nextPageNum * rowsPerPage;
      if (remaining <= rowsPerPage) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const getNewData = () => {
    const next = page + 1;
    dispatch(Orders.getCreatedOrders(next + 1, false, `${fromDate.format('DD-MM-YYYY')}`, `${toDate.format('DD-MM-YYYY')}`, pickupStatus));
  };

  const handleChangePage = (event, newPage) => {
    // alert(`max->${maxPageNum} next->${newPage}`);
    if (shouldGetNextDataBulk(newPage) && maxPageNum !== newPage) {
      getNewData();
    }
    setPage(newPage);
    if (newPage > maxPageNum) {
      setMaxPageNum(newPage);
    }
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows().length - page * rowsPerPage);

  const exportToCsv = () => {
    // const headings = [];
    // headCells.map(item => (item.id !== 'print' ? headings.push(item.label) : null));
    // const data = stableSort(rows(), getComparator(order, orderBy)).slice(
    //   page * rowsPerPage,
    //   page * rowsPerPage + rowsPerPage,
    // );
    // const dataSet = data.map(item => Object.values(item));
    //
    // const csvBuilder = new CsvBuilder('order_list_export.csv')
    //   .setColumns(headings)
    //   .addRows(dataSet)
    //   .exportFile();
    dispatch(reports.openCreatedOrdersReportUrl(fromDate, toDate));
  };
  const handleRefresh = () => {
    dispatch(Orders.getCreatedOrders(1, true, `${fromDate.format('DD-MM-YYYY')}`, `${toDate.format('DD-MM-YYYY')}`, pickupStatus));
    setPage(0);
    setMaxPageNum(0);
  };

  return (
    <TabPanel value={value} index={index} className={classes.tabPanel}>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <EnhancedTableToolbar
            enableRefresh={true}
            handleRefresh={handleRefresh}
            enablePrint={true}
            selected={selected}
            numSelected={selected.length}
            origin="waybills"
          />
          <Grid container>
            <Grid item xs={12} md={6} />
            <Grid item xs={12} md={6}>
              <Grid container justifyContent="center" alignItems="flex-end" spacing={2}>
              <Grid item xs={12} md={2}>
                  <Box p={2} py={4}>
                    <FormControl size="small" style={{ width: '100%' }}>
                      <InputLabel style={{ width: '100%' }}>Status</InputLabel>
                      <Select value={pickupStatus} onChange={e => setPickupStatus(e.target.value)} autoWidth>
                        <MenuItem value={'ALL'}>ALL</MenuItem>
                        <MenuItem value={'PICKED'}>PICKED</MenuItem>
                        <MenuItem value={'NOT PICKED'}>NOT PICKED</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box p={2}>
                    <KeyboardDatePicker
                      style={{ width: '100%' }}
                      disableToolbar
                      variant="inline"
                      format="DD-MM-yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="From Date"
                      value={fromDate}
                      autoOk={true}
                      onChange={e => {
                        setFromDate(moment(e._d));
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box p={2}>
                    <KeyboardDatePicker
                      style={{ width: '100%' }}
                      disableToolbar
                      variant="inline"
                      format="DD-MM-yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="To Date"
                      value={toDate}
                      minDate={fromDate}
                      autoOk={true}
                      onChange={e => {
                        setToDate(moment(e._d));
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Box p={2} py={5} sx={{ alignContent: 'flex-end' }}>
                    <Button
                      variant="contained"
                      fullWidth={true}
                      className={classes.newRequestButton}
                      onClick={() => {
                        // alert(moment(new Date(fromDate)).format('YYYY-MM-DD'))
                        handleRefresh();
                        // dispatch(
                        //   PickUps.getPickUps(
                        //     true,
                        //     undefined,
                        //     moment(new Date(fromDate)).format('YYYY-MM-DD'),
                        //     moment(new Date(toDate)).format('YYYY-MM-DD'),
                        //   ),
                        //   setPage(0),
                        // );
                      }}>
                      Filter
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <TableContainer>
            <Table className={classes.table} aria-labelledby="tableTitle" size={'medium'} aria-label="enhanced table">
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows().length}
                headCells={headCells}
                excludeCell={'print'}
              />
              <TableBody>
                {stableSort(rows(), getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.waybill);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.waybill}
                        selected={isItemSelected}
                        classes={{ hover: 'MuiTableRow-hover' }}>
                        <TableCell
                          padding="checkbox"
                          onClick={event => handleClick(event, row.waybill)}
                          className={classes.tableCellPadding}>
                          <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} color={'primary'} />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          onClick={event => handleClick(event, row.waybill)}>
                          {row.waybill}
                        </TableCell>
                        <TableCell align="right" onClick={event => handleClick(event, row.waybill)}>
                          {row.reference}
                        </TableCell>
                        <TableCell align="left" onClick={event => handleClick(event, row.waybill)}>
                          {AppConfigs.formatDisplayDate(row.createdDate)}
                        </TableCell>
                        <TableCell align="left" onClick={event => handleClick(event, row.waybill)}>
                          {row.consignee}
                        </TableCell>
                        <TableCell align="left" onClick={event => handleClick(event, row.waybill)}>
                          {row.destination}
                        </TableCell>
                        {/*<TableCell align="right" onClick={event => handleClick(event, row.waybill)}>*/}
                        {/*  {row.noPieces}*/}
                        {/*</TableCell>*/}
                        {/* <TableCell align="left" onClick={event => handleClick(event, row.waybill)}>
                          {row.type}
                        </TableCell> */}
                        <TableCell align="right" onClick={event => handleClick(event, row.waybill)}>
                          {row.cash_on_delivery_amount}
                        </TableCell>
                        <TableCell padding="default" align="right">
                          <Tooltip title="Print">
                            <IconButton
                              disabled={selected.length > 0}
                              onClick={() => {
                                dispatch(
                                  openDialog({
                                    ids: [row.waybill],
                                    origin: 'waybills',
                                  }),
                                );
                              }}
                              aria-label="print"
                              color={'primary'}>
                              <PrintIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={12} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box className={classes.paginationContainer}>
            <Button
              variant="outlined"
              onClick={exportToCsv}
              size={'small'}
              startIcon={<ExportIcon />}
              className={classes.exportIcon}>
              Export
            </Button>
            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={created.totalRecords}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        </Paper>
      </div>

      <ErrorToast open={created ? (created.orders.length > 0 ? false : true) : false} message="No Records Found" />
    </TabPanel>
  );
}

export default CreatedOrderTabPanel;