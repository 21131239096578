import { useSelector } from 'react-redux';
import { setAuthUser } from 'redux/actions/Auth';
import { setRefMode, setTrackingHistory, setTrackingHistoryInRefMode, setTrackingHistoryInTnMode } from 'redux/actions/TrackingHistory';
import { fetchError, fetchStart, fetchSuccess } from '../../redux/actions';

import axios from '../auth/jwt/config';

const TrackingHistory = {
  getTrackinghistory: (id = 'D0006587') => {
    return dispatch => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token'); //check
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      //token eka naththan redirect

      const encodedData = encodeURIComponent(id);

      axios
        .get(`tracking-history/show?tracking_number=${encodedData}`)
        .then(({ data }) => {
          const result = data.data;
          if (data.status == 'success') {
            // const refMode = data.data.refmode;
            const refMode = false;

            dispatch(fetchSuccess());
            if (refMode) {
              dispatch(setTrackingHistoryInRefMode(result));
            } else {
              //   console.log('DataaaaRTTTT', data.data.refs);
              dispatch(setTrackingHistoryInTnMode(result));
            }

            //dispatch(setCreatedOrders(result,page,isNextPage,isInitial));
            //setResult
          } else {
            // console.log('ERRRRRRRRRRRRR');
            dispatch(fetchError('ERRRRRRRRRR'));
          } //
        })
        .catch(function(error) {
          console.log(error);
          dispatch(fetchError(error.message));
        });
    };
  }
};

export default TrackingHistory;
