import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { SvgIcon } from '@material-ui/core';
// import iconSug from '../assets/svg/';

function PickUpRequestIcon(props) {
  return (
    <SvgIcon style={{ margin: 0 }} {...props} viewBox="0 0 59 47" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M49.681 12.587H42.153V1.75H7.01903C5.63697 1.80906 4.33418 2.41161 3.3942 3.4265C2.45422 4.44139 1.95315 5.78648 2.00003 7.169V36.969H7.01903C7.01903 41.469 10.382 45.097 14.548 45.097C18.714 45.097 22.077 41.467 22.077 36.969H37.134C37.134 41.469 40.497 45.097 44.663 45.097C48.829 45.097 52.192 41.467 52.192 36.969H57.21V23.425L49.681 12.587ZM14.548 41.035C13.5241 40.9563 12.5677 40.4939 11.8699 39.7405C11.1722 38.987 10.7846 37.9979 10.7846 36.971C10.7846 35.9441 11.1722 34.955 11.8699 34.2015C12.5677 33.4481 13.5241 32.9857 14.548 32.907C15.5719 32.9857 16.5284 33.4481 17.2261 34.2015C17.9239 34.955 18.3115 35.9441 18.3115 36.971C18.3115 37.9979 17.9239 38.987 17.2261 39.7405C16.5284 40.4939 15.5719 40.9563 14.548 41.035ZM48.426 16.65L53.345 23.423H42.153V16.65H48.426ZM44.662 41.035C43.845 41.0977 43.028 40.9127 42.3176 40.5041C41.6073 40.0955 41.0366 39.4823 40.6801 38.7445C40.3235 38.0066 40.1977 37.1785 40.3189 36.368C40.4401 35.5576 40.8028 34.8025 41.3596 34.2013C41.9164 33.6001 42.6415 33.1807 43.4403 32.9978C44.2391 32.8149 45.0745 32.877 45.8375 33.1761C46.6004 33.4751 47.2555 33.9972 47.7173 34.6741C48.179 35.3511 48.426 36.1515 48.426 36.971C48.4658 38.009 48.0915 39.0204 47.3857 39.7825C46.6798 40.5447 45.7001 40.9952 44.662 41.035Z" />
    </SvgIcon>
  );
}

export default PickUpRequestIcon;
