import { SET_TRACKING_HISTORY_IN_REF_MODE, SET_TRACKING_HISTORY_IN_TN_MODE } from '../../@jumbo/constants/ActionTypes';

export const setTrackingHistoryInRefMode = data => {
  console.log(data);
  return dispatch => {
    dispatch({
      type: SET_TRACKING_HISTORY_IN_REF_MODE,
      payload: data,
    });
  };
};

export const setTrackingHistoryInTnMode = data => {
  console.log(data, 'jjjjjjjjjjjjjj');
  return dispatch => {
    dispatch({
      type: SET_TRACKING_HISTORY_IN_TN_MODE,
      payload: data,
    });
  };
};
