import { GET_REPLIES, SET_REPLY_ATTACHMENTS, SET_TICKETS, SET_TICKET_ATTACHMENTS } from 'redux/actions/Tickets';

const INIT_STATE = {
  data: {},
  replies: {},
  attachment: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_TICKETS:
      return {
        ...state,
        data: action.payload.tickets,
      };
    case GET_REPLIES:
      return {
        ...state,
        replies: action.payload.data,
      };
    case SET_TICKET_ATTACHMENTS:
      return {
        ...state,
        attachment: action.payload.id,
      };
    case SET_REPLY_ATTACHMENTS:
      return {
        ...state,
        attachment: action.payload.id,
      };
    default:
      return state;
  }
};
