import axios from '../auth/jwt/config';
import { fetchError, fetchStart, fetchSuccess } from '../../redux/actions';
import {
  setTicketCreatedState,
  setTickets,
  getReplies,
  setTicketAttachments,
  setReplytAttachments,
} from 'redux/actions/Tickets';
import moment from 'moment';
import { showSnackBar } from 'redux/actions/SnackBar';

const Tickets = {
  getTickets: (trackingNumber, status) => {
    return dispatch => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      axios
        .get(`tickets?trackingNumber=${trackingNumber}&status=${status}`)
        .then(({ data }) => {
          const tickets = data.data;
          dispatch(setTickets({ tickets: tickets }));
        })
        .catch(error => {
          console.log(error, 'error');
        });
      dispatch(fetchSuccess());
    };
  },
  getTicketAttachment: (id, ext) => {
    return dispatch => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      axios
        .get(`tickets/ticketAttachment/${id}`)
        .then(({ data }) => {
          const fileExtension = data
            .split('.')
            .pop()
            .toLowerCase();

          if (['jpg', 'jpeg', 'png'].includes(fileExtension)) {
            dispatch(setTicketAttachments({ id: data }));
          } else {
            window.open(data, '_blank');
          }
        })
        .catch(error => {
          console.log(error, 'error');
        });
      dispatch(fetchSuccess());
    };
  },
  getReplyAttachment: (id, ext) => {
    return dispatch => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      axios
        .get(`tickets/replyAttachment/${id}`)
        .then(({ data }) => {
          const fileExtension = data
            .split('.')
            .pop()
            .toLowerCase();

          dispatch(setReplytAttachments({ id: data }));

          if (!['jpg', 'jpeg', 'png'].includes(fileExtension)) {
            window.open(data, '_blank');
          }
        })
        .catch(error => {
          console.log(error, 'error');
        });
      dispatch(fetchSuccess());
    };
  },
  getReplies: id => {
    return dispatch => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      axios
        .get(`tickets/${id}/replies`)
        .then(({ data }) => {
          const replies = data.data;
          dispatch(getReplies({ data: replies }));
        })
        .catch(error => {
          console.log(error, 'error');
        });
      dispatch(fetchSuccess());
    };
  },
  setTicketMarkAsRead: function(id, trackingNumber, status) {
    const main = this;
    return dispatch => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      axios
        .post(`tickets/${id}/mark-as-read`)
        .then(({ data }) => {
          dispatch(main.getTickets(trackingNumber, status));
        })
        .catch(error => {
          console.log(error, 'error');
        });
      dispatch(fetchSuccess());
    };
  },
  setTicket: function(data) {
    const main = this;
    const formData = new FormData();
    formData.append('reply', data.reply);
    formData.append('ticketId', data.ticketId);
    formData.append('attachment', data.file);

    return dispatch => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios
        .post('ticket/reply', formData)
        .then(response => {
          const res = response.data.status;
          if (res === 'success') {
            dispatch(showSnackBar({ message: response.data.data.success, severity: 'success' }));
            dispatch(setTicketCreatedState({ message: response.data.data.success, success: res }));
            dispatch(main.getReplies(data.ticketId));
          } else {
            dispatch(
              showSnackBar({
                message: response.data.data.validation_error[0].attachment,
                severity: 'error',
              }),
            );
            dispatch(setTicketCreatedState({ message: 'Failed to create ticket.', error: res }));
          }
          dispatch(fetchSuccess());
        })
        .catch(error => {
          dispatch(fetchError('Something Went Wrong'));
          console.log('Error', error);
        });
    };
  },
  createTicket: function(data, ticketStatus) {
    const main = this;
    const formData = new FormData();
    formData.append('title', data.title);
    formData.append('message', data.message);
    formData.append('trackingNumbers', data.trackingNumbers);
    formData.append('attachment', data.file);

    return dispatch => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token');
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      axios
        .post('tickets/store', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          const res = response.data.status;
          if (res === 'success') {
            dispatch(showSnackBar({ message: response.data.data.success, severity: 'success' }));
            dispatch(setTicketCreatedState({ message: response.data.message, success: res }));
            dispatch(main.getTickets('', ticketStatus));
          } else {
            const validationErrors = response.data.data.validation_error;

            for (const field in validationErrors) {
              if (validationErrors.hasOwnProperty(field)) {
                const errorMessages = validationErrors[field];

                errorMessages.forEach(errorMessage => {
                  dispatch(
                    showSnackBar({
                      message: `${field}: ${errorMessage}`,
                      severity: 'error',
                    }),
                  );
                });
              }
            }
          }
          dispatch(fetchSuccess());
        })
        .catch(error => {
          dispatch(fetchError('Something Went Wrong'));
          console.log('Error', error);
        });
    };
  },
};

export default Tickets;
