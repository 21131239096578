import {
  DISPLAY_BULK_UPLOADS,
  OPEN_BULK_UPLOAD_DIALOG,
  CLOSE_BULK_UPLOAD_DIALOG,
} from '../../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  bulkUploads: [],
  page: 1,
  isNextPage: false,
  open: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case DISPLAY_BULK_UPLOADS: {
      if (action.payload.isInitial) {
        return {
          ...state,
          bulkUploads: action.payload.records,
          page: action.payload.page,
          isNextPage: action.payload.isNextPage,
        };
      } else {
        return {
          ...state,
          bulkUploads: [...state.bulkUploads, ...action.payload.records],
          page: action.payload.page,
          isNextPage: action.payload.isNextPage,
        };
      }
    }
    case OPEN_BULK_UPLOAD_DIALOG: {
      return {
        ...state,
        open: true,
      };
    }
    case CLOSE_BULK_UPLOAD_DIALOG: {
      return {
        ...state,
        open: false,
      };
    }

    default:
      return state;
  }
};
