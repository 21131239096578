import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { useDispatch, useSelector } from 'react-redux';
import { openBulkUploadDialog, closeBulkUploadDialog } from '../redux/actions/BulkUpload';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import { Paper, FormControl, FormControlLabel, Radio, Grid } from '@material-ui/core';
import bulkUpload from 'services/bulkUploads';
import FormLabel from '@material-ui/core/FormLabel';
import BackupIcon from '@material-ui/icons/Backup';
import RadioGroup from '@material-ui/core/RadioGroup';
import { set } from 'lodash';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.white,
  },
  closeButton: {
    color: theme.palette.text.white,
  },
});

const useStyles = makeStyles(theme => ({
  paperContainer: {
    width: '140px',
    margin: '0 auto',
    minHeight: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  paperContainerSelected: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
  contentWrapper: {
    display: 'flex',
    margin: '20px 100px',
    width: 'fit-content',
  },
  gridItemWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '200px',
    justifyContent: 'center',

    // borderRight: '1px solid',
    // borderRightColor: theme.palette.borderColor.dark,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={clsx([classes.root, classes.titleWrapper])} {...other}>
      <Typography variant="h4">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const BulkUploadDialog = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { open } = useSelector(state => state.bulkUpload);
  const [file, setFile] = useState(null);

  useEffect(() => {
    setFile(null);
  }, [open]);

  const handleClose = () => {
    dispatch(closeBulkUploadDialog());
  };
  const handleSave = () => {
    dispatch(bulkUpload.uploadBulkFile(file));
  };

  const isValidFile = () => {
    if (file) {
      return file.name.split('.')[1] === 'xls' || file.name.split('.')[1] === 'xlsx';
    }
    return false;
  };

  const handleFileChange = e => {
    setFile(e.target.files[0]);
  };
  return (
    <React.Fragment>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        TransitionComponent={Transition}
        open={open}
        fullWidth={true}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Upload Excel File
        </DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid item md={12} className={classes.gridItemWrapper}>
              <Button variant="outlined" component="label" color="primary">
                {' '}
                <BackupIcon />
                <span style={{ marginLeft: 10 }}>Select file from computer</span>
                <input onChange={handleFileChange} type="file" hidden />
              </Button>
              {!file ? <div>(Please only select .xslx or .xls file)</div> : <div> {file.name}</div>}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus variant="outlined" disabled={!isValidFile()} onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default BulkUploadDialog;
