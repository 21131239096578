import axios from 'axios';

export default axios.create({
  // baseURL: `http://localhost:8000/client_panel_api/v1/`,//testing
  // baseURL: `https://system.citypak.net/client_panel_api/v1/`, //production
  // baseURL: `https://falcon.citypak.lk/client_panel_api/v1/`, //production
  baseURL:
    process.env.NODE_ENV !== 'production'
      ? 'http://localhost:8000/client_panel_api/v1/'
      // ? 'https://staging.citypak.lk/client_panel_api/v1/'
      : process.env.REACT_APP_API_BASE_URL,
  headers: {
    Accept: 'application/json',
  },
});
