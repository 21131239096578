import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { SvgIcon } from '@material-ui/core';

function OrderIcon({ color }) {
  return (
    <SvgIcon viewBox="0 0 25 25">
      <g transform="translate(2102.209 -665.5)">
        <path
          d="M-2094.525,649.061h24v24h-24Z"
          transform="translate(-7.183 16.939)"
          fill="none"
          stroke="#707070"
          opacity="0"
        />
        <path
          d="M18.789,2.955H14.664a2.958,2.958,0,0,0-5.565,0H4.974A1.97,1.97,0,0,0,3,4.911V18.6a1.97,1.97,0,0,0,1.974,1.955H18.789A1.97,1.97,0,0,0,20.762,18.6V4.911A1.97,1.97,0,0,0,18.789,2.955Zm-6.908,0a.978.978,0,1,1-.987.978A.985.985,0,0,1,11.881,2.955ZM9.908,16.644,5.96,12.733l1.391-1.379,2.556,2.523,6.5-6.443L17.8,8.822Z"
          transform="translate(-2101.59 667.223)"
        />
      </g>
    </SvgIcon>
  );
}

export default OrderIcon;
