import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { Box, Grid, Button, Typography, Radio, TextField } from '@material-ui/core';
import React, { useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import { useState } from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { DatePicker } from '@material-ui/pickers';
import reports from '../../../services/reports/index';
import { useDispatch } from 'react-redux';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  /*[theme.breakpoints.up('md')]: { */

  reportContainer: {
    backgroundColor: theme.palette.background.paper,
    //alignContent:'center',
    //justifyContent:'center',
    borderRadius: 10,
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 30,
    paddingBottom: 30,
    // display: 'flex',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)',
    //height:'100%',
  },

  form: {
    display: 'flex',
    flex: 1,
    //width:'50%',
    //paddingLeft:20,
    paddingRight: 15,
    flexDirection: 'column',
  },
  formContainer: {
    borderRadius: 10,
    borderColor: theme.palette.borderColor.dark,
    border: '1px solid',
    padding: 20,
    marginTop: 30,
    marginBottom: 10,
  },

  formInput: {
    marginTop: '5px',
    marginBottom: '5px',
  },

  panelContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    //85
  },

  // leftPanel: {
  //   display: 'flex',
  //   flex: 1,
  // },
  // rightPanel: {
  //   display: 'none',
  //   [theme.breakpoints.up('md')]: {
  //     visibility: 'visible',
  //     display: 'flex',
  //     flex: 1,
  //     //   backgroundColor: 'green',
  //     resize: 'contained',
  //   },
  // },

  buttonContainer: {
    display: 'flex',

    justifyContent: 'center',
    paddingTop: '25px',
  },
}));

const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'Reports', isActive: true },
];

const Reports = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [wayBillOrRefNo, setWayBillOrRefNo] = useState('');
  const [searchType, setSearchType] = useState('created_date');
  //const [startDate, setStartDate] = useState();
  //const [endDate, setEndDate] = useState();

  const [startDate, setStartDate] = useState(
    moment()
      .clone()
      .subtract(1, 'months'),
  );
  const [endDate, setEndDate] = useState(moment());

  const handleReportByNumber = () => {
    let trackingNumbers = wayBillOrRefNo.split(/[ ,\n]+/);
    trackingNumbers = Array.from(new Set(trackingNumbers));
    dispatch(reports.openLastStatusReportUrl(trackingNumbers.filter(e => e)));
  };

  const handleDownloadReport = () => {
    dispatch(reports.openReportUrl(searchType, startDate, endDate));
  };

  return (
    <PageContainer heading={'Reports'} breadcrumbs={breadcrumbs}>
      <Box className={classes.reportContainer}>
        <Grid container>
          <Grid item md={6}>
            <Box className={classes.form}>
              <form>
                <AppTextInput
                  className={classes.formInput}
                  label={'Paste waybills/reference numbers'}
                  value={wayBillOrRefNo}
                  variant="outlined"
                  onChange={e => {
                    setWayBillOrRefNo(e.target.value);
                  }}
                  multiline
                  rows={6}
                />
                <Box>
                  <Button size={'large'} color={'primary'} variant="contained" onClick={handleReportByNumber}>
                    Download
                  </Button>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={6}>
            <Box className={classes.form}>
              <form>
                <Box className={classes.formContainer}>
                  <Typography variant="h4">Search By Type</Typography>
                  <Grid container>
                    <Grid item sm={6}>
                      <RadioGroup
                        aria-label="gender"
                        name="gender1"
                        value={searchType}
                        onChange={e => {
                          setSearchType(e.target.value);
                        }}>
                        <FormControlLabel value="created_date" control={<Radio />} label="Created Date" />
                        <FormControlLabel value="fm_scan" control={<Radio />} label="FM Scan" />
                        {/*<FormControlLabel value="delivered" control={<Radio />} label="Delivered" />*/}
                      </RadioGroup>
                    </Grid>
                    <Grid item sm={6}>
                      <Box>
                        <DatePicker
                          label={'Start Date'}
                          value={startDate}
                          format="MM/DD/yyyy"
                          onChange={e => {
                            setStartDate(e);
                          }}
                          required={true}
                          maxDate={endDate}
                        />
                        <DatePicker
                          label={'End Date'}
                          value={endDate}
                          format="MM/DD/yyyy"
                          onChange={e => {
                            setEndDate(e);
                          }}
                          required={true}
                          minDate={startDate.clone()}
                          maxDate={startDate.clone().add(1, 'months')}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Button size={'large'} color={'primary'} variant="contained" onClick={handleDownloadReport}>
                    Download
                  </Button>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </PageContainer>
  );
};

export default Reports;
