import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

const PieChart = data => {
  const [breakdown, setBreakdown] = useState([]);

  useEffect(() => {
    const obj = Object.values(data)[0];
    const arr = [];
    for (const key in obj) {
      arr.push(obj[key]);
    }
    setBreakdown(arr);
  }, [data]);
  const options = {
    chart: {
      width: 380,
      type: 'pie',
    },
    labels: ['Undelivered(UD)', 'Delivered (DL)', 'Returned (RTM)', 'To Be Returned (RT)'],
    colors: ['#EAB308', '#22C55E', '#BE0707', '#EF4444'],
    responsive: [
      {
        breakpoint: 1380,
        options: {
          chart: {
            width: '100%',
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };

  return (
    <div style={{ width: '100%' }}>
      <Chart options={options} series={breakdown} type={'pie'} />
    </div>
  );
};

export default PieChart;
