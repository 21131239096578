import { SET_REPORT_DATA } from '../../@jumbo/constants/ActionTypes';

export const setReportData = data => {
  return dispatch => {
    dispatch({
      type: SET_REPORT_DATA,
      payload: data,
    });
  };
};
