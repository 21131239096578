/* eslint-disable react-hooks/exhaustive-deps */
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { Grid, Paper, Typography, TextField, Button } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import AppAutocomplete from '@jumbo/components/Common/formElements/AppAutocomplete';
import { Phone, SearchOutlined } from '@material-ui/icons';
import AccountService from '../../../services/account/index';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(5),
  },
}));

const Account = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const cities = useSelector(state => state.cities.cities);

  const [shipperName, setShipperName] = useState('');
  const [shipperAddressLine1, setShipperAddressLine1] = useState('');
  const [shipperAddressLine2, setShipperAddressLine2] = useState('');
  const [shipperAddressLine3, setShipperAddressLine3] = useState('');
  const [shipperAddressLine4, setShipperAddressLine4] = useState('');
  const [shipperAddressCity, setShipperAddressCity] = useState('');
  const [shipperContact1, setShipperContact1] = useState('');
  const [shipperContact2, setShipperContact2] = useState('');

  const [sellectedCity, setSellectedCity] = useState(null);

  useEffect(() => {
    // dispatch(AccountService.getShipper());
    async function fetchData() {
      // let response = await fetch('api/data')
      let {
        address_city_id,
        address_line_1,
        address_line_2,
        address_line_3,
        address_line_4,
        address_title,
        contact_1,
        contact_2,
      } = await AccountService.getShipper();
      setShipperName(address_title);
      setShipperAddressLine1(address_line_1);
      setShipperAddressLine2(address_line_2);
      setShipperAddressLine3(address_line_3);
      setShipperAddressLine4(address_line_4);
      setShipperAddressCity(address_city_id);
      setShipperContact1(contact_1);
      setShipperContact2(contact_2);

      setSellectedCity(cities.find(d => d.id === address_city_id));
    }

    fetchData();

    // AccountService.getShipper().then(d => console.log(d));

    // console.log(shipperAddressCity);
    // console.log(cities.findIndex(value => value.id === address_city_id));
    // console.log(AccountService.getShipper().then(d => console.log(d)));
  }, []);
  // useEffect(() => {
  //   console.log('triigger');
  // }, [shipperAddressCity]);

  const [errors, setErrors] = useState({
    merchantName: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    city: '',
    name: '',
    contactNumber: '',
    time: '',
    waybillCount: '',
    maxWeight: '',
    dateFrom: '',
    dateTo: '',
    hasError: 'false',
  });

  const submitHandler = event => {
    event.preventDefault();
    const data = {
      shipperName,
      shipperAddressLine1,
      shipperAddressLine2,
      shipperAddressLine3,
      shipperAddressLine4,
      shipperAddressCity,
      shipperContact1,
      shipperContact2,
    };
    console.log(data);
    dispatch(AccountService.saveShipper(data));
  };

  return (
    <React.Fragment>
      <PageContainer heading={'Account'}>
        <form onSubmit={submitHandler}>
          <Grid item md={6}>
            <Paper className={classes.paper}>
              <Typography variant="h3" className={classes.sectionTitle}>
                Shipper Details
              </Typography>
              <div>
                <AppTextInput
                  className={classes.formInput}
                  label={'Shipper Name'}
                  required={true}
                  type={'text'}
                  onChange={event => {
                    setShipperName(event.target.value);
                  }}
                  value={shipperName}
                />
                <Typography color={'primary'}>{errors.merchantName}</Typography>

                <AppTextInput
                  className={classes.formInput}
                  label={'Address Line 1'}
                  required={true}
                  type={'text'}
                  value={shipperAddressLine1}
                  onChange={event => {
                    setShipperAddressLine1(event.target.value);
                  }}
                />
                <Typography color={'primary'}>{errors.addressLine1}</Typography>

                <AppTextInput
                  className={classes.formInput}
                  label={'Address Line 2'}
                  type={'text'}
                  value={shipperAddressLine2}
                  onChange={event => {
                    setShipperAddressLine2(event.target.value);
                  }}
                />
                <Typography color={'primary'}>{errors.addressLine2}</Typography>

                <AppTextInput
                  className={classes.formInput}
                  label={'Address Line 3'}
                  type={'text'}
                  value={shipperAddressLine3}
                  onChange={event => {
                    setShipperAddressLine3(event.target.value);
                  }}
                />
                <Typography color={'primary'}>{errors.addressLine3}</Typography>

                <AppTextInput
                  className={classes.formInput}
                  label={'Address Line 4'}
                  type={'text'}
                  value={shipperAddressLine4}
                  onChange={event => {
                    setShipperAddressLine4(event.target.value);
                  }}
                />
                <Typography color={'primary'}>{errors.addressLine3}</Typography>

                <AppAutocomplete
                  id="autocomplete_shipper_city"
                  label={'Address City'}
                  options={cities}
                  variant="standard"
                  value={sellectedCity}
                  required={true}
                  onChange={(event, value) => {
                    if (value != null) {
                      console.log(value);
                      setSellectedCity(cities.find(d => d.id === value.id));
                      setShipperAddressCity(value.id);
                    } else {
                      setSellectedCity('');
                      setShipperAddressCity('');
                    }
                  }}
                  getOptionLabel={option => option.name_en}
                  renderInput={params => <TextField {...params} label="Address City" />}
                />

                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <AppTextInput
                      className={classes.formInput}
                      label={'Contact No 1'}
                      required={true}
                      margin="dense"
                      type={'text'}
                      value={shipperContact1}
                      InputProps={{
                        startAdornment: <Phone />,
                      }}
                      onChange={event => {
                        setShipperContact1(event.target.value);
                      }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <AppTextInput
                      className={classes.formInput}
                      label={'Contact No 2'}
                      margin="dense"
                      required={false}
                      type={'text'}
                      value={shipperContact2}
                      InputProps={{
                        startAdornment: <Phone />,
                      }}
                      onChange={event => {
                        setShipperContact2(event.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button size={'large'} color={'primary'} variant="contained" type="submit">
                      Save Shipper
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Paper>
          </Grid>
        </form>
      </PageContainer>
    </React.Fragment>
  );
};

export default Account;
