import React from 'react'
import AppImages from 'Utils/AppImages'

function AppLogo() {
    return (
        <img src={AppImages.appLogo}></img>
    )
}

export default AppLogo
