import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import React, { useEffect, useState } from 'react';
import { NewRequest } from './newRequest';
import { PickUpTable } from './tableRelated';
import PickUps from 'services/pickups';
import { useDispatch, useSelector } from 'react-redux';

const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'Pickup Request', isActive: true },
];

function PickupRequest() {
  const [isNewRequest, toggleNewRequest] = useState(false);

  const toggleNewRequestUI = () => {
    toggleNewRequest(!isNewRequest);
  };

  return (
    <>
      <PageContainer heading={'Pickup Requests'.toUpperCase()} breadcrumbs={breadcrumbs} style={{ backgroundColor: 'blue' }}>
        {!isNewRequest && <PickUpTable toggleNewRequestUI={toggleNewRequestUI}></PickUpTable>}
        {isNewRequest && <NewRequest toggleRequest={toggleNewRequestUI}></NewRequest>}
      </PageContainer>
    </>
  );
}

export default PickupRequest;
